@font-face {
  font-family: 'stack-interface';
  src: url(/static/media/stack-interface.8aca86aa.eot);
  src: url(/static/media/stack-interface.8aca86aa.eot#iefix) format('embedded-opentype'),
       url(/static/media/stack-interface.ae072782.woff2) format('woff2'),
       url(/static/media/stack-interface.9f761bcf.woff) format('woff'),
       url(/static/media/stack-interface.59c2371f.ttf) format('truetype'),
       url(/static/media/stack-interface.fcf839e4.svg#stack-interface) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'stack-interface';
    src: url('../font/stack-interface.svg?34857618#stack-interface') format('svg');
  }
}
*/
 
 [class^="stack-"]:before, [class*=" stack-"]:before {
  font-family: "stack-interface";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.stack-down-open-big:before { content: '\E800'; } /* '' */
.stack-left-open-big:before { content: '\E801'; } /* '' */
.stack-right-open-big:before { content: '\E802'; } /* '' */
.stack-up-open-big:before { content: '\E803'; } /* '' */
.stack-basket:before { content: '\E804'; } /* '' */
.stack-search:before { content: '\E805'; } /* '' */
.stack-down-dir:before { content: '\E806'; } /* '' */
.stack-left-dir:before { content: '\E807'; } /* '' */
.stack-right-dir:before { content: '\E808'; } /* '' */
.stack-up-dir:before { content: '\E809'; } /* '' */
.stack-down-open:before { content: '\E80A'; } /* '' */
.stack-left-open:before { content: '\E80B'; } /* '' */
.stack-right-open:before { content: '\E80C'; } /* '' */
.stack-up-open:before { content: '\E80D'; } /* '' */
.stack-menu:before { content: '\E80E'; } /* '' */
.stack-users:before { content: '\E80F'; } /* '' */
.stack-publish:before { content: '\E810'; } /* '' */
.stack-trash:before { content: '\E811'; } /* '' */
.stack-bell:before { content: '\E812'; } /* '' */
.stack-cog:before { content: '\E813'; } /* '' */
.stack-plus-circled:before { content: '\E814'; } /* '' */
.stack-dot-3:before { content: '\E815'; } /* '' */
@font-face {
  font-family: 'Socicon';
  src:  url(/static/media/socicon.23870e1b.eot);
  src:  url(/static/media/socicon.23870e1b.eot#iefix) format('embedded-opentype'),
    url(/static/media/socicon.2ba13131.ttf) format('truetype'),
    url(/static/media/socicon.cdaa4f04.woff) format('woff'),
    url(/static/media/socicon.b6029456.svg#Socicon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="socicon-"], [class*=" socicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Socicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.socicon-gamewisp:before {
  content: "\E905";
}
.socicon-bloglovin:before {
  content: "\E904";
}
.socicon-tunein:before {
  content: "\E903";
}
.socicon-gamejolt:before {
  content: "\E901";
}
.socicon-trello:before {
  content: "\E902";
}
.socicon-spreadshirt:before {
  content: "\E900";
}
.socicon-500px:before {
  content: "\E000";
}
.socicon-8tracks:before {
  content: "\E001";
}
.socicon-airbnb:before {
  content: "\E002";
}
.socicon-alliance:before {
  content: "\E003";
}
.socicon-amazon:before {
  content: "\E004";
}
.socicon-amplement:before {
  content: "\E005";
}
.socicon-android:before {
  content: "\E006";
}
.socicon-angellist:before {
  content: "\E007";
}
.socicon-apple:before {
  content: "\E008";
}
.socicon-appnet:before {
  content: "\E009";
}
.socicon-baidu:before {
  content: "\E00A";
}
.socicon-bandcamp:before {
  content: "\E00B";
}
.socicon-battlenet:before {
  content: "\E00C";
}
.socicon-beam:before {
  content: "\E00D";
}
.socicon-bebee:before {
  content: "\E00E";
}
.socicon-bebo:before {
  content: "\E00F";
}
.socicon-behance:before {
  content: "\E010";
}
.socicon-blizzard:before {
  content: "\E011";
}
.socicon-blogger:before {
  content: "\E012";
}
.socicon-buffer:before {
  content: "\E013";
}
.socicon-chrome:before {
  content: "\E014";
}
.socicon-coderwall:before {
  content: "\E015";
}
.socicon-curse:before {
  content: "\E016";
}
.socicon-dailymotion:before {
  content: "\E017";
}
.socicon-deezer:before {
  content: "\E018";
}
.socicon-delicious:before {
  content: "\E019";
}
.socicon-deviantart:before {
  content: "\E01A";
}
.socicon-diablo:before {
  content: "\E01B";
}
.socicon-digg:before {
  content: "\E01C";
}
.socicon-discord:before {
  content: "\E01D";
}
.socicon-disqus:before {
  content: "\E01E";
}
.socicon-douban:before {
  content: "\E01F";
}
.socicon-draugiem:before {
  content: "\E020";
}
.socicon-dribbble:before {
  content: "\E021";
}
.socicon-drupal:before {
  content: "\E022";
}
.socicon-ebay:before {
  content: "\E023";
}
.socicon-ello:before {
  content: "\E024";
}
.socicon-endomodo:before {
  content: "\E025";
}
.socicon-envato:before {
  content: "\E026";
}
.socicon-etsy:before {
  content: "\E027";
}
.socicon-facebook:before {
  content: "\E028";
}
.socicon-feedburner:before {
  content: "\E029";
}
.socicon-filmweb:before {
  content: "\E02A";
}
.socicon-firefox:before {
  content: "\E02B";
}
.socicon-flattr:before {
  content: "\E02C";
}
.socicon-flickr:before {
  content: "\E02D";
}
.socicon-formulr:before {
  content: "\E02E";
}
.socicon-forrst:before {
  content: "\E02F";
}
.socicon-foursquare:before {
  content: "\E030";
}
.socicon-friendfeed:before {
  content: "\E031";
}
.socicon-github:before {
  content: "\E032";
}
.socicon-goodreads:before {
  content: "\E033";
}
.socicon-google:before {
  content: "\E034";
}
.socicon-googlescholar:before {
  content: "\E035";
}
.socicon-googlegroups:before {
  content: "\E036";
}
.socicon-googlephotos:before {
  content: "\E037";
}
.socicon-googleplus:before {
  content: "\E038";
}
.socicon-grooveshark:before {
  content: "\E039";
}
.socicon-hackerrank:before {
  content: "\E03A";
}
.socicon-hearthstone:before {
  content: "\E03B";
}
.socicon-hellocoton:before {
  content: "\E03C";
}
.socicon-heroes:before {
  content: "\E03D";
}
.socicon-hitbox:before {
  content: "\E03E";
}
.socicon-horde:before {
  content: "\E03F";
}
.socicon-houzz:before {
  content: "\E040";
}
.socicon-icq:before {
  content: "\E041";
}
.socicon-identica:before {
  content: "\E042";
}
.socicon-imdb:before {
  content: "\E043";
}
.socicon-instagram:before {
  content: "\E044";
}
.socicon-issuu:before {
  content: "\E045";
}
.socicon-istock:before {
  content: "\E046";
}
.socicon-itunes:before {
  content: "\E047";
}
.socicon-keybase:before {
  content: "\E048";
}
.socicon-lanyrd:before {
  content: "\E049";
}
.socicon-lastfm:before {
  content: "\E04A";
}
.socicon-line:before {
  content: "\E04B";
}
.socicon-linkedin:before {
  content: "\E04C";
}
.socicon-livejournal:before {
  content: "\E04D";
}
.socicon-lyft:before {
  content: "\E04E";
}
.socicon-macos:before {
  content: "\E04F";
}
.socicon-mail:before {
  content: "\E050";
}
.socicon-medium:before {
  content: "\E051";
}
.socicon-meetup:before {
  content: "\E052";
}
.socicon-mixcloud:before {
  content: "\E053";
}
.socicon-modelmayhem:before {
  content: "\E054";
}
.socicon-mumble:before {
  content: "\E055";
}
.socicon-myspace:before {
  content: "\E056";
}
.socicon-newsvine:before {
  content: "\E057";
}
.socicon-nintendo:before {
  content: "\E058";
}
.socicon-npm:before {
  content: "\E059";
}
.socicon-odnoklassniki:before {
  content: "\E05A";
}
.socicon-openid:before {
  content: "\E05B";
}
.socicon-opera:before {
  content: "\E05C";
}
.socicon-outlook:before {
  content: "\E05D";
}
.socicon-overwatch:before {
  content: "\E05E";
}
.socicon-patreon:before {
  content: "\E05F";
}
.socicon-paypal:before {
  content: "\E060";
}
.socicon-periscope:before {
  content: "\E061";
}
.socicon-persona:before {
  content: "\E062";
}
.socicon-pinterest:before {
  content: "\E063";
}
.socicon-play:before {
  content: "\E064";
}
.socicon-player:before {
  content: "\E065";
}
.socicon-playstation:before {
  content: "\E066";
}
.socicon-pocket:before {
  content: "\E067";
}
.socicon-qq:before {
  content: "\E068";
}
.socicon-quora:before {
  content: "\E069";
}
.socicon-raidcall:before {
  content: "\E06A";
}
.socicon-ravelry:before {
  content: "\E06B";
}
.socicon-reddit:before {
  content: "\E06C";
}
.socicon-renren:before {
  content: "\E06D";
}
.socicon-researchgate:before {
  content: "\E06E";
}
.socicon-residentadvisor:before {
  content: "\E06F";
}
.socicon-reverbnation:before {
  content: "\E070";
}
.socicon-rss:before {
  content: "\E071";
}
.socicon-sharethis:before {
  content: "\E072";
}
.socicon-skype:before {
  content: "\E073";
}
.socicon-slideshare:before {
  content: "\E074";
}
.socicon-smugmug:before {
  content: "\E075";
}
.socicon-snapchat:before {
  content: "\E076";
}
.socicon-songkick:before {
  content: "\E077";
}
.socicon-soundcloud:before {
  content: "\E078";
}
.socicon-spotify:before {
  content: "\E079";
}
.socicon-stackexchange:before {
  content: "\E07A";
}
.socicon-stackoverflow:before {
  content: "\E07B";
}
.socicon-starcraft:before {
  content: "\E07C";
}
.socicon-stayfriends:before {
  content: "\E07D";
}
.socicon-steam:before {
  content: "\E07E";
}
.socicon-storehouse:before {
  content: "\E07F";
}
.socicon-strava:before {
  content: "\E080";
}
.socicon-streamjar:before {
  content: "\E081";
}
.socicon-stumbleupon:before {
  content: "\E082";
}
.socicon-swarm:before {
  content: "\E083";
}
.socicon-teamspeak:before {
  content: "\E084";
}
.socicon-teamviewer:before {
  content: "\E085";
}
.socicon-technorati:before {
  content: "\E086";
}
.socicon-telegram:before {
  content: "\E087";
}
.socicon-tripadvisor:before {
  content: "\E088";
}
.socicon-tripit:before {
  content: "\E089";
}
.socicon-triplej:before {
  content: "\E08A";
}
.socicon-tumblr:before {
  content: "\E08B";
}
.socicon-twitch:before {
  content: "\E08C";
}
.socicon-twitter:before {
  content: "\E08D";
}
.socicon-uber:before {
  content: "\E08E";
}
.socicon-ventrilo:before {
  content: "\E08F";
}
.socicon-viadeo:before {
  content: "\E090";
}
.socicon-viber:before {
  content: "\E091";
}
.socicon-viewbug:before {
  content: "\E092";
}
.socicon-vimeo:before {
  content: "\E093";
}
.socicon-vine:before {
  content: "\E094";
}
.socicon-vkontakte:before {
  content: "\E095";
}
.socicon-warcraft:before {
  content: "\E096";
}
.socicon-wechat:before {
  content: "\E097";
}
.socicon-weibo:before {
  content: "\E098";
}
.socicon-whatsapp:before {
  content: "\E099";
}
.socicon-wikipedia:before {
  content: "\E09A";
}
.socicon-windows:before {
  content: "\E09B";
}
.socicon-wordpress:before {
  content: "\E09C";
}
.socicon-wykop:before {
  content: "\E09D";
}
.socicon-xbox:before {
  content: "\E09E";
}
.socicon-xing:before {
  content: "\E09F";
}
.socicon-yahoo:before {
  content: "\E0A0";
}
.socicon-yammer:before {
  content: "\E0A1";
}
.socicon-yandex:before {
  content: "\E0A2";
}
.socicon-yelp:before {
  content: "\E0A3";
}
.socicon-younow:before {
  content: "\E0A4";
}
.socicon-youtube:before {
  content: "\E0A5";
}
.socicon-zapier:before {
  content: "\E0A6";
}
.socicon-zerply:before {
  content: "\E0A7";
}
.socicon-zomato:before {
  content: "\E0A8";
}
.socicon-zynga:before {
  content: "\E0A9";
}


body:after {
    display: none
}

.lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #000;
    filter: alpha(Opacity=80);
    opacity: .8;
    display: none
}

.lightbox {
    position: fixed;
    top: 50% !important;
   	-webkit-transform: translate3d(0,-50%,0);
   	        transform: translate3d(0,-50%,0);
    left: 0;
    width: 100%;
    z-index: 10000;
    text-align: center;
    line-height: 0;
    font-weight: 400
}

.lightbox .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    border-radius: 3px
}

.lightbox a img {
    border: none
}

.lb-outerContainer {
    position: relative;
    background-color: #222;
    *zoom: 1;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px
}

.lb-outerContainer:after {
    content: "";
    display: table;
    clear: both
}

.lb-container {
    padding: 4px
}

.lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    height: 25%;
    width: 100%;
    text-align: center;
    line-height: 0
}

.lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
}

.lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10
}

.lb-container>.nav {
    left: 0
}

.lb-nav a {
    outline: 0;
}

.lb-next,
.lb-prev {
    height: 100%;
    cursor: pointer;
    display: block
}

.lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    filter: alpha(Opacity=0);
    opacity: .5;
    -webkit-transition: opacity .6s;
    transition: opacity .6s
}

.lb-nav a.lb-prev:hover {
    filter: alpha(Opacity=100);
    opacity: 1
}

.lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    filter: alpha(Opacity=0);
    opacity: .5;
    -webkit-transition: opacity .6s;
    transition: opacity .6s
}

.lb-nav a.lb-next:hover {
    filter: alpha(Opacity=100);
    opacity: 1
}

.lb-prev:after,.lb-next:after{
	content: '\2190';
	position: absolute;
	top: 50%;
	color: #fff;
	font-size: 24px;
}
.lb-prev:after{
	left: -60px;
}
.lb-next:after{
	right: -60px;
	content: '\2192';
}

.lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    min-height: 35px;
    *zoom: 1;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

.lb-dataContainer:after {
    content: "";
    display: table;
    clear: both
}

.lb-data {
    padding: 0 4px;
    color: #ccc
}

.lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em
}

.lb-data .lb-caption {
    font-size: 13px;
    font-weight: 700;
    line-height: 1em
}

.lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999
}

.lb-data .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    text-align: right;
    outline: 0;
    filter: alpha(Opacity=70);
    opacity: .7;
    -webkit-transition: opacity .2s;
    transition: opacity .2s
}

.lb-data .lb-close:hover {
    cursor: pointer;
    filter: alpha(Opacity=100);
    opacity: 1
}
/*! Flickity v2.0.2
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.flickity-prev-next-button:hover { background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.flickity-prev-next-button.previous { left: 10px; }
.flickity-prev-next-button.next { right: 10px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
@font-face {
	font-family: 'iconsmind';
	src:url(/static/media/iconsmind.5a93ca37.eot?#iefix-rdmvgc) format('embedded-opentype');
	src:url(/static/media/iconsmind.2864469c.woff) format('woff'),
		url(/static/media/iconsmind.f0409337.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'iconsmind';
	speak: none;
	font-style: normal;
	font-weight: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-A-Z:before {
	content: "\E600";
}
.icon-Aa:before {
	content: "\E601";
}
.icon-Add-Bag:before {
	content: "\E602";
}
.icon-Add-Basket:before {
	content: "\E603";
}
.icon-Add-Cart:before {
	content: "\E604";
}
.icon-Add-File:before {
	content: "\E605";
}
.icon-Add-SpaceAfterParagraph:before {
	content: "\E606";
}
.icon-Add-SpaceBeforeParagraph:before {
	content: "\E607";
}
.icon-Add-User:before {
	content: "\E608";
}
.icon-Add-UserStar:before {
	content: "\E609";
}
.icon-Add-Window:before {
	content: "\E60A";
}
.icon-Add:before {
	content: "\E60B";
}
.icon-Address-Book:before {
	content: "\E60C";
}
.icon-Address-Book2:before {
	content: "\E60D";
}
.icon-Administrator:before {
	content: "\E60E";
}
.icon-Aerobics-2:before {
	content: "\E60F";
}
.icon-Aerobics-3:before {
	content: "\E610";
}
.icon-Aerobics:before {
	content: "\E611";
}
.icon-Affiliate:before {
	content: "\E612";
}
.icon-Aim:before {
	content: "\E613";
}
.icon-Air-Balloon:before {
	content: "\E614";
}
.icon-Airbrush:before {
	content: "\E615";
}
.icon-Airship:before {
	content: "\E616";
}
.icon-Alarm-Clock:before {
	content: "\E617";
}
.icon-Alarm-Clock2:before {
	content: "\E618";
}
.icon-Alarm:before {
	content: "\E619";
}
.icon-Alien-2:before {
	content: "\E61A";
}
.icon-Alien:before {
	content: "\E61B";
}
.icon-Aligator:before {
	content: "\E61C";
}
.icon-Align-Center:before {
	content: "\E61D";
}
.icon-Align-JustifyAll:before {
	content: "\E61E";
}
.icon-Align-JustifyCenter:before {
	content: "\E61F";
}
.icon-Align-JustifyLeft:before {
	content: "\E620";
}
.icon-Align-JustifyRight:before {
	content: "\E621";
}
.icon-Align-Left:before {
	content: "\E622";
}
.icon-Align-Right:before {
	content: "\E623";
}
.icon-Alpha:before {
	content: "\E624";
}
.icon-Ambulance:before {
	content: "\E625";
}
.icon-AMX:before {
	content: "\E626";
}
.icon-Anchor-2:before {
	content: "\E627";
}
.icon-Anchor:before {
	content: "\E628";
}
.icon-Android-Store:before {
	content: "\E629";
}
.icon-Android:before {
	content: "\E62A";
}
.icon-Angel-Smiley:before {
	content: "\E62B";
}
.icon-Angel:before {
	content: "\E62C";
}
.icon-Angry:before {
	content: "\E62D";
}
.icon-Apple-Bite:before {
	content: "\E62E";
}
.icon-Apple-Store:before {
	content: "\E62F";
}
.icon-Apple:before {
	content: "\E630";
}
.icon-Approved-Window:before {
	content: "\E631";
}
.icon-Aquarius-2:before {
	content: "\E632";
}
.icon-Aquarius:before {
	content: "\E633";
}
.icon-Archery-2:before {
	content: "\E634";
}
.icon-Archery:before {
	content: "\E635";
}
.icon-Argentina:before {
	content: "\E636";
}
.icon-Aries-2:before {
	content: "\E637";
}
.icon-Aries:before {
	content: "\E638";
}
.icon-Army-Key:before {
	content: "\E639";
}
.icon-Arrow-Around:before {
	content: "\E63A";
}
.icon-Arrow-Back3:before {
	content: "\E63B";
}
.icon-Arrow-Back:before {
	content: "\E63C";
}
.icon-Arrow-Back2:before {
	content: "\E63D";
}
.icon-Arrow-Barrier:before {
	content: "\E63E";
}
.icon-Arrow-Circle:before {
	content: "\E63F";
}
.icon-Arrow-Cross:before {
	content: "\E640";
}
.icon-Arrow-Down:before {
	content: "\E641";
}
.icon-Arrow-Down2:before {
	content: "\E642";
}
.icon-Arrow-Down3:before {
	content: "\E643";
}
.icon-Arrow-DowninCircle:before {
	content: "\E644";
}
.icon-Arrow-Fork:before {
	content: "\E645";
}
.icon-Arrow-Forward:before {
	content: "\E646";
}
.icon-Arrow-Forward2:before {
	content: "\E647";
}
.icon-Arrow-From:before {
	content: "\E648";
}
.icon-Arrow-Inside:before {
	content: "\E649";
}
.icon-Arrow-Inside45:before {
	content: "\E64A";
}
.icon-Arrow-InsideGap:before {
	content: "\E64B";
}
.icon-Arrow-InsideGap45:before {
	content: "\E64C";
}
.icon-Arrow-Into:before {
	content: "\E64D";
}
.icon-Arrow-Join:before {
	content: "\E64E";
}
.icon-Arrow-Junction:before {
	content: "\E64F";
}
.icon-Arrow-Left:before {
	content: "\E650";
}
.icon-Arrow-Left2:before {
	content: "\E651";
}
.icon-Arrow-LeftinCircle:before {
	content: "\E652";
}
.icon-Arrow-Loop:before {
	content: "\E653";
}
.icon-Arrow-Merge:before {
	content: "\E654";
}
.icon-Arrow-Mix:before {
	content: "\E655";
}
.icon-Arrow-Next:before {
	content: "\E656";
}
.icon-Arrow-OutLeft:before {
	content: "\E657";
}
.icon-Arrow-OutRight:before {
	content: "\E658";
}
.icon-Arrow-Outside:before {
	content: "\E659";
}
.icon-Arrow-Outside45:before {
	content: "\E65A";
}
.icon-Arrow-OutsideGap:before {
	content: "\E65B";
}
.icon-Arrow-OutsideGap45:before {
	content: "\E65C";
}
.icon-Arrow-Over:before {
	content: "\E65D";
}
.icon-Arrow-Refresh:before {
	content: "\E65E";
}
.icon-Arrow-Refresh2:before {
	content: "\E65F";
}
.icon-Arrow-Right:before {
	content: "\E660";
}
.icon-Arrow-Right2:before {
	content: "\E661";
}
.icon-Arrow-RightinCircle:before {
	content: "\E662";
}
.icon-Arrow-Shuffle:before {
	content: "\E663";
}
.icon-Arrow-Squiggly:before {
	content: "\E664";
}
.icon-Arrow-Through:before {
	content: "\E665";
}
.icon-Arrow-To:before {
	content: "\E666";
}
.icon-Arrow-TurnLeft:before {
	content: "\E667";
}
.icon-Arrow-TurnRight:before {
	content: "\E668";
}
.icon-Arrow-Up:before {
	content: "\E669";
}
.icon-Arrow-Up2:before {
	content: "\E66A";
}
.icon-Arrow-Up3:before {
	content: "\E66B";
}
.icon-Arrow-UpinCircle:before {
	content: "\E66C";
}
.icon-Arrow-XLeft:before {
	content: "\E66D";
}
.icon-Arrow-XRight:before {
	content: "\E66E";
}
.icon-Ask:before {
	content: "\E66F";
}
.icon-Assistant:before {
	content: "\E670";
}
.icon-Astronaut:before {
	content: "\E671";
}
.icon-At-Sign:before {
	content: "\E672";
}
.icon-ATM:before {
	content: "\E673";
}
.icon-Atom:before {
	content: "\E674";
}
.icon-Audio:before {
	content: "\E675";
}
.icon-Auto-Flash:before {
	content: "\E676";
}
.icon-Autumn:before {
	content: "\E677";
}
.icon-Baby-Clothes:before {
	content: "\E678";
}
.icon-Baby-Clothes2:before {
	content: "\E679";
}
.icon-Baby-Cry:before {
	content: "\E67A";
}
.icon-Baby:before {
	content: "\E67B";
}
.icon-Back2:before {
	content: "\E67C";
}
.icon-Back-Media:before {
	content: "\E67D";
}
.icon-Back-Music:before {
	content: "\E67E";
}
.icon-Back:before {
	content: "\E67F";
}
.icon-Background:before {
	content: "\E680";
}
.icon-Bacteria:before {
	content: "\E681";
}
.icon-Bag-Coins:before {
	content: "\E682";
}
.icon-Bag-Items:before {
	content: "\E683";
}
.icon-Bag-Quantity:before {
	content: "\E684";
}
.icon-Bag:before {
	content: "\E685";
}
.icon-Bakelite:before {
	content: "\E686";
}
.icon-Ballet-Shoes:before {
	content: "\E687";
}
.icon-Balloon:before {
	content: "\E688";
}
.icon-Banana:before {
	content: "\E689";
}
.icon-Band-Aid:before {
	content: "\E68A";
}
.icon-Bank:before {
	content: "\E68B";
}
.icon-Bar-Chart:before {
	content: "\E68C";
}
.icon-Bar-Chart2:before {
	content: "\E68D";
}
.icon-Bar-Chart3:before {
	content: "\E68E";
}
.icon-Bar-Chart4:before {
	content: "\E68F";
}
.icon-Bar-Chart5:before {
	content: "\E690";
}
.icon-Bar-Code:before {
	content: "\E691";
}
.icon-Barricade-2:before {
	content: "\E692";
}
.icon-Barricade:before {
	content: "\E693";
}
.icon-Baseball:before {
	content: "\E694";
}
.icon-Basket-Ball:before {
	content: "\E695";
}
.icon-Basket-Coins:before {
	content: "\E696";
}
.icon-Basket-Items:before {
	content: "\E697";
}
.icon-Basket-Quantity:before {
	content: "\E698";
}
.icon-Bat-2:before {
	content: "\E699";
}
.icon-Bat:before {
	content: "\E69A";
}
.icon-Bathrobe:before {
	content: "\E69B";
}
.icon-Batman-Mask:before {
	content: "\E69C";
}
.icon-Battery-0:before {
	content: "\E69D";
}
.icon-Battery-25:before {
	content: "\E69E";
}
.icon-Battery-50:before {
	content: "\E69F";
}
.icon-Battery-75:before {
	content: "\E6A0";
}
.icon-Battery-100:before {
	content: "\E6A1";
}
.icon-Battery-Charge:before {
	content: "\E6A2";
}
.icon-Bear:before {
	content: "\E6A3";
}
.icon-Beard-2:before {
	content: "\E6A4";
}
.icon-Beard-3:before {
	content: "\E6A5";
}
.icon-Beard:before {
	content: "\E6A6";
}
.icon-Bebo:before {
	content: "\E6A7";
}
.icon-Bee:before {
	content: "\E6A8";
}
.icon-Beer-Glass:before {
	content: "\E6A9";
}
.icon-Beer:before {
	content: "\E6AA";
}
.icon-Bell-2:before {
	content: "\E6AB";
}
.icon-Bell:before {
	content: "\E6AC";
}
.icon-Belt-2:before {
	content: "\E6AD";
}
.icon-Belt-3:before {
	content: "\E6AE";
}
.icon-Belt:before {
	content: "\E6AF";
}
.icon-Berlin-Tower:before {
	content: "\E6B0";
}
.icon-Beta:before {
	content: "\E6B1";
}
.icon-Betvibes:before {
	content: "\E6B2";
}
.icon-Bicycle-2:before {
	content: "\E6B3";
}
.icon-Bicycle-3:before {
	content: "\E6B4";
}
.icon-Bicycle:before {
	content: "\E6B5";
}
.icon-Big-Bang:before {
	content: "\E6B6";
}
.icon-Big-Data:before {
	content: "\E6B7";
}
.icon-Bike-Helmet:before {
	content: "\E6B8";
}
.icon-Bikini:before {
	content: "\E6B9";
}
.icon-Bilk-Bottle2:before {
	content: "\E6BA";
}
.icon-Billing:before {
	content: "\E6BB";
}
.icon-Bing:before {
	content: "\E6BC";
}
.icon-Binocular:before {
	content: "\E6BD";
}
.icon-Bio-Hazard:before {
	content: "\E6BE";
}
.icon-Biotech:before {
	content: "\E6BF";
}
.icon-Bird-DeliveringLetter:before {
	content: "\E6C0";
}
.icon-Bird:before {
	content: "\E6C1";
}
.icon-Birthday-Cake:before {
	content: "\E6C2";
}
.icon-Bisexual:before {
	content: "\E6C3";
}
.icon-Bishop:before {
	content: "\E6C4";
}
.icon-Bitcoin:before {
	content: "\E6C5";
}
.icon-Black-Cat:before {
	content: "\E6C6";
}
.icon-Blackboard:before {
	content: "\E6C7";
}
.icon-Blinklist:before {
	content: "\E6C8";
}
.icon-Block-Cloud:before {
	content: "\E6C9";
}
.icon-Block-Window:before {
	content: "\E6CA";
}
.icon-Blogger:before {
	content: "\E6CB";
}
.icon-Blood:before {
	content: "\E6CC";
}
.icon-Blouse:before {
	content: "\E6CD";
}
.icon-Blueprint:before {
	content: "\E6CE";
}
.icon-Board:before {
	content: "\E6CF";
}
.icon-Bodybuilding:before {
	content: "\E6D0";
}
.icon-Bold-Text:before {
	content: "\E6D1";
}
.icon-Bone:before {
	content: "\E6D2";
}
.icon-Bones:before {
	content: "\E6D3";
}
.icon-Book:before {
	content: "\E6D4";
}
.icon-Bookmark:before {
	content: "\E6D5";
}
.icon-Books-2:before {
	content: "\E6D6";
}
.icon-Books:before {
	content: "\E6D7";
}
.icon-Boom:before {
	content: "\E6D8";
}
.icon-Boot-2:before {
	content: "\E6D9";
}
.icon-Boot:before {
	content: "\E6DA";
}
.icon-Bottom-ToTop:before {
	content: "\E6DB";
}
.icon-Bow-2:before {
	content: "\E6DC";
}
.icon-Bow-3:before {
	content: "\E6DD";
}
.icon-Bow-4:before {
	content: "\E6DE";
}
.icon-Bow-5:before {
	content: "\E6DF";
}
.icon-Bow-6:before {
	content: "\E6E0";
}
.icon-Bow:before {
	content: "\E6E1";
}
.icon-Bowling-2:before {
	content: "\E6E2";
}
.icon-Bowling:before {
	content: "\E6E3";
}
.icon-Box2:before {
	content: "\E6E4";
}
.icon-Box-Close:before {
	content: "\E6E5";
}
.icon-Box-Full:before {
	content: "\E6E6";
}
.icon-Box-Open:before {
	content: "\E6E7";
}
.icon-Box-withFolders:before {
	content: "\E6E8";
}
.icon-Box:before {
	content: "\E6E9";
}
.icon-Boy:before {
	content: "\E6EA";
}
.icon-Bra:before {
	content: "\E6EB";
}
.icon-Brain-2:before {
	content: "\E6EC";
}
.icon-Brain-3:before {
	content: "\E6ED";
}
.icon-Brain:before {
	content: "\E6EE";
}
.icon-Brazil:before {
	content: "\E6EF";
}
.icon-Bread-2:before {
	content: "\E6F0";
}
.icon-Bread:before {
	content: "\E6F1";
}
.icon-Bridge:before {
	content: "\E6F2";
}
.icon-Brightkite:before {
	content: "\E6F3";
}
.icon-Broke-Link2:before {
	content: "\E6F4";
}
.icon-Broken-Link:before {
	content: "\E6F5";
}
.icon-Broom:before {
	content: "\E6F6";
}
.icon-Brush:before {
	content: "\E6F7";
}
.icon-Bucket:before {
	content: "\E6F8";
}
.icon-Bug:before {
	content: "\E6F9";
}
.icon-Building:before {
	content: "\E6FA";
}
.icon-Bulleted-List:before {
	content: "\E6FB";
}
.icon-Bus-2:before {
	content: "\E6FC";
}
.icon-Bus:before {
	content: "\E6FD";
}
.icon-Business-Man:before {
	content: "\E6FE";
}
.icon-Business-ManWoman:before {
	content: "\E6FF";
}
.icon-Business-Mens:before {
	content: "\E700";
}
.icon-Business-Woman:before {
	content: "\E701";
}
.icon-Butterfly:before {
	content: "\E702";
}
.icon-Button:before {
	content: "\E703";
}
.icon-Cable-Car:before {
	content: "\E704";
}
.icon-Cake:before {
	content: "\E705";
}
.icon-Calculator-2:before {
	content: "\E706";
}
.icon-Calculator-3:before {
	content: "\E707";
}
.icon-Calculator:before {
	content: "\E708";
}
.icon-Calendar-2:before {
	content: "\E709";
}
.icon-Calendar-3:before {
	content: "\E70A";
}
.icon-Calendar-4:before {
	content: "\E70B";
}
.icon-Calendar-Clock:before {
	content: "\E70C";
}
.icon-Calendar:before {
	content: "\E70D";
}
.icon-Camel:before {
	content: "\E70E";
}
.icon-Camera-2:before {
	content: "\E70F";
}
.icon-Camera-3:before {
	content: "\E710";
}
.icon-Camera-4:before {
	content: "\E711";
}
.icon-Camera-5:before {
	content: "\E712";
}
.icon-Camera-Back:before {
	content: "\E713";
}
.icon-Camera:before {
	content: "\E714";
}
.icon-Can-2:before {
	content: "\E715";
}
.icon-Can:before {
	content: "\E716";
}
.icon-Canada:before {
	content: "\E717";
}
.icon-Cancer-2:before {
	content: "\E718";
}
.icon-Cancer-3:before {
	content: "\E719";
}
.icon-Cancer:before {
	content: "\E71A";
}
.icon-Candle:before {
	content: "\E71B";
}
.icon-Candy-Cane:before {
	content: "\E71C";
}
.icon-Candy:before {
	content: "\E71D";
}
.icon-Cannon:before {
	content: "\E71E";
}
.icon-Cap-2:before {
	content: "\E71F";
}
.icon-Cap-3:before {
	content: "\E720";
}
.icon-Cap-Smiley:before {
	content: "\E721";
}
.icon-Cap:before {
	content: "\E722";
}
.icon-Capricorn-2:before {
	content: "\E723";
}
.icon-Capricorn:before {
	content: "\E724";
}
.icon-Car-2:before {
	content: "\E725";
}
.icon-Car-3:before {
	content: "\E726";
}
.icon-Car-Coins:before {
	content: "\E727";
}
.icon-Car-Items:before {
	content: "\E728";
}
.icon-Car-Wheel:before {
	content: "\E729";
}
.icon-Car:before {
	content: "\E72A";
}
.icon-Cardigan:before {
	content: "\E72B";
}
.icon-Cardiovascular:before {
	content: "\E72C";
}
.icon-Cart-Quantity:before {
	content: "\E72D";
}
.icon-Casette-Tape:before {
	content: "\E72E";
}
.icon-Cash-Register:before {
	content: "\E72F";
}
.icon-Cash-register2:before {
	content: "\E730";
}
.icon-Castle:before {
	content: "\E731";
}
.icon-Cat:before {
	content: "\E732";
}
.icon-Cathedral:before {
	content: "\E733";
}
.icon-Cauldron:before {
	content: "\E734";
}
.icon-CD-2:before {
	content: "\E735";
}
.icon-CD-Cover:before {
	content: "\E736";
}
.icon-CD:before {
	content: "\E737";
}
.icon-Cello:before {
	content: "\E738";
}
.icon-Celsius:before {
	content: "\E739";
}
.icon-Chacked-Flag:before {
	content: "\E73A";
}
.icon-Chair:before {
	content: "\E73B";
}
.icon-Charger:before {
	content: "\E73C";
}
.icon-Check-2:before {
	content: "\E73D";
}
.icon-Check:before {
	content: "\E73E";
}
.icon-Checked-User:before {
	content: "\E73F";
}
.icon-Checkmate:before {
	content: "\E740";
}
.icon-Checkout-Bag:before {
	content: "\E741";
}
.icon-Checkout-Basket:before {
	content: "\E742";
}
.icon-Checkout:before {
	content: "\E743";
}
.icon-Cheese:before {
	content: "\E744";
}
.icon-Cheetah:before {
	content: "\E745";
}
.icon-Chef-Hat:before {
	content: "\E746";
}
.icon-Chef-Hat2:before {
	content: "\E747";
}
.icon-Chef:before {
	content: "\E748";
}
.icon-Chemical-2:before {
	content: "\E749";
}
.icon-Chemical-3:before {
	content: "\E74A";
}
.icon-Chemical-4:before {
	content: "\E74B";
}
.icon-Chemical-5:before {
	content: "\E74C";
}
.icon-Chemical:before {
	content: "\E74D";
}
.icon-Chess-Board:before {
	content: "\E74E";
}
.icon-Chess:before {
	content: "\E74F";
}
.icon-Chicken:before {
	content: "\E750";
}
.icon-Chile:before {
	content: "\E751";
}
.icon-Chimney:before {
	content: "\E752";
}
.icon-China:before {
	content: "\E753";
}
.icon-Chinese-Temple:before {
	content: "\E754";
}
.icon-Chip:before {
	content: "\E755";
}
.icon-Chopsticks-2:before {
	content: "\E756";
}
.icon-Chopsticks:before {
	content: "\E757";
}
.icon-Christmas-Ball:before {
	content: "\E758";
}
.icon-Christmas-Bell:before {
	content: "\E759";
}
.icon-Christmas-Candle:before {
	content: "\E75A";
}
.icon-Christmas-Hat:before {
	content: "\E75B";
}
.icon-Christmas-Sleigh:before {
	content: "\E75C";
}
.icon-Christmas-Snowman:before {
	content: "\E75D";
}
.icon-Christmas-Sock:before {
	content: "\E75E";
}
.icon-Christmas-Tree:before {
	content: "\E75F";
}
.icon-Christmas:before {
	content: "\E760";
}
.icon-Chrome:before {
	content: "\E761";
}
.icon-Chrysler-Building:before {
	content: "\E762";
}
.icon-Cinema:before {
	content: "\E763";
}
.icon-Circular-Point:before {
	content: "\E764";
}
.icon-City-Hall:before {
	content: "\E765";
}
.icon-Clamp:before {
	content: "\E766";
}
.icon-Clapperboard-Close:before {
	content: "\E767";
}
.icon-Clapperboard-Open:before {
	content: "\E768";
}
.icon-Claps:before {
	content: "\E769";
}
.icon-Clef:before {
	content: "\E76A";
}
.icon-Clinic:before {
	content: "\E76B";
}
.icon-Clock-2:before {
	content: "\E76C";
}
.icon-Clock-3:before {
	content: "\E76D";
}
.icon-Clock-4:before {
	content: "\E76E";
}
.icon-Clock-Back:before {
	content: "\E76F";
}
.icon-Clock-Forward:before {
	content: "\E770";
}
.icon-Clock:before {
	content: "\E771";
}
.icon-Close-Window:before {
	content: "\E772";
}
.icon-Close:before {
	content: "\E773";
}
.icon-Clothing-Store:before {
	content: "\E774";
}
.icon-Cloud--:before {
	content: "\E775";
}
.icon-Cloud-:before {
	content: "\E776";
}
.icon-Cloud-Camera:before {
	content: "\E777";
}
.icon-Cloud-Computer:before {
	content: "\E778";
}
.icon-Cloud-Email:before {
	content: "\E779";
}
.icon-Cloud-Hail:before {
	content: "\E77A";
}
.icon-Cloud-Laptop:before {
	content: "\E77B";
}
.icon-Cloud-Lock:before {
	content: "\E77C";
}
.icon-Cloud-Moon:before {
	content: "\E77D";
}
.icon-Cloud-Music:before {
	content: "\E77E";
}
.icon-Cloud-Picture:before {
	content: "\E77F";
}
.icon-Cloud-Rain:before {
	content: "\E780";
}
.icon-Cloud-Remove:before {
	content: "\E781";
}
.icon-Cloud-Secure:before {
	content: "\E782";
}
.icon-Cloud-Settings:before {
	content: "\E783";
}
.icon-Cloud-Smartphone:before {
	content: "\E784";
}
.icon-Cloud-Snow:before {
	content: "\E785";
}
.icon-Cloud-Sun:before {
	content: "\E786";
}
.icon-Cloud-Tablet:before {
	content: "\E787";
}
.icon-Cloud-Video:before {
	content: "\E788";
}
.icon-Cloud-Weather:before {
	content: "\E789";
}
.icon-Cloud:before {
	content: "\E78A";
}
.icon-Clouds-Weather:before {
	content: "\E78B";
}
.icon-Clouds:before {
	content: "\E78C";
}
.icon-Clown:before {
	content: "\E78D";
}
.icon-CMYK:before {
	content: "\E78E";
}
.icon-Coat:before {
	content: "\E78F";
}
.icon-Cocktail:before {
	content: "\E790";
}
.icon-Coconut:before {
	content: "\E791";
}
.icon-Code-Window:before {
	content: "\E792";
}
.icon-Coding:before {
	content: "\E793";
}
.icon-Coffee-2:before {
	content: "\E794";
}
.icon-Coffee-Bean:before {
	content: "\E795";
}
.icon-Coffee-Machine:before {
	content: "\E796";
}
.icon-Coffee-toGo:before {
	content: "\E797";
}
.icon-Coffee:before {
	content: "\E798";
}
.icon-Coffin:before {
	content: "\E799";
}
.icon-Coin:before {
	content: "\E79A";
}
.icon-Coins-2:before {
	content: "\E79B";
}
.icon-Coins-3:before {
	content: "\E79C";
}
.icon-Coins:before {
	content: "\E79D";
}
.icon-Colombia:before {
	content: "\E79E";
}
.icon-Colosseum:before {
	content: "\E79F";
}
.icon-Column-2:before {
	content: "\E7A0";
}
.icon-Column-3:before {
	content: "\E7A1";
}
.icon-Column:before {
	content: "\E7A2";
}
.icon-Comb-2:before {
	content: "\E7A3";
}
.icon-Comb:before {
	content: "\E7A4";
}
.icon-Communication-Tower:before {
	content: "\E7A5";
}
.icon-Communication-Tower2:before {
	content: "\E7A6";
}
.icon-Compass-2:before {
	content: "\E7A7";
}
.icon-Compass-3:before {
	content: "\E7A8";
}
.icon-Compass-4:before {
	content: "\E7A9";
}
.icon-Compass-Rose:before {
	content: "\E7AA";
}
.icon-Compass:before {
	content: "\E7AB";
}
.icon-Computer-2:before {
	content: "\E7AC";
}
.icon-Computer-3:before {
	content: "\E7AD";
}
.icon-Computer-Secure:before {
	content: "\E7AE";
}
.icon-Computer:before {
	content: "\E7AF";
}
.icon-Conference:before {
	content: "\E7B0";
}
.icon-Confused:before {
	content: "\E7B1";
}
.icon-Conservation:before {
	content: "\E7B2";
}
.icon-Consulting:before {
	content: "\E7B3";
}
.icon-Contrast:before {
	content: "\E7B4";
}
.icon-Control-2:before {
	content: "\E7B5";
}
.icon-Control:before {
	content: "\E7B6";
}
.icon-Cookie-Man:before {
	content: "\E7B7";
}
.icon-Cookies:before {
	content: "\E7B8";
}
.icon-Cool-Guy:before {
	content: "\E7B9";
}
.icon-Cool:before {
	content: "\E7BA";
}
.icon-Copyright:before {
	content: "\E7BB";
}
.icon-Costume:before {
	content: "\E7BC";
}
.icon-Couple-Sign:before {
	content: "\E7BD";
}
.icon-Cow:before {
	content: "\E7BE";
}
.icon-CPU:before {
	content: "\E7BF";
}
.icon-Crane:before {
	content: "\E7C0";
}
.icon-Cranium:before {
	content: "\E7C1";
}
.icon-Credit-Card:before {
	content: "\E7C2";
}
.icon-Credit-Card2:before {
	content: "\E7C3";
}
.icon-Credit-Card3:before {
	content: "\E7C4";
}
.icon-Cricket:before {
	content: "\E7C5";
}
.icon-Criminal:before {
	content: "\E7C6";
}
.icon-Croissant:before {
	content: "\E7C7";
}
.icon-Crop-2:before {
	content: "\E7C8";
}
.icon-Crop-3:before {
	content: "\E7C9";
}
.icon-Crown-2:before {
	content: "\E7CA";
}
.icon-Crown:before {
	content: "\E7CB";
}
.icon-Crying:before {
	content: "\E7CC";
}
.icon-Cube-Molecule:before {
	content: "\E7CD";
}
.icon-Cube-Molecule2:before {
	content: "\E7CE";
}
.icon-Cupcake:before {
	content: "\E7CF";
}
.icon-Cursor-Click:before {
	content: "\E7D0";
}
.icon-Cursor-Click2:before {
	content: "\E7D1";
}
.icon-Cursor-Move:before {
	content: "\E7D2";
}
.icon-Cursor-Move2:before {
	content: "\E7D3";
}
.icon-Cursor-Select:before {
	content: "\E7D4";
}
.icon-Cursor:before {
	content: "\E7D5";
}
.icon-D-Eyeglasses:before {
	content: "\E7D6";
}
.icon-D-Eyeglasses2:before {
	content: "\E7D7";
}
.icon-Dam:before {
	content: "\E7D8";
}
.icon-Danemark:before {
	content: "\E7D9";
}
.icon-Danger-2:before {
	content: "\E7DA";
}
.icon-Danger:before {
	content: "\E7DB";
}
.icon-Dashboard:before {
	content: "\E7DC";
}
.icon-Data-Backup:before {
	content: "\E7DD";
}
.icon-Data-Block:before {
	content: "\E7DE";
}
.icon-Data-Center:before {
	content: "\E7DF";
}
.icon-Data-Clock:before {
	content: "\E7E0";
}
.icon-Data-Cloud:before {
	content: "\E7E1";
}
.icon-Data-Compress:before {
	content: "\E7E2";
}
.icon-Data-Copy:before {
	content: "\E7E3";
}
.icon-Data-Download:before {
	content: "\E7E4";
}
.icon-Data-Financial:before {
	content: "\E7E5";
}
.icon-Data-Key:before {
	content: "\E7E6";
}
.icon-Data-Lock:before {
	content: "\E7E7";
}
.icon-Data-Network:before {
	content: "\E7E8";
}
.icon-Data-Password:before {
	content: "\E7E9";
}
.icon-Data-Power:before {
	content: "\E7EA";
}
.icon-Data-Refresh:before {
	content: "\E7EB";
}
.icon-Data-Save:before {
	content: "\E7EC";
}
.icon-Data-Search:before {
	content: "\E7ED";
}
.icon-Data-Security:before {
	content: "\E7EE";
}
.icon-Data-Settings:before {
	content: "\E7EF";
}
.icon-Data-Sharing:before {
	content: "\E7F0";
}
.icon-Data-Shield:before {
	content: "\E7F1";
}
.icon-Data-Signal:before {
	content: "\E7F2";
}
.icon-Data-Storage:before {
	content: "\E7F3";
}
.icon-Data-Stream:before {
	content: "\E7F4";
}
.icon-Data-Transfer:before {
	content: "\E7F5";
}
.icon-Data-Unlock:before {
	content: "\E7F6";
}
.icon-Data-Upload:before {
	content: "\E7F7";
}
.icon-Data-Yes:before {
	content: "\E7F8";
}
.icon-Data:before {
	content: "\E7F9";
}
.icon-David-Star:before {
	content: "\E7FA";
}
.icon-Daylight:before {
	content: "\E7FB";
}
.icon-Death:before {
	content: "\E7FC";
}
.icon-Debian:before {
	content: "\E7FD";
}
.icon-Dec:before {
	content: "\E7FE";
}
.icon-Decrase-Inedit:before {
	content: "\E7FF";
}
.icon-Deer-2:before {
	content: "\E800";
}
.icon-Deer:before {
	content: "\E801";
}
.icon-Delete-File:before {
	content: "\E802";
}
.icon-Delete-Window:before {
	content: "\E803";
}
.icon-Delicious:before {
	content: "\E804";
}
.icon-Depression:before {
	content: "\E805";
}
.icon-Deviantart:before {
	content: "\E806";
}
.icon-Device-SyncwithCloud:before {
	content: "\E807";
}
.icon-Diamond:before {
	content: "\E808";
}
.icon-Dice-2:before {
	content: "\E809";
}
.icon-Dice:before {
	content: "\E80A";
}
.icon-Digg:before {
	content: "\E80B";
}
.icon-Digital-Drawing:before {
	content: "\E80C";
}
.icon-Diigo:before {
	content: "\E80D";
}
.icon-Dinosaur:before {
	content: "\E80E";
}
.icon-Diploma-2:before {
	content: "\E80F";
}
.icon-Diploma:before {
	content: "\E810";
}
.icon-Direction-East:before {
	content: "\E811";
}
.icon-Direction-North:before {
	content: "\E812";
}
.icon-Direction-South:before {
	content: "\E813";
}
.icon-Direction-West:before {
	content: "\E814";
}
.icon-Director:before {
	content: "\E815";
}
.icon-Disk:before {
	content: "\E816";
}
.icon-Dj:before {
	content: "\E817";
}
.icon-DNA-2:before {
	content: "\E818";
}
.icon-DNA-Helix:before {
	content: "\E819";
}
.icon-DNA:before {
	content: "\E81A";
}
.icon-Doctor:before {
	content: "\E81B";
}
.icon-Dog:before {
	content: "\E81C";
}
.icon-Dollar-Sign:before {
	content: "\E81D";
}
.icon-Dollar-Sign2:before {
	content: "\E81E";
}
.icon-Dollar:before {
	content: "\E81F";
}
.icon-Dolphin:before {
	content: "\E820";
}
.icon-Domino:before {
	content: "\E821";
}
.icon-Door-Hanger:before {
	content: "\E822";
}
.icon-Door:before {
	content: "\E823";
}
.icon-Doplr:before {
	content: "\E824";
}
.icon-Double-Circle:before {
	content: "\E825";
}
.icon-Double-Tap:before {
	content: "\E826";
}
.icon-Doughnut:before {
	content: "\E827";
}
.icon-Dove:before {
	content: "\E828";
}
.icon-Down-2:before {
	content: "\E829";
}
.icon-Down-3:before {
	content: "\E82A";
}
.icon-Down-4:before {
	content: "\E82B";
}
.icon-Down:before {
	content: "\E82C";
}
.icon-Download-2:before {
	content: "\E82D";
}
.icon-Download-fromCloud:before {
	content: "\E82E";
}
.icon-Download-Window:before {
	content: "\E82F";
}
.icon-Download:before {
	content: "\E830";
}
.icon-Downward:before {
	content: "\E831";
}
.icon-Drag-Down:before {
	content: "\E832";
}
.icon-Drag-Left:before {
	content: "\E833";
}
.icon-Drag-Right:before {
	content: "\E834";
}
.icon-Drag-Up:before {
	content: "\E835";
}
.icon-Drag:before {
	content: "\E836";
}
.icon-Dress:before {
	content: "\E837";
}
.icon-Drill-2:before {
	content: "\E838";
}
.icon-Drill:before {
	content: "\E839";
}
.icon-Drop:before {
	content: "\E83A";
}
.icon-Dropbox:before {
	content: "\E83B";
}
.icon-Drum:before {
	content: "\E83C";
}
.icon-Dry:before {
	content: "\E83D";
}
.icon-Duck:before {
	content: "\E83E";
}
.icon-Dumbbell:before {
	content: "\E83F";
}
.icon-Duplicate-Layer:before {
	content: "\E840";
}
.icon-Duplicate-Window:before {
	content: "\E841";
}
.icon-DVD:before {
	content: "\E842";
}
.icon-Eagle:before {
	content: "\E843";
}
.icon-Ear:before {
	content: "\E844";
}
.icon-Earphones-2:before {
	content: "\E845";
}
.icon-Earphones:before {
	content: "\E846";
}
.icon-Eci-Icon:before {
	content: "\E847";
}
.icon-Edit-Map:before {
	content: "\E848";
}
.icon-Edit:before {
	content: "\E849";
}
.icon-Eggs:before {
	content: "\E84A";
}
.icon-Egypt:before {
	content: "\E84B";
}
.icon-Eifel-Tower:before {
	content: "\E84C";
}
.icon-eject-2:before {
	content: "\E84D";
}
.icon-Eject:before {
	content: "\E84E";
}
.icon-El-Castillo:before {
	content: "\E84F";
}
.icon-Elbow:before {
	content: "\E850";
}
.icon-Electric-Guitar:before {
	content: "\E851";
}
.icon-Electricity:before {
	content: "\E852";
}
.icon-Elephant:before {
	content: "\E853";
}
.icon-Email:before {
	content: "\E854";
}
.icon-Embassy:before {
	content: "\E855";
}
.icon-Empire-StateBuilding:before {
	content: "\E856";
}
.icon-Empty-Box:before {
	content: "\E857";
}
.icon-End2:before {
	content: "\E858";
}
.icon-End-2:before {
	content: "\E859";
}
.icon-End:before {
	content: "\E85A";
}
.icon-Endways:before {
	content: "\E85B";
}
.icon-Engineering:before {
	content: "\E85C";
}
.icon-Envelope-2:before {
	content: "\E85D";
}
.icon-Envelope:before {
	content: "\E85E";
}
.icon-Environmental-2:before {
	content: "\E85F";
}
.icon-Environmental-3:before {
	content: "\E860";
}
.icon-Environmental:before {
	content: "\E861";
}
.icon-Equalizer:before {
	content: "\E862";
}
.icon-Eraser-2:before {
	content: "\E863";
}
.icon-Eraser-3:before {
	content: "\E864";
}
.icon-Eraser:before {
	content: "\E865";
}
.icon-Error-404Window:before {
	content: "\E866";
}
.icon-Euro-Sign:before {
	content: "\E867";
}
.icon-Euro-Sign2:before {
	content: "\E868";
}
.icon-Euro:before {
	content: "\E869";
}
.icon-Evernote:before {
	content: "\E86A";
}
.icon-Evil:before {
	content: "\E86B";
}
.icon-Explode:before {
	content: "\E86C";
}
.icon-Eye-2:before {
	content: "\E86D";
}
.icon-Eye-Blind:before {
	content: "\E86E";
}
.icon-Eye-Invisible:before {
	content: "\E86F";
}
.icon-Eye-Scan:before {
	content: "\E870";
}
.icon-Eye-Visible:before {
	content: "\E871";
}
.icon-Eye:before {
	content: "\E872";
}
.icon-Eyebrow-2:before {
	content: "\E873";
}
.icon-Eyebrow-3:before {
	content: "\E874";
}
.icon-Eyebrow:before {
	content: "\E875";
}
.icon-Eyeglasses-Smiley:before {
	content: "\E876";
}
.icon-Eyeglasses-Smiley2:before {
	content: "\E877";
}
.icon-Face-Style:before {
	content: "\E878";
}
.icon-Face-Style2:before {
	content: "\E879";
}
.icon-Face-Style3:before {
	content: "\E87A";
}
.icon-Face-Style4:before {
	content: "\E87B";
}
.icon-Face-Style5:before {
	content: "\E87C";
}
.icon-Face-Style6:before {
	content: "\E87D";
}
.icon-Facebook-2:before {
	content: "\E87E";
}
.icon-Facebook:before {
	content: "\E87F";
}
.icon-Factory-2:before {
	content: "\E880";
}
.icon-Factory:before {
	content: "\E881";
}
.icon-Fahrenheit:before {
	content: "\E882";
}
.icon-Family-Sign:before {
	content: "\E883";
}
.icon-Fan:before {
	content: "\E884";
}
.icon-Farmer:before {
	content: "\E885";
}
.icon-Fashion:before {
	content: "\E886";
}
.icon-Favorite-Window:before {
	content: "\E887";
}
.icon-Fax:before {
	content: "\E888";
}
.icon-Feather:before {
	content: "\E889";
}
.icon-Feedburner:before {
	content: "\E88A";
}
.icon-Female-2:before {
	content: "\E88B";
}
.icon-Female-Sign:before {
	content: "\E88C";
}
.icon-Female:before {
	content: "\E88D";
}
.icon-File-Block:before {
	content: "\E88E";
}
.icon-File-Bookmark:before {
	content: "\E88F";
}
.icon-File-Chart:before {
	content: "\E890";
}
.icon-File-Clipboard:before {
	content: "\E891";
}
.icon-File-ClipboardFileText:before {
	content: "\E892";
}
.icon-File-ClipboardTextImage:before {
	content: "\E893";
}
.icon-File-Cloud:before {
	content: "\E894";
}
.icon-File-Copy:before {
	content: "\E895";
}
.icon-File-Copy2:before {
	content: "\E896";
}
.icon-File-CSV:before {
	content: "\E897";
}
.icon-File-Download:before {
	content: "\E898";
}
.icon-File-Edit:before {
	content: "\E899";
}
.icon-File-Excel:before {
	content: "\E89A";
}
.icon-File-Favorite:before {
	content: "\E89B";
}
.icon-File-Fire:before {
	content: "\E89C";
}
.icon-File-Graph:before {
	content: "\E89D";
}
.icon-File-Hide:before {
	content: "\E89E";
}
.icon-File-Horizontal:before {
	content: "\E89F";
}
.icon-File-HorizontalText:before {
	content: "\E8A0";
}
.icon-File-HTML:before {
	content: "\E8A1";
}
.icon-File-JPG:before {
	content: "\E8A2";
}
.icon-File-Link:before {
	content: "\E8A3";
}
.icon-File-Loading:before {
	content: "\E8A4";
}
.icon-File-Lock:before {
	content: "\E8A5";
}
.icon-File-Love:before {
	content: "\E8A6";
}
.icon-File-Music:before {
	content: "\E8A7";
}
.icon-File-Network:before {
	content: "\E8A8";
}
.icon-File-Pictures:before {
	content: "\E8A9";
}
.icon-File-Pie:before {
	content: "\E8AA";
}
.icon-File-Presentation:before {
	content: "\E8AB";
}
.icon-File-Refresh:before {
	content: "\E8AC";
}
.icon-File-Search:before {
	content: "\E8AD";
}
.icon-File-Settings:before {
	content: "\E8AE";
}
.icon-File-Share:before {
	content: "\E8AF";
}
.icon-File-TextImage:before {
	content: "\E8B0";
}
.icon-File-Trash:before {
	content: "\E8B1";
}
.icon-File-TXT:before {
	content: "\E8B2";
}
.icon-File-Upload:before {
	content: "\E8B3";
}
.icon-File-Video:before {
	content: "\E8B4";
}
.icon-File-Word:before {
	content: "\E8B5";
}
.icon-File-Zip:before {
	content: "\E8B6";
}
.icon-File:before {
	content: "\E8B7";
}
.icon-Files:before {
	content: "\E8B8";
}
.icon-Film-Board:before {
	content: "\E8B9";
}
.icon-Film-Cartridge:before {
	content: "\E8BA";
}
.icon-Film-Strip:before {
	content: "\E8BB";
}
.icon-Film-Video:before {
	content: "\E8BC";
}
.icon-Film:before {
	content: "\E8BD";
}
.icon-Filter-2:before {
	content: "\E8BE";
}
.icon-Filter:before {
	content: "\E8BF";
}
.icon-Financial:before {
	content: "\E8C0";
}
.icon-Find-User:before {
	content: "\E8C1";
}
.icon-Finger-DragFourSides:before {
	content: "\E8C2";
}
.icon-Finger-DragTwoSides:before {
	content: "\E8C3";
}
.icon-Finger-Print:before {
	content: "\E8C4";
}
.icon-Finger:before {
	content: "\E8C5";
}
.icon-Fingerprint-2:before {
	content: "\E8C6";
}
.icon-Fingerprint:before {
	content: "\E8C7";
}
.icon-Fire-Flame:before {
	content: "\E8C8";
}
.icon-Fire-Flame2:before {
	content: "\E8C9";
}
.icon-Fire-Hydrant:before {
	content: "\E8CA";
}
.icon-Fire-Staion:before {
	content: "\E8CB";
}
.icon-Firefox:before {
	content: "\E8CC";
}
.icon-Firewall:before {
	content: "\E8CD";
}
.icon-First-Aid:before {
	content: "\E8CE";
}
.icon-First:before {
	content: "\E8CF";
}
.icon-Fish-Food:before {
	content: "\E8D0";
}
.icon-Fish:before {
	content: "\E8D1";
}
.icon-Fit-To:before {
	content: "\E8D2";
}
.icon-Fit-To2:before {
	content: "\E8D3";
}
.icon-Five-Fingers:before {
	content: "\E8D4";
}
.icon-Five-FingersDrag:before {
	content: "\E8D5";
}
.icon-Five-FingersDrag2:before {
	content: "\E8D6";
}
.icon-Five-FingersTouch:before {
	content: "\E8D7";
}
.icon-Flag-2:before {
	content: "\E8D8";
}
.icon-Flag-3:before {
	content: "\E8D9";
}
.icon-Flag-4:before {
	content: "\E8DA";
}
.icon-Flag-5:before {
	content: "\E8DB";
}
.icon-Flag-6:before {
	content: "\E8DC";
}
.icon-Flag:before {
	content: "\E8DD";
}
.icon-Flamingo:before {
	content: "\E8DE";
}
.icon-Flash-2:before {
	content: "\E8DF";
}
.icon-Flash-Video:before {
	content: "\E8E0";
}
.icon-Flash:before {
	content: "\E8E1";
}
.icon-Flashlight:before {
	content: "\E8E2";
}
.icon-Flask-2:before {
	content: "\E8E3";
}
.icon-Flask:before {
	content: "\E8E4";
}
.icon-Flick:before {
	content: "\E8E5";
}
.icon-Flickr:before {
	content: "\E8E6";
}
.icon-Flowerpot:before {
	content: "\E8E7";
}
.icon-Fluorescent:before {
	content: "\E8E8";
}
.icon-Fog-Day:before {
	content: "\E8E9";
}
.icon-Fog-Night:before {
	content: "\E8EA";
}
.icon-Folder-Add:before {
	content: "\E8EB";
}
.icon-Folder-Archive:before {
	content: "\E8EC";
}
.icon-Folder-Binder:before {
	content: "\E8ED";
}
.icon-Folder-Binder2:before {
	content: "\E8EE";
}
.icon-Folder-Block:before {
	content: "\E8EF";
}
.icon-Folder-Bookmark:before {
	content: "\E8F0";
}
.icon-Folder-Close:before {
	content: "\E8F1";
}
.icon-Folder-Cloud:before {
	content: "\E8F2";
}
.icon-Folder-Delete:before {
	content: "\E8F3";
}
.icon-Folder-Download:before {
	content: "\E8F4";
}
.icon-Folder-Edit:before {
	content: "\E8F5";
}
.icon-Folder-Favorite:before {
	content: "\E8F6";
}
.icon-Folder-Fire:before {
	content: "\E8F7";
}
.icon-Folder-Hide:before {
	content: "\E8F8";
}
.icon-Folder-Link:before {
	content: "\E8F9";
}
.icon-Folder-Loading:before {
	content: "\E8FA";
}
.icon-Folder-Lock:before {
	content: "\E8FB";
}
.icon-Folder-Love:before {
	content: "\E8FC";
}
.icon-Folder-Music:before {
	content: "\E8FD";
}
.icon-Folder-Network:before {
	content: "\E8FE";
}
.icon-Folder-Open:before {
	content: "\E8FF";
}
.icon-Folder-Open2:before {
	content: "\E900";
}
.icon-Folder-Organizing:before {
	content: "\E901";
}
.icon-Folder-Pictures:before {
	content: "\E902";
}
.icon-Folder-Refresh:before {
	content: "\E903";
}
.icon-Folder-Remove-:before {
	content: "\E904";
}
.icon-Folder-Search:before {
	content: "\E905";
}
.icon-Folder-Settings:before {
	content: "\E906";
}
.icon-Folder-Share:before {
	content: "\E907";
}
.icon-Folder-Trash:before {
	content: "\E908";
}
.icon-Folder-Upload:before {
	content: "\E909";
}
.icon-Folder-Video:before {
	content: "\E90A";
}
.icon-Folder-WithDocument:before {
	content: "\E90B";
}
.icon-Folder-Zip:before {
	content: "\E90C";
}
.icon-Folder:before {
	content: "\E90D";
}
.icon-Folders:before {
	content: "\E90E";
}
.icon-Font-Color:before {
	content: "\E90F";
}
.icon-Font-Name:before {
	content: "\E910";
}
.icon-Font-Size:before {
	content: "\E911";
}
.icon-Font-Style:before {
	content: "\E912";
}
.icon-Font-StyleSubscript:before {
	content: "\E913";
}
.icon-Font-StyleSuperscript:before {
	content: "\E914";
}
.icon-Font-Window:before {
	content: "\E915";
}
.icon-Foot-2:before {
	content: "\E916";
}
.icon-Foot:before {
	content: "\E917";
}
.icon-Football-2:before {
	content: "\E918";
}
.icon-Football:before {
	content: "\E919";
}
.icon-Footprint-2:before {
	content: "\E91A";
}
.icon-Footprint-3:before {
	content: "\E91B";
}
.icon-Footprint:before {
	content: "\E91C";
}
.icon-Forest:before {
	content: "\E91D";
}
.icon-Fork:before {
	content: "\E91E";
}
.icon-Formspring:before {
	content: "\E91F";
}
.icon-Formula:before {
	content: "\E920";
}
.icon-Forsquare:before {
	content: "\E921";
}
.icon-Forward:before {
	content: "\E922";
}
.icon-Fountain-Pen:before {
	content: "\E923";
}
.icon-Four-Fingers:before {
	content: "\E924";
}
.icon-Four-FingersDrag:before {
	content: "\E925";
}
.icon-Four-FingersDrag2:before {
	content: "\E926";
}
.icon-Four-FingersTouch:before {
	content: "\E927";
}
.icon-Fox:before {
	content: "\E928";
}
.icon-Frankenstein:before {
	content: "\E929";
}
.icon-French-Fries:before {
	content: "\E92A";
}
.icon-Friendfeed:before {
	content: "\E92B";
}
.icon-Friendster:before {
	content: "\E92C";
}
.icon-Frog:before {
	content: "\E92D";
}
.icon-Fruits:before {
	content: "\E92E";
}
.icon-Fuel:before {
	content: "\E92F";
}
.icon-Full-Bag:before {
	content: "\E930";
}
.icon-Full-Basket:before {
	content: "\E931";
}
.icon-Full-Cart:before {
	content: "\E932";
}
.icon-Full-Moon:before {
	content: "\E933";
}
.icon-Full-Screen:before {
	content: "\E934";
}
.icon-Full-Screen2:before {
	content: "\E935";
}
.icon-Full-View:before {
	content: "\E936";
}
.icon-Full-View2:before {
	content: "\E937";
}
.icon-Full-ViewWindow:before {
	content: "\E938";
}
.icon-Function:before {
	content: "\E939";
}
.icon-Funky:before {
	content: "\E93A";
}
.icon-Funny-Bicycle:before {
	content: "\E93B";
}
.icon-Furl:before {
	content: "\E93C";
}
.icon-Gamepad-2:before {
	content: "\E93D";
}
.icon-Gamepad:before {
	content: "\E93E";
}
.icon-Gas-Pump:before {
	content: "\E93F";
}
.icon-Gaugage-2:before {
	content: "\E940";
}
.icon-Gaugage:before {
	content: "\E941";
}
.icon-Gay:before {
	content: "\E942";
}
.icon-Gear-2:before {
	content: "\E943";
}
.icon-Gear:before {
	content: "\E944";
}
.icon-Gears-2:before {
	content: "\E945";
}
.icon-Gears:before {
	content: "\E946";
}
.icon-Geek-2:before {
	content: "\E947";
}
.icon-Geek:before {
	content: "\E948";
}
.icon-Gemini-2:before {
	content: "\E949";
}
.icon-Gemini:before {
	content: "\E94A";
}
.icon-Genius:before {
	content: "\E94B";
}
.icon-Gentleman:before {
	content: "\E94C";
}
.icon-Geo--:before {
	content: "\E94D";
}
.icon-Geo-:before {
	content: "\E94E";
}
.icon-Geo-Close:before {
	content: "\E94F";
}
.icon-Geo-Love:before {
	content: "\E950";
}
.icon-Geo-Number:before {
	content: "\E951";
}
.icon-Geo-Star:before {
	content: "\E952";
}
.icon-Geo:before {
	content: "\E953";
}
.icon-Geo2--:before {
	content: "\E954";
}
.icon-Geo2-:before {
	content: "\E955";
}
.icon-Geo2-Close:before {
	content: "\E956";
}
.icon-Geo2-Love:before {
	content: "\E957";
}
.icon-Geo2-Number:before {
	content: "\E958";
}
.icon-Geo2-Star:before {
	content: "\E959";
}
.icon-Geo2:before {
	content: "\E95A";
}
.icon-Geo3--:before {
	content: "\E95B";
}
.icon-Geo3-:before {
	content: "\E95C";
}
.icon-Geo3-Close:before {
	content: "\E95D";
}
.icon-Geo3-Love:before {
	content: "\E95E";
}
.icon-Geo3-Number:before {
	content: "\E95F";
}
.icon-Geo3-Star:before {
	content: "\E960";
}
.icon-Geo3:before {
	content: "\E961";
}
.icon-Gey:before {
	content: "\E962";
}
.icon-Gift-Box:before {
	content: "\E963";
}
.icon-Giraffe:before {
	content: "\E964";
}
.icon-Girl:before {
	content: "\E965";
}
.icon-Glass-Water:before {
	content: "\E966";
}
.icon-Glasses-2:before {
	content: "\E967";
}
.icon-Glasses-3:before {
	content: "\E968";
}
.icon-Glasses:before {
	content: "\E969";
}
.icon-Global-Position:before {
	content: "\E96A";
}
.icon-Globe-2:before {
	content: "\E96B";
}
.icon-Globe:before {
	content: "\E96C";
}
.icon-Gloves:before {
	content: "\E96D";
}
.icon-Go-Bottom:before {
	content: "\E96E";
}
.icon-Go-Top:before {
	content: "\E96F";
}
.icon-Goggles:before {
	content: "\E970";
}
.icon-Golf-2:before {
	content: "\E971";
}
.icon-Golf:before {
	content: "\E972";
}
.icon-Google-Buzz:before {
	content: "\E973";
}
.icon-Google-Drive:before {
	content: "\E974";
}
.icon-Google-Play:before {
	content: "\E975";
}
.icon-Google-Plus:before {
	content: "\E976";
}
.icon-Google:before {
	content: "\E977";
}
.icon-Gopro:before {
	content: "\E978";
}
.icon-Gorilla:before {
	content: "\E979";
}
.icon-Gowalla:before {
	content: "\E97A";
}
.icon-Grave:before {
	content: "\E97B";
}
.icon-Graveyard:before {
	content: "\E97C";
}
.icon-Greece:before {
	content: "\E97D";
}
.icon-Green-Energy:before {
	content: "\E97E";
}
.icon-Green-House:before {
	content: "\E97F";
}
.icon-Guitar:before {
	content: "\E980";
}
.icon-Gun-2:before {
	content: "\E981";
}
.icon-Gun-3:before {
	content: "\E982";
}
.icon-Gun:before {
	content: "\E983";
}
.icon-Gymnastics:before {
	content: "\E984";
}
.icon-Hair-2:before {
	content: "\E985";
}
.icon-Hair-3:before {
	content: "\E986";
}
.icon-Hair-4:before {
	content: "\E987";
}
.icon-Hair:before {
	content: "\E988";
}
.icon-Half-Moon:before {
	content: "\E989";
}
.icon-Halloween-HalfMoon:before {
	content: "\E98A";
}
.icon-Halloween-Moon:before {
	content: "\E98B";
}
.icon-Hamburger:before {
	content: "\E98C";
}
.icon-Hammer:before {
	content: "\E98D";
}
.icon-Hand-Touch:before {
	content: "\E98E";
}
.icon-Hand-Touch2:before {
	content: "\E98F";
}
.icon-Hand-TouchSmartphone:before {
	content: "\E990";
}
.icon-Hand:before {
	content: "\E991";
}
.icon-Hands:before {
	content: "\E992";
}
.icon-Handshake:before {
	content: "\E993";
}
.icon-Hanger:before {
	content: "\E994";
}
.icon-Happy:before {
	content: "\E995";
}
.icon-Hat-2:before {
	content: "\E996";
}
.icon-Hat:before {
	content: "\E997";
}
.icon-Haunted-House:before {
	content: "\E998";
}
.icon-HD-Video:before {
	content: "\E999";
}
.icon-HD:before {
	content: "\E99A";
}
.icon-HDD:before {
	content: "\E99B";
}
.icon-Headphone:before {
	content: "\E99C";
}
.icon-Headphones:before {
	content: "\E99D";
}
.icon-Headset:before {
	content: "\E99E";
}
.icon-Heart-2:before {
	content: "\E99F";
}
.icon-Heart:before {
	content: "\E9A0";
}
.icon-Heels-2:before {
	content: "\E9A1";
}
.icon-Heels:before {
	content: "\E9A2";
}
.icon-Height-Window:before {
	content: "\E9A3";
}
.icon-Helicopter-2:before {
	content: "\E9A4";
}
.icon-Helicopter:before {
	content: "\E9A5";
}
.icon-Helix-2:before {
	content: "\E9A6";
}
.icon-Hello:before {
	content: "\E9A7";
}
.icon-Helmet-2:before {
	content: "\E9A8";
}
.icon-Helmet-3:before {
	content: "\E9A9";
}
.icon-Helmet:before {
	content: "\E9AA";
}
.icon-Hipo:before {
	content: "\E9AB";
}
.icon-Hipster-Glasses:before {
	content: "\E9AC";
}
.icon-Hipster-Glasses2:before {
	content: "\E9AD";
}
.icon-Hipster-Glasses3:before {
	content: "\E9AE";
}
.icon-Hipster-Headphones:before {
	content: "\E9AF";
}
.icon-Hipster-Men:before {
	content: "\E9B0";
}
.icon-Hipster-Men2:before {
	content: "\E9B1";
}
.icon-Hipster-Men3:before {
	content: "\E9B2";
}
.icon-Hipster-Sunglasses:before {
	content: "\E9B3";
}
.icon-Hipster-Sunglasses2:before {
	content: "\E9B4";
}
.icon-Hipster-Sunglasses3:before {
	content: "\E9B5";
}
.icon-Hokey:before {
	content: "\E9B6";
}
.icon-Holly:before {
	content: "\E9B7";
}
.icon-Home-2:before {
	content: "\E9B8";
}
.icon-Home-3:before {
	content: "\E9B9";
}
.icon-Home-4:before {
	content: "\E9BA";
}
.icon-Home-5:before {
	content: "\E9BB";
}
.icon-Home-Window:before {
	content: "\E9BC";
}
.icon-Home:before {
	content: "\E9BD";
}
.icon-Homosexual:before {
	content: "\E9BE";
}
.icon-Honey:before {
	content: "\E9BF";
}
.icon-Hong-Kong:before {
	content: "\E9C0";
}
.icon-Hoodie:before {
	content: "\E9C1";
}
.icon-Horror:before {
	content: "\E9C2";
}
.icon-Horse:before {
	content: "\E9C3";
}
.icon-Hospital-2:before {
	content: "\E9C4";
}
.icon-Hospital:before {
	content: "\E9C5";
}
.icon-Host:before {
	content: "\E9C6";
}
.icon-Hot-Dog:before {
	content: "\E9C7";
}
.icon-Hotel:before {
	content: "\E9C8";
}
.icon-Hour:before {
	content: "\E9C9";
}
.icon-Hub:before {
	content: "\E9CA";
}
.icon-Humor:before {
	content: "\E9CB";
}
.icon-Hurt:before {
	content: "\E9CC";
}
.icon-Ice-Cream:before {
	content: "\E9CD";
}
.icon-ICQ:before {
	content: "\E9CE";
}
.icon-ID-2:before {
	content: "\E9CF";
}
.icon-ID-3:before {
	content: "\E9D0";
}
.icon-ID-Card:before {
	content: "\E9D1";
}
.icon-Idea-2:before {
	content: "\E9D2";
}
.icon-Idea-3:before {
	content: "\E9D3";
}
.icon-Idea-4:before {
	content: "\E9D4";
}
.icon-Idea-5:before {
	content: "\E9D5";
}
.icon-Idea:before {
	content: "\E9D6";
}
.icon-Identification-Badge:before {
	content: "\E9D7";
}
.icon-ImDB:before {
	content: "\E9D8";
}
.icon-Inbox-Empty:before {
	content: "\E9D9";
}
.icon-Inbox-Forward:before {
	content: "\E9DA";
}
.icon-Inbox-Full:before {
	content: "\E9DB";
}
.icon-Inbox-Into:before {
	content: "\E9DC";
}
.icon-Inbox-Out:before {
	content: "\E9DD";
}
.icon-Inbox-Reply:before {
	content: "\E9DE";
}
.icon-Inbox:before {
	content: "\E9DF";
}
.icon-Increase-Inedit:before {
	content: "\E9E0";
}
.icon-Indent-FirstLine:before {
	content: "\E9E1";
}
.icon-Indent-LeftMargin:before {
	content: "\E9E2";
}
.icon-Indent-RightMargin:before {
	content: "\E9E3";
}
.icon-India:before {
	content: "\E9E4";
}
.icon-Info-Window:before {
	content: "\E9E5";
}
.icon-Information:before {
	content: "\E9E6";
}
.icon-Inifity:before {
	content: "\E9E7";
}
.icon-Instagram:before {
	content: "\E9E8";
}
.icon-Internet-2:before {
	content: "\E9E9";
}
.icon-Internet-Explorer:before {
	content: "\E9EA";
}
.icon-Internet-Smiley:before {
	content: "\E9EB";
}
.icon-Internet:before {
	content: "\E9EC";
}
.icon-iOS-Apple:before {
	content: "\E9ED";
}
.icon-Israel:before {
	content: "\E9EE";
}
.icon-Italic-Text:before {
	content: "\E9EF";
}
.icon-Jacket-2:before {
	content: "\E9F0";
}
.icon-Jacket:before {
	content: "\E9F1";
}
.icon-Jamaica:before {
	content: "\E9F2";
}
.icon-Japan:before {
	content: "\E9F3";
}
.icon-Japanese-Gate:before {
	content: "\E9F4";
}
.icon-Jeans:before {
	content: "\E9F5";
}
.icon-Jeep-2:before {
	content: "\E9F6";
}
.icon-Jeep:before {
	content: "\E9F7";
}
.icon-Jet:before {
	content: "\E9F8";
}
.icon-Joystick:before {
	content: "\E9F9";
}
.icon-Juice:before {
	content: "\E9FA";
}
.icon-Jump-Rope:before {
	content: "\E9FB";
}
.icon-Kangoroo:before {
	content: "\E9FC";
}
.icon-Kenya:before {
	content: "\E9FD";
}
.icon-Key-2:before {
	content: "\E9FE";
}
.icon-Key-3:before {
	content: "\E9FF";
}
.icon-Key-Lock:before {
	content: "\EA00";
}
.icon-Key:before {
	content: "\EA01";
}
.icon-Keyboard:before {
	content: "\EA02";
}
.icon-Keyboard3:before {
	content: "\EA03";
}
.icon-Keypad:before {
	content: "\EA04";
}
.icon-King-2:before {
	content: "\EA05";
}
.icon-King:before {
	content: "\EA06";
}
.icon-Kiss:before {
	content: "\EA07";
}
.icon-Knee:before {
	content: "\EA08";
}
.icon-Knife-2:before {
	content: "\EA09";
}
.icon-Knife:before {
	content: "\EA0A";
}
.icon-Knight:before {
	content: "\EA0B";
}
.icon-Koala:before {
	content: "\EA0C";
}
.icon-Korea:before {
	content: "\EA0D";
}
.icon-Lamp:before {
	content: "\EA0E";
}
.icon-Landscape-2:before {
	content: "\EA0F";
}
.icon-Landscape:before {
	content: "\EA10";
}
.icon-Lantern:before {
	content: "\EA11";
}
.icon-Laptop-2:before {
	content: "\EA12";
}
.icon-Laptop-3:before {
	content: "\EA13";
}
.icon-Laptop-Phone:before {
	content: "\EA14";
}
.icon-Laptop-Secure:before {
	content: "\EA15";
}
.icon-Laptop-Tablet:before {
	content: "\EA16";
}
.icon-Laptop:before {
	content: "\EA17";
}
.icon-Laser:before {
	content: "\EA18";
}
.icon-Last-FM:before {
	content: "\EA19";
}
.icon-Last:before {
	content: "\EA1A";
}
.icon-Laughing:before {
	content: "\EA1B";
}
.icon-Layer-1635:before {
	content: "\EA1C";
}
.icon-Layer-1646:before {
	content: "\EA1D";
}
.icon-Layer-Backward:before {
	content: "\EA1E";
}
.icon-Layer-Forward:before {
	content: "\EA1F";
}
.icon-Leafs-2:before {
	content: "\EA20";
}
.icon-Leafs:before {
	content: "\EA21";
}
.icon-Leaning-Tower:before {
	content: "\EA22";
}
.icon-Left--Right:before {
	content: "\EA23";
}
.icon-Left--Right3:before {
	content: "\EA24";
}
.icon-Left-2:before {
	content: "\EA25";
}
.icon-Left-3:before {
	content: "\EA26";
}
.icon-Left-4:before {
	content: "\EA27";
}
.icon-Left-ToRight:before {
	content: "\EA28";
}
.icon-Left:before {
	content: "\EA29";
}
.icon-Leg-2:before {
	content: "\EA2A";
}
.icon-Leg:before {
	content: "\EA2B";
}
.icon-Lego:before {
	content: "\EA2C";
}
.icon-Lemon:before {
	content: "\EA2D";
}
.icon-Len-2:before {
	content: "\EA2E";
}
.icon-Len-3:before {
	content: "\EA2F";
}
.icon-Len:before {
	content: "\EA30";
}
.icon-Leo-2:before {
	content: "\EA31";
}
.icon-Leo:before {
	content: "\EA32";
}
.icon-Leopard:before {
	content: "\EA33";
}
.icon-Lesbian:before {
	content: "\EA34";
}
.icon-Lesbians:before {
	content: "\EA35";
}
.icon-Letter-Close:before {
	content: "\EA36";
}
.icon-Letter-Open:before {
	content: "\EA37";
}
.icon-Letter-Sent:before {
	content: "\EA38";
}
.icon-Libra-2:before {
	content: "\EA39";
}
.icon-Libra:before {
	content: "\EA3A";
}
.icon-Library-2:before {
	content: "\EA3B";
}
.icon-Library:before {
	content: "\EA3C";
}
.icon-Life-Jacket:before {
	content: "\EA3D";
}
.icon-Life-Safer:before {
	content: "\EA3E";
}
.icon-Light-Bulb:before {
	content: "\EA3F";
}
.icon-Light-Bulb2:before {
	content: "\EA40";
}
.icon-Light-BulbLeaf:before {
	content: "\EA41";
}
.icon-Lighthouse:before {
	content: "\EA42";
}
.icon-Like-2:before {
	content: "\EA43";
}
.icon-Like:before {
	content: "\EA44";
}
.icon-Line-Chart:before {
	content: "\EA45";
}
.icon-Line-Chart2:before {
	content: "\EA46";
}
.icon-Line-Chart3:before {
	content: "\EA47";
}
.icon-Line-Chart4:before {
	content: "\EA48";
}
.icon-Line-Spacing:before {
	content: "\EA49";
}
.icon-Line-SpacingText:before {
	content: "\EA4A";
}
.icon-Link-2:before {
	content: "\EA4B";
}
.icon-Link:before {
	content: "\EA4C";
}
.icon-Linkedin-2:before {
	content: "\EA4D";
}
.icon-Linkedin:before {
	content: "\EA4E";
}
.icon-Linux:before {
	content: "\EA4F";
}
.icon-Lion:before {
	content: "\EA50";
}
.icon-Livejournal:before {
	content: "\EA51";
}
.icon-Loading-2:before {
	content: "\EA52";
}
.icon-Loading-3:before {
	content: "\EA53";
}
.icon-Loading-Window:before {
	content: "\EA54";
}
.icon-Loading:before {
	content: "\EA55";
}
.icon-Location-2:before {
	content: "\EA56";
}
.icon-Location:before {
	content: "\EA57";
}
.icon-Lock-2:before {
	content: "\EA58";
}
.icon-Lock-3:before {
	content: "\EA59";
}
.icon-Lock-User:before {
	content: "\EA5A";
}
.icon-Lock-Window:before {
	content: "\EA5B";
}
.icon-Lock:before {
	content: "\EA5C";
}
.icon-Lollipop-2:before {
	content: "\EA5D";
}
.icon-Lollipop-3:before {
	content: "\EA5E";
}
.icon-Lollipop:before {
	content: "\EA5F";
}
.icon-Loop:before {
	content: "\EA60";
}
.icon-Loud:before {
	content: "\EA61";
}
.icon-Loudspeaker:before {
	content: "\EA62";
}
.icon-Love-2:before {
	content: "\EA63";
}
.icon-Love-User:before {
	content: "\EA64";
}
.icon-Love-Window:before {
	content: "\EA65";
}
.icon-Love:before {
	content: "\EA66";
}
.icon-Lowercase-Text:before {
	content: "\EA67";
}
.icon-Luggafe-Front:before {
	content: "\EA68";
}
.icon-Luggage-2:before {
	content: "\EA69";
}
.icon-Macro:before {
	content: "\EA6A";
}
.icon-Magic-Wand:before {
	content: "\EA6B";
}
.icon-Magnet:before {
	content: "\EA6C";
}
.icon-Magnifi-Glass-:before {
	content: "\EA6D";
}
.icon-Magnifi-Glass:before {
	content: "\EA6E";
}
.icon-Magnifi-Glass2:before {
	content: "\EA6F";
}
.icon-Mail-2:before {
	content: "\EA70";
}
.icon-Mail-3:before {
	content: "\EA71";
}
.icon-Mail-Add:before {
	content: "\EA72";
}
.icon-Mail-Attachement:before {
	content: "\EA73";
}
.icon-Mail-Block:before {
	content: "\EA74";
}
.icon-Mail-Delete:before {
	content: "\EA75";
}
.icon-Mail-Favorite:before {
	content: "\EA76";
}
.icon-Mail-Forward:before {
	content: "\EA77";
}
.icon-Mail-Gallery:before {
	content: "\EA78";
}
.icon-Mail-Inbox:before {
	content: "\EA79";
}
.icon-Mail-Link:before {
	content: "\EA7A";
}
.icon-Mail-Lock:before {
	content: "\EA7B";
}
.icon-Mail-Love:before {
	content: "\EA7C";
}
.icon-Mail-Money:before {
	content: "\EA7D";
}
.icon-Mail-Open:before {
	content: "\EA7E";
}
.icon-Mail-Outbox:before {
	content: "\EA7F";
}
.icon-Mail-Password:before {
	content: "\EA80";
}
.icon-Mail-Photo:before {
	content: "\EA81";
}
.icon-Mail-Read:before {
	content: "\EA82";
}
.icon-Mail-Removex:before {
	content: "\EA83";
}
.icon-Mail-Reply:before {
	content: "\EA84";
}
.icon-Mail-ReplyAll:before {
	content: "\EA85";
}
.icon-Mail-Search:before {
	content: "\EA86";
}
.icon-Mail-Send:before {
	content: "\EA87";
}
.icon-Mail-Settings:before {
	content: "\EA88";
}
.icon-Mail-Unread:before {
	content: "\EA89";
}
.icon-Mail-Video:before {
	content: "\EA8A";
}
.icon-Mail-withAtSign:before {
	content: "\EA8B";
}
.icon-Mail-WithCursors:before {
	content: "\EA8C";
}
.icon-Mail:before {
	content: "\EA8D";
}
.icon-Mailbox-Empty:before {
	content: "\EA8E";
}
.icon-Mailbox-Full:before {
	content: "\EA8F";
}
.icon-Male-2:before {
	content: "\EA90";
}
.icon-Male-Sign:before {
	content: "\EA91";
}
.icon-Male:before {
	content: "\EA92";
}
.icon-MaleFemale:before {
	content: "\EA93";
}
.icon-Man-Sign:before {
	content: "\EA94";
}
.icon-Management:before {
	content: "\EA95";
}
.icon-Mans-Underwear:before {
	content: "\EA96";
}
.icon-Mans-Underwear2:before {
	content: "\EA97";
}
.icon-Map-Marker:before {
	content: "\EA98";
}
.icon-Map-Marker2:before {
	content: "\EA99";
}
.icon-Map-Marker3:before {
	content: "\EA9A";
}
.icon-Map:before {
	content: "\EA9B";
}
.icon-Map2:before {
	content: "\EA9C";
}
.icon-Marker-2:before {
	content: "\EA9D";
}
.icon-Marker-3:before {
	content: "\EA9E";
}
.icon-Marker:before {
	content: "\EA9F";
}
.icon-Martini-Glass:before {
	content: "\EAA0";
}
.icon-Mask:before {
	content: "\EAA1";
}
.icon-Master-Card:before {
	content: "\EAA2";
}
.icon-Maximize-Window:before {
	content: "\EAA3";
}
.icon-Maximize:before {
	content: "\EAA4";
}
.icon-Medal-2:before {
	content: "\EAA5";
}
.icon-Medal-3:before {
	content: "\EAA6";
}
.icon-Medal:before {
	content: "\EAA7";
}
.icon-Medical-Sign:before {
	content: "\EAA8";
}
.icon-Medicine-2:before {
	content: "\EAA9";
}
.icon-Medicine-3:before {
	content: "\EAAA";
}
.icon-Medicine:before {
	content: "\EAAB";
}
.icon-Megaphone:before {
	content: "\EAAC";
}
.icon-Memory-Card:before {
	content: "\EAAD";
}
.icon-Memory-Card2:before {
	content: "\EAAE";
}
.icon-Memory-Card3:before {
	content: "\EAAF";
}
.icon-Men:before {
	content: "\EAB0";
}
.icon-Menorah:before {
	content: "\EAB1";
}
.icon-Mens:before {
	content: "\EAB2";
}
.icon-Metacafe:before {
	content: "\EAB3";
}
.icon-Mexico:before {
	content: "\EAB4";
}
.icon-Mic:before {
	content: "\EAB5";
}
.icon-Microphone-2:before {
	content: "\EAB6";
}
.icon-Microphone-3:before {
	content: "\EAB7";
}
.icon-Microphone-4:before {
	content: "\EAB8";
}
.icon-Microphone-5:before {
	content: "\EAB9";
}
.icon-Microphone-6:before {
	content: "\EABA";
}
.icon-Microphone-7:before {
	content: "\EABB";
}
.icon-Microphone:before {
	content: "\EABC";
}
.icon-Microscope:before {
	content: "\EABD";
}
.icon-Milk-Bottle:before {
	content: "\EABE";
}
.icon-Mine:before {
	content: "\EABF";
}
.icon-Minimize-Maximize-Close-Window:before {
	content: "\EAC0";
}
.icon-Minimize-Window:before {
	content: "\EAC1";
}
.icon-Minimize:before {
	content: "\EAC2";
}
.icon-Mirror:before {
	content: "\EAC3";
}
.icon-Mixer:before {
	content: "\EAC4";
}
.icon-Mixx:before {
	content: "\EAC5";
}
.icon-Money-2:before {
	content: "\EAC6";
}
.icon-Money-Bag:before {
	content: "\EAC7";
}
.icon-Money-Smiley:before {
	content: "\EAC8";
}
.icon-Money:before {
	content: "\EAC9";
}
.icon-Monitor-2:before {
	content: "\EACA";
}
.icon-Monitor-3:before {
	content: "\EACB";
}
.icon-Monitor-4:before {
	content: "\EACC";
}
.icon-Monitor-5:before {
	content: "\EACD";
}
.icon-Monitor-Analytics:before {
	content: "\EACE";
}
.icon-Monitor-Laptop:before {
	content: "\EACF";
}
.icon-Monitor-phone:before {
	content: "\EAD0";
}
.icon-Monitor-Tablet:before {
	content: "\EAD1";
}
.icon-Monitor-Vertical:before {
	content: "\EAD2";
}
.icon-Monitor:before {
	content: "\EAD3";
}
.icon-Monitoring:before {
	content: "\EAD4";
}
.icon-Monkey:before {
	content: "\EAD5";
}
.icon-Monster:before {
	content: "\EAD6";
}
.icon-Morocco:before {
	content: "\EAD7";
}
.icon-Motorcycle:before {
	content: "\EAD8";
}
.icon-Mouse-2:before {
	content: "\EAD9";
}
.icon-Mouse-3:before {
	content: "\EADA";
}
.icon-Mouse-4:before {
	content: "\EADB";
}
.icon-Mouse-Pointer:before {
	content: "\EADC";
}
.icon-Mouse:before {
	content: "\EADD";
}
.icon-Moustache-Smiley:before {
	content: "\EADE";
}
.icon-Movie-Ticket:before {
	content: "\EADF";
}
.icon-Movie:before {
	content: "\EAE0";
}
.icon-Mp3-File:before {
	content: "\EAE1";
}
.icon-Museum:before {
	content: "\EAE2";
}
.icon-Mushroom:before {
	content: "\EAE3";
}
.icon-Music-Note:before {
	content: "\EAE4";
}
.icon-Music-Note2:before {
	content: "\EAE5";
}
.icon-Music-Note3:before {
	content: "\EAE6";
}
.icon-Music-Note4:before {
	content: "\EAE7";
}
.icon-Music-Player:before {
	content: "\EAE8";
}
.icon-Mustache-2:before {
	content: "\EAE9";
}
.icon-Mustache-3:before {
	content: "\EAEA";
}
.icon-Mustache-4:before {
	content: "\EAEB";
}
.icon-Mustache-5:before {
	content: "\EAEC";
}
.icon-Mustache-6:before {
	content: "\EAED";
}
.icon-Mustache-7:before {
	content: "\EAEE";
}
.icon-Mustache-8:before {
	content: "\EAEF";
}
.icon-Mustache:before {
	content: "\EAF0";
}
.icon-Mute:before {
	content: "\EAF1";
}
.icon-Myspace:before {
	content: "\EAF2";
}
.icon-Navigat-Start:before {
	content: "\EAF3";
}
.icon-Navigate-End:before {
	content: "\EAF4";
}
.icon-Navigation-LeftWindow:before {
	content: "\EAF5";
}
.icon-Navigation-RightWindow:before {
	content: "\EAF6";
}
.icon-Nepal:before {
	content: "\EAF7";
}
.icon-Netscape:before {
	content: "\EAF8";
}
.icon-Network-Window:before {
	content: "\EAF9";
}
.icon-Network:before {
	content: "\EAFA";
}
.icon-Neutron:before {
	content: "\EAFB";
}
.icon-New-Mail:before {
	content: "\EAFC";
}
.icon-New-Tab:before {
	content: "\EAFD";
}
.icon-Newspaper-2:before {
	content: "\EAFE";
}
.icon-Newspaper:before {
	content: "\EAFF";
}
.icon-Newsvine:before {
	content: "\EB00";
}
.icon-Next2:before {
	content: "\EB01";
}
.icon-Next-3:before {
	content: "\EB02";
}
.icon-Next-Music:before {
	content: "\EB03";
}
.icon-Next:before {
	content: "\EB04";
}
.icon-No-Battery:before {
	content: "\EB05";
}
.icon-No-Drop:before {
	content: "\EB06";
}
.icon-No-Flash:before {
	content: "\EB07";
}
.icon-No-Smoking:before {
	content: "\EB08";
}
.icon-Noose:before {
	content: "\EB09";
}
.icon-Normal-Text:before {
	content: "\EB0A";
}
.icon-Note:before {
	content: "\EB0B";
}
.icon-Notepad-2:before {
	content: "\EB0C";
}
.icon-Notepad:before {
	content: "\EB0D";
}
.icon-Nuclear:before {
	content: "\EB0E";
}
.icon-Numbering-List:before {
	content: "\EB0F";
}
.icon-Nurse:before {
	content: "\EB10";
}
.icon-Office-Lamp:before {
	content: "\EB11";
}
.icon-Office:before {
	content: "\EB12";
}
.icon-Oil:before {
	content: "\EB13";
}
.icon-Old-Camera:before {
	content: "\EB14";
}
.icon-Old-Cassette:before {
	content: "\EB15";
}
.icon-Old-Clock:before {
	content: "\EB16";
}
.icon-Old-Radio:before {
	content: "\EB17";
}
.icon-Old-Sticky:before {
	content: "\EB18";
}
.icon-Old-Sticky2:before {
	content: "\EB19";
}
.icon-Old-Telephone:before {
	content: "\EB1A";
}
.icon-Old-TV:before {
	content: "\EB1B";
}
.icon-On-Air:before {
	content: "\EB1C";
}
.icon-On-Off-2:before {
	content: "\EB1D";
}
.icon-On-Off-3:before {
	content: "\EB1E";
}
.icon-On-off:before {
	content: "\EB1F";
}
.icon-One-Finger:before {
	content: "\EB20";
}
.icon-One-FingerTouch:before {
	content: "\EB21";
}
.icon-One-Window:before {
	content: "\EB22";
}
.icon-Open-Banana:before {
	content: "\EB23";
}
.icon-Open-Book:before {
	content: "\EB24";
}
.icon-Opera-House:before {
	content: "\EB25";
}
.icon-Opera:before {
	content: "\EB26";
}
.icon-Optimization:before {
	content: "\EB27";
}
.icon-Orientation-2:before {
	content: "\EB28";
}
.icon-Orientation-3:before {
	content: "\EB29";
}
.icon-Orientation:before {
	content: "\EB2A";
}
.icon-Orkut:before {
	content: "\EB2B";
}
.icon-Ornament:before {
	content: "\EB2C";
}
.icon-Over-Time:before {
	content: "\EB2D";
}
.icon-Over-Time2:before {
	content: "\EB2E";
}
.icon-Owl:before {
	content: "\EB2F";
}
.icon-Pac-Man:before {
	content: "\EB30";
}
.icon-Paint-Brush:before {
	content: "\EB31";
}
.icon-Paint-Bucket:before {
	content: "\EB32";
}
.icon-Paintbrush:before {
	content: "\EB33";
}
.icon-Palette:before {
	content: "\EB34";
}
.icon-Palm-Tree:before {
	content: "\EB35";
}
.icon-Panda:before {
	content: "\EB36";
}
.icon-Panorama:before {
	content: "\EB37";
}
.icon-Pantheon:before {
	content: "\EB38";
}
.icon-Pantone:before {
	content: "\EB39";
}
.icon-Pants:before {
	content: "\EB3A";
}
.icon-Paper-Plane:before {
	content: "\EB3B";
}
.icon-Paper:before {
	content: "\EB3C";
}
.icon-Parasailing:before {
	content: "\EB3D";
}
.icon-Parrot:before {
	content: "\EB3E";
}
.icon-Password-2shopping:before {
	content: "\EB3F";
}
.icon-Password-Field:before {
	content: "\EB40";
}
.icon-Password-shopping:before {
	content: "\EB41";
}
.icon-Password:before {
	content: "\EB42";
}
.icon-pause-2:before {
	content: "\EB43";
}
.icon-Pause:before {
	content: "\EB44";
}
.icon-Paw:before {
	content: "\EB45";
}
.icon-Pawn:before {
	content: "\EB46";
}
.icon-Paypal:before {
	content: "\EB47";
}
.icon-Pen-2:before {
	content: "\EB48";
}
.icon-Pen-3:before {
	content: "\EB49";
}
.icon-Pen-4:before {
	content: "\EB4A";
}
.icon-Pen-5:before {
	content: "\EB4B";
}
.icon-Pen-6:before {
	content: "\EB4C";
}
.icon-Pen:before {
	content: "\EB4D";
}
.icon-Pencil-Ruler:before {
	content: "\EB4E";
}
.icon-Pencil:before {
	content: "\EB4F";
}
.icon-Penguin:before {
	content: "\EB50";
}
.icon-Pentagon:before {
	content: "\EB51";
}
.icon-People-onCloud:before {
	content: "\EB52";
}
.icon-Pepper-withFire:before {
	content: "\EB53";
}
.icon-Pepper:before {
	content: "\EB54";
}
.icon-Petrol:before {
	content: "\EB55";
}
.icon-Petronas-Tower:before {
	content: "\EB56";
}
.icon-Philipines:before {
	content: "\EB57";
}
.icon-Phone-2:before {
	content: "\EB58";
}
.icon-Phone-3:before {
	content: "\EB59";
}
.icon-Phone-3G:before {
	content: "\EB5A";
}
.icon-Phone-4G:before {
	content: "\EB5B";
}
.icon-Phone-Simcard:before {
	content: "\EB5C";
}
.icon-Phone-SMS:before {
	content: "\EB5D";
}
.icon-Phone-Wifi:before {
	content: "\EB5E";
}
.icon-Phone:before {
	content: "\EB5F";
}
.icon-Photo-2:before {
	content: "\EB60";
}
.icon-Photo-3:before {
	content: "\EB61";
}
.icon-Photo-Album:before {
	content: "\EB62";
}
.icon-Photo-Album2:before {
	content: "\EB63";
}
.icon-Photo-Album3:before {
	content: "\EB64";
}
.icon-Photo:before {
	content: "\EB65";
}
.icon-Photos:before {
	content: "\EB66";
}
.icon-Physics:before {
	content: "\EB67";
}
.icon-Pi:before {
	content: "\EB68";
}
.icon-Piano:before {
	content: "\EB69";
}
.icon-Picasa:before {
	content: "\EB6A";
}
.icon-Pie-Chart:before {
	content: "\EB6B";
}
.icon-Pie-Chart2:before {
	content: "\EB6C";
}
.icon-Pie-Chart3:before {
	content: "\EB6D";
}
.icon-Pilates-2:before {
	content: "\EB6E";
}
.icon-Pilates-3:before {
	content: "\EB6F";
}
.icon-Pilates:before {
	content: "\EB70";
}
.icon-Pilot:before {
	content: "\EB71";
}
.icon-Pinch:before {
	content: "\EB72";
}
.icon-Ping-Pong:before {
	content: "\EB73";
}
.icon-Pinterest:before {
	content: "\EB74";
}
.icon-Pipe:before {
	content: "\EB75";
}
.icon-Pipette:before {
	content: "\EB76";
}
.icon-Piramids:before {
	content: "\EB77";
}
.icon-Pisces-2:before {
	content: "\EB78";
}
.icon-Pisces:before {
	content: "\EB79";
}
.icon-Pizza-Slice:before {
	content: "\EB7A";
}
.icon-Pizza:before {
	content: "\EB7B";
}
.icon-Plane-2:before {
	content: "\EB7C";
}
.icon-Plane:before {
	content: "\EB7D";
}
.icon-Plant:before {
	content: "\EB7E";
}
.icon-Plasmid:before {
	content: "\EB7F";
}
.icon-Plaster:before {
	content: "\EB80";
}
.icon-Plastic-CupPhone:before {
	content: "\EB81";
}
.icon-Plastic-CupPhone2:before {
	content: "\EB82";
}
.icon-Plate:before {
	content: "\EB83";
}
.icon-Plates:before {
	content: "\EB84";
}
.icon-Plaxo:before {
	content: "\EB85";
}
.icon-Play-Music:before {
	content: "\EB86";
}
.icon-Plug-In:before {
	content: "\EB87";
}
.icon-Plug-In2:before {
	content: "\EB88";
}
.icon-Plurk:before {
	content: "\EB89";
}
.icon-Pointer:before {
	content: "\EB8A";
}
.icon-Poland:before {
	content: "\EB8B";
}
.icon-Police-Man:before {
	content: "\EB8C";
}
.icon-Police-Station:before {
	content: "\EB8D";
}
.icon-Police-Woman:before {
	content: "\EB8E";
}
.icon-Police:before {
	content: "\EB8F";
}
.icon-Polo-Shirt:before {
	content: "\EB90";
}
.icon-Portrait:before {
	content: "\EB91";
}
.icon-Portugal:before {
	content: "\EB92";
}
.icon-Post-Mail:before {
	content: "\EB93";
}
.icon-Post-Mail2:before {
	content: "\EB94";
}
.icon-Post-Office:before {
	content: "\EB95";
}
.icon-Post-Sign:before {
	content: "\EB96";
}
.icon-Post-Sign2ways:before {
	content: "\EB97";
}
.icon-Posterous:before {
	content: "\EB98";
}
.icon-Pound-Sign:before {
	content: "\EB99";
}
.icon-Pound-Sign2:before {
	content: "\EB9A";
}
.icon-Pound:before {
	content: "\EB9B";
}
.icon-Power-2:before {
	content: "\EB9C";
}
.icon-Power-3:before {
	content: "\EB9D";
}
.icon-Power-Cable:before {
	content: "\EB9E";
}
.icon-Power-Station:before {
	content: "\EB9F";
}
.icon-Power:before {
	content: "\EBA0";
}
.icon-Prater:before {
	content: "\EBA1";
}
.icon-Present:before {
	content: "\EBA2";
}
.icon-Presents:before {
	content: "\EBA3";
}
.icon-Press:before {
	content: "\EBA4";
}
.icon-Preview:before {
	content: "\EBA5";
}
.icon-Previous:before {
	content: "\EBA6";
}
.icon-Pricing:before {
	content: "\EBA7";
}
.icon-Printer:before {
	content: "\EBA8";
}
.icon-Professor:before {
	content: "\EBA9";
}
.icon-Profile:before {
	content: "\EBAA";
}
.icon-Project:before {
	content: "\EBAB";
}
.icon-Projector-2:before {
	content: "\EBAC";
}
.icon-Projector:before {
	content: "\EBAD";
}
.icon-Pulse:before {
	content: "\EBAE";
}
.icon-Pumpkin:before {
	content: "\EBAF";
}
.icon-Punk:before {
	content: "\EBB0";
}
.icon-Punker:before {
	content: "\EBB1";
}
.icon-Puzzle:before {
	content: "\EBB2";
}
.icon-QIK:before {
	content: "\EBB3";
}
.icon-QR-Code:before {
	content: "\EBB4";
}
.icon-Queen-2:before {
	content: "\EBB5";
}
.icon-Queen:before {
	content: "\EBB6";
}
.icon-Quill-2:before {
	content: "\EBB7";
}
.icon-Quill-3:before {
	content: "\EBB8";
}
.icon-Quill:before {
	content: "\EBB9";
}
.icon-Quotes-2:before {
	content: "\EBBA";
}
.icon-Quotes:before {
	content: "\EBBB";
}
.icon-Radio:before {
	content: "\EBBC";
}
.icon-Radioactive:before {
	content: "\EBBD";
}
.icon-Rafting:before {
	content: "\EBBE";
}
.icon-Rain-Drop:before {
	content: "\EBBF";
}
.icon-Rainbow-2:before {
	content: "\EBC0";
}
.icon-Rainbow:before {
	content: "\EBC1";
}
.icon-Ram:before {
	content: "\EBC2";
}
.icon-Razzor-Blade:before {
	content: "\EBC3";
}
.icon-Receipt-2:before {
	content: "\EBC4";
}
.icon-Receipt-3:before {
	content: "\EBC5";
}
.icon-Receipt-4:before {
	content: "\EBC6";
}
.icon-Receipt:before {
	content: "\EBC7";
}
.icon-Record2:before {
	content: "\EBC8";
}
.icon-Record-3:before {
	content: "\EBC9";
}
.icon-Record-Music:before {
	content: "\EBCA";
}
.icon-Record:before {
	content: "\EBCB";
}
.icon-Recycling-2:before {
	content: "\EBCC";
}
.icon-Recycling:before {
	content: "\EBCD";
}
.icon-Reddit:before {
	content: "\EBCE";
}
.icon-Redhat:before {
	content: "\EBCF";
}
.icon-Redirect:before {
	content: "\EBD0";
}
.icon-Redo:before {
	content: "\EBD1";
}
.icon-Reel:before {
	content: "\EBD2";
}
.icon-Refinery:before {
	content: "\EBD3";
}
.icon-Refresh-Window:before {
	content: "\EBD4";
}
.icon-Refresh:before {
	content: "\EBD5";
}
.icon-Reload-2:before {
	content: "\EBD6";
}
.icon-Reload-3:before {
	content: "\EBD7";
}
.icon-Reload:before {
	content: "\EBD8";
}
.icon-Remote-Controll:before {
	content: "\EBD9";
}
.icon-Remote-Controll2:before {
	content: "\EBDA";
}
.icon-Remove-Bag:before {
	content: "\EBDB";
}
.icon-Remove-Basket:before {
	content: "\EBDC";
}
.icon-Remove-Cart:before {
	content: "\EBDD";
}
.icon-Remove-File:before {
	content: "\EBDE";
}
.icon-Remove-User:before {
	content: "\EBDF";
}
.icon-Remove-Window:before {
	content: "\EBE0";
}
.icon-Remove:before {
	content: "\EBE1";
}
.icon-Rename:before {
	content: "\EBE2";
}
.icon-Repair:before {
	content: "\EBE3";
}
.icon-Repeat-2:before {
	content: "\EBE4";
}
.icon-Repeat-3:before {
	content: "\EBE5";
}
.icon-Repeat-4:before {
	content: "\EBE6";
}
.icon-Repeat-5:before {
	content: "\EBE7";
}
.icon-Repeat-6:before {
	content: "\EBE8";
}
.icon-Repeat-7:before {
	content: "\EBE9";
}
.icon-Repeat:before {
	content: "\EBEA";
}
.icon-Reset:before {
	content: "\EBEB";
}
.icon-Resize:before {
	content: "\EBEC";
}
.icon-Restore-Window:before {
	content: "\EBED";
}
.icon-Retouching:before {
	content: "\EBEE";
}
.icon-Retro-Camera:before {
	content: "\EBEF";
}
.icon-Retro:before {
	content: "\EBF0";
}
.icon-Retweet:before {
	content: "\EBF1";
}
.icon-Reverbnation:before {
	content: "\EBF2";
}
.icon-Rewind:before {
	content: "\EBF3";
}
.icon-RGB:before {
	content: "\EBF4";
}
.icon-Ribbon-2:before {
	content: "\EBF5";
}
.icon-Ribbon-3:before {
	content: "\EBF6";
}
.icon-Ribbon:before {
	content: "\EBF7";
}
.icon-Right-2:before {
	content: "\EBF8";
}
.icon-Right-3:before {
	content: "\EBF9";
}
.icon-Right-4:before {
	content: "\EBFA";
}
.icon-Right-ToLeft:before {
	content: "\EBFB";
}
.icon-Right:before {
	content: "\EBFC";
}
.icon-Road-2:before {
	content: "\EBFD";
}
.icon-Road-3:before {
	content: "\EBFE";
}
.icon-Road:before {
	content: "\EBFF";
}
.icon-Robot-2:before {
	content: "\EC00";
}
.icon-Robot:before {
	content: "\EC01";
}
.icon-Rock-andRoll:before {
	content: "\EC02";
}
.icon-Rocket:before {
	content: "\EC03";
}
.icon-Roller:before {
	content: "\EC04";
}
.icon-Roof:before {
	content: "\EC05";
}
.icon-Rook:before {
	content: "\EC06";
}
.icon-Rotate-Gesture:before {
	content: "\EC07";
}
.icon-Rotate-Gesture2:before {
	content: "\EC08";
}
.icon-Rotate-Gesture3:before {
	content: "\EC09";
}
.icon-Rotation-390:before {
	content: "\EC0A";
}
.icon-Rotation:before {
	content: "\EC0B";
}
.icon-Router-2:before {
	content: "\EC0C";
}
.icon-Router:before {
	content: "\EC0D";
}
.icon-RSS:before {
	content: "\EC0E";
}
.icon-Ruler-2:before {
	content: "\EC0F";
}
.icon-Ruler:before {
	content: "\EC10";
}
.icon-Running-Shoes:before {
	content: "\EC11";
}
.icon-Running:before {
	content: "\EC12";
}
.icon-Safari:before {
	content: "\EC13";
}
.icon-Safe-Box:before {
	content: "\EC14";
}
.icon-Safe-Box2:before {
	content: "\EC15";
}
.icon-Safety-PinClose:before {
	content: "\EC16";
}
.icon-Safety-PinOpen:before {
	content: "\EC17";
}
.icon-Sagittarus-2:before {
	content: "\EC18";
}
.icon-Sagittarus:before {
	content: "\EC19";
}
.icon-Sailing-Ship:before {
	content: "\EC1A";
}
.icon-Sand-watch:before {
	content: "\EC1B";
}
.icon-Sand-watch2:before {
	content: "\EC1C";
}
.icon-Santa-Claus:before {
	content: "\EC1D";
}
.icon-Santa-Claus2:before {
	content: "\EC1E";
}
.icon-Santa-onSled:before {
	content: "\EC1F";
}
.icon-Satelite-2:before {
	content: "\EC20";
}
.icon-Satelite:before {
	content: "\EC21";
}
.icon-Save-Window:before {
	content: "\EC22";
}
.icon-Save:before {
	content: "\EC23";
}
.icon-Saw:before {
	content: "\EC24";
}
.icon-Saxophone:before {
	content: "\EC25";
}
.icon-Scale:before {
	content: "\EC26";
}
.icon-Scarf:before {
	content: "\EC27";
}
.icon-Scissor:before {
	content: "\EC28";
}
.icon-Scooter-Front:before {
	content: "\EC29";
}
.icon-Scooter:before {
	content: "\EC2A";
}
.icon-Scorpio-2:before {
	content: "\EC2B";
}
.icon-Scorpio:before {
	content: "\EC2C";
}
.icon-Scotland:before {
	content: "\EC2D";
}
.icon-Screwdriver:before {
	content: "\EC2E";
}
.icon-Scroll-Fast:before {
	content: "\EC2F";
}
.icon-Scroll:before {
	content: "\EC30";
}
.icon-Scroller-2:before {
	content: "\EC31";
}
.icon-Scroller:before {
	content: "\EC32";
}
.icon-Sea-Dog:before {
	content: "\EC33";
}
.icon-Search-onCloud:before {
	content: "\EC34";
}
.icon-Search-People:before {
	content: "\EC35";
}
.icon-secound:before {
	content: "\EC36";
}
.icon-secound2:before {
	content: "\EC37";
}
.icon-Security-Block:before {
	content: "\EC38";
}
.icon-Security-Bug:before {
	content: "\EC39";
}
.icon-Security-Camera:before {
	content: "\EC3A";
}
.icon-Security-Check:before {
	content: "\EC3B";
}
.icon-Security-Settings:before {
	content: "\EC3C";
}
.icon-Security-Smiley:before {
	content: "\EC3D";
}
.icon-Securiy-Remove:before {
	content: "\EC3E";
}
.icon-Seed:before {
	content: "\EC3F";
}
.icon-Selfie:before {
	content: "\EC40";
}
.icon-Serbia:before {
	content: "\EC41";
}
.icon-Server-2:before {
	content: "\EC42";
}
.icon-Server:before {
	content: "\EC43";
}
.icon-Servers:before {
	content: "\EC44";
}
.icon-Settings-Window:before {
	content: "\EC45";
}
.icon-Sewing-Machine:before {
	content: "\EC46";
}
.icon-Sexual:before {
	content: "\EC47";
}
.icon-Share-onCloud:before {
	content: "\EC48";
}
.icon-Share-Window:before {
	content: "\EC49";
}
.icon-Share:before {
	content: "\EC4A";
}
.icon-Sharethis:before {
	content: "\EC4B";
}
.icon-Shark:before {
	content: "\EC4C";
}
.icon-Sheep:before {
	content: "\EC4D";
}
.icon-Sheriff-Badge:before {
	content: "\EC4E";
}
.icon-Shield:before {
	content: "\EC4F";
}
.icon-Ship-2:before {
	content: "\EC50";
}
.icon-Ship:before {
	content: "\EC51";
}
.icon-Shirt:before {
	content: "\EC52";
}
.icon-Shoes-2:before {
	content: "\EC53";
}
.icon-Shoes-3:before {
	content: "\EC54";
}
.icon-Shoes:before {
	content: "\EC55";
}
.icon-Shop-2:before {
	content: "\EC56";
}
.icon-Shop-3:before {
	content: "\EC57";
}
.icon-Shop-4:before {
	content: "\EC58";
}
.icon-Shop:before {
	content: "\EC59";
}
.icon-Shopping-Bag:before {
	content: "\EC5A";
}
.icon-Shopping-Basket:before {
	content: "\EC5B";
}
.icon-Shopping-Cart:before {
	content: "\EC5C";
}
.icon-Short-Pants:before {
	content: "\EC5D";
}
.icon-Shoutwire:before {
	content: "\EC5E";
}
.icon-Shovel:before {
	content: "\EC5F";
}
.icon-Shuffle-2:before {
	content: "\EC60";
}
.icon-Shuffle-3:before {
	content: "\EC61";
}
.icon-Shuffle-4:before {
	content: "\EC62";
}
.icon-Shuffle:before {
	content: "\EC63";
}
.icon-Shutter:before {
	content: "\EC64";
}
.icon-Sidebar-Window:before {
	content: "\EC65";
}
.icon-Signal:before {
	content: "\EC66";
}
.icon-Singapore:before {
	content: "\EC67";
}
.icon-Skate-Shoes:before {
	content: "\EC68";
}
.icon-Skateboard-2:before {
	content: "\EC69";
}
.icon-Skateboard:before {
	content: "\EC6A";
}
.icon-Skeleton:before {
	content: "\EC6B";
}
.icon-Ski:before {
	content: "\EC6C";
}
.icon-Skirt:before {
	content: "\EC6D";
}
.icon-Skrill:before {
	content: "\EC6E";
}
.icon-Skull:before {
	content: "\EC6F";
}
.icon-Skydiving:before {
	content: "\EC70";
}
.icon-Skype:before {
	content: "\EC71";
}
.icon-Sled-withGifts:before {
	content: "\EC72";
}
.icon-Sled:before {
	content: "\EC73";
}
.icon-Sleeping:before {
	content: "\EC74";
}
.icon-Sleet:before {
	content: "\EC75";
}
.icon-Slippers:before {
	content: "\EC76";
}
.icon-Smart:before {
	content: "\EC77";
}
.icon-Smartphone-2:before {
	content: "\EC78";
}
.icon-Smartphone-3:before {
	content: "\EC79";
}
.icon-Smartphone-4:before {
	content: "\EC7A";
}
.icon-Smartphone-Secure:before {
	content: "\EC7B";
}
.icon-Smartphone:before {
	content: "\EC7C";
}
.icon-Smile:before {
	content: "\EC7D";
}
.icon-Smoking-Area:before {
	content: "\EC7E";
}
.icon-Smoking-Pipe:before {
	content: "\EC7F";
}
.icon-Snake:before {
	content: "\EC80";
}
.icon-Snorkel:before {
	content: "\EC81";
}
.icon-Snow-2:before {
	content: "\EC82";
}
.icon-Snow-Dome:before {
	content: "\EC83";
}
.icon-Snow-Storm:before {
	content: "\EC84";
}
.icon-Snow:before {
	content: "\EC85";
}
.icon-Snowflake-2:before {
	content: "\EC86";
}
.icon-Snowflake-3:before {
	content: "\EC87";
}
.icon-Snowflake-4:before {
	content: "\EC88";
}
.icon-Snowflake:before {
	content: "\EC89";
}
.icon-Snowman:before {
	content: "\EC8A";
}
.icon-Soccer-Ball:before {
	content: "\EC8B";
}
.icon-Soccer-Shoes:before {
	content: "\EC8C";
}
.icon-Socks:before {
	content: "\EC8D";
}
.icon-Solar:before {
	content: "\EC8E";
}
.icon-Sound-Wave:before {
	content: "\EC8F";
}
.icon-Sound:before {
	content: "\EC90";
}
.icon-Soundcloud:before {
	content: "\EC91";
}
.icon-Soup:before {
	content: "\EC92";
}
.icon-South-Africa:before {
	content: "\EC93";
}
.icon-Space-Needle:before {
	content: "\EC94";
}
.icon-Spain:before {
	content: "\EC95";
}
.icon-Spam-Mail:before {
	content: "\EC96";
}
.icon-Speach-Bubble:before {
	content: "\EC97";
}
.icon-Speach-Bubble2:before {
	content: "\EC98";
}
.icon-Speach-Bubble3:before {
	content: "\EC99";
}
.icon-Speach-Bubble4:before {
	content: "\EC9A";
}
.icon-Speach-Bubble5:before {
	content: "\EC9B";
}
.icon-Speach-Bubble6:before {
	content: "\EC9C";
}
.icon-Speach-Bubble7:before {
	content: "\EC9D";
}
.icon-Speach-Bubble8:before {
	content: "\EC9E";
}
.icon-Speach-Bubble9:before {
	content: "\EC9F";
}
.icon-Speach-Bubble10:before {
	content: "\ECA0";
}
.icon-Speach-Bubble11:before {
	content: "\ECA1";
}
.icon-Speach-Bubble12:before {
	content: "\ECA2";
}
.icon-Speach-Bubble13:before {
	content: "\ECA3";
}
.icon-Speach-BubbleAsking:before {
	content: "\ECA4";
}
.icon-Speach-BubbleComic:before {
	content: "\ECA5";
}
.icon-Speach-BubbleComic2:before {
	content: "\ECA6";
}
.icon-Speach-BubbleComic3:before {
	content: "\ECA7";
}
.icon-Speach-BubbleComic4:before {
	content: "\ECA8";
}
.icon-Speach-BubbleDialog:before {
	content: "\ECA9";
}
.icon-Speach-Bubbles:before {
	content: "\ECAA";
}
.icon-Speak-2:before {
	content: "\ECAB";
}
.icon-Speak:before {
	content: "\ECAC";
}
.icon-Speaker-2:before {
	content: "\ECAD";
}
.icon-Speaker:before {
	content: "\ECAE";
}
.icon-Spell-Check:before {
	content: "\ECAF";
}
.icon-Spell-CheckABC:before {
	content: "\ECB0";
}
.icon-Spermium:before {
	content: "\ECB1";
}
.icon-Spider:before {
	content: "\ECB2";
}
.icon-Spiderweb:before {
	content: "\ECB3";
}
.icon-Split-FourSquareWindow:before {
	content: "\ECB4";
}
.icon-Split-Horizontal:before {
	content: "\ECB5";
}
.icon-Split-Horizontal2Window:before {
	content: "\ECB6";
}
.icon-Split-Vertical:before {
	content: "\ECB7";
}
.icon-Split-Vertical2:before {
	content: "\ECB8";
}
.icon-Split-Window:before {
	content: "\ECB9";
}
.icon-Spoder:before {
	content: "\ECBA";
}
.icon-Spoon:before {
	content: "\ECBB";
}
.icon-Sport-Mode:before {
	content: "\ECBC";
}
.icon-Sports-Clothings1:before {
	content: "\ECBD";
}
.icon-Sports-Clothings2:before {
	content: "\ECBE";
}
.icon-Sports-Shirt:before {
	content: "\ECBF";
}
.icon-Spot:before {
	content: "\ECC0";
}
.icon-Spray:before {
	content: "\ECC1";
}
.icon-Spread:before {
	content: "\ECC2";
}
.icon-Spring:before {
	content: "\ECC3";
}
.icon-Spurl:before {
	content: "\ECC4";
}
.icon-Spy:before {
	content: "\ECC5";
}
.icon-Squirrel:before {
	content: "\ECC6";
}
.icon-SSL:before {
	content: "\ECC7";
}
.icon-St-BasilsCathedral:before {
	content: "\ECC8";
}
.icon-St-PaulsCathedral:before {
	content: "\ECC9";
}
.icon-Stamp-2:before {
	content: "\ECCA";
}
.icon-Stamp:before {
	content: "\ECCB";
}
.icon-Stapler:before {
	content: "\ECCC";
}
.icon-Star-Track:before {
	content: "\ECCD";
}
.icon-Star:before {
	content: "\ECCE";
}
.icon-Starfish:before {
	content: "\ECCF";
}
.icon-Start2:before {
	content: "\ECD0";
}
.icon-Start-3:before {
	content: "\ECD1";
}
.icon-Start-ways:before {
	content: "\ECD2";
}
.icon-Start:before {
	content: "\ECD3";
}
.icon-Statistic:before {
	content: "\ECD4";
}
.icon-Stethoscope:before {
	content: "\ECD5";
}
.icon-stop--2:before {
	content: "\ECD6";
}
.icon-Stop-Music:before {
	content: "\ECD7";
}
.icon-Stop:before {
	content: "\ECD8";
}
.icon-Stopwatch-2:before {
	content: "\ECD9";
}
.icon-Stopwatch:before {
	content: "\ECDA";
}
.icon-Storm:before {
	content: "\ECDB";
}
.icon-Street-View:before {
	content: "\ECDC";
}
.icon-Street-View2:before {
	content: "\ECDD";
}
.icon-Strikethrough-Text:before {
	content: "\ECDE";
}
.icon-Stroller:before {
	content: "\ECDF";
}
.icon-Structure:before {
	content: "\ECE0";
}
.icon-Student-Female:before {
	content: "\ECE1";
}
.icon-Student-Hat:before {
	content: "\ECE2";
}
.icon-Student-Hat2:before {
	content: "\ECE3";
}
.icon-Student-Male:before {
	content: "\ECE4";
}
.icon-Student-MaleFemale:before {
	content: "\ECE5";
}
.icon-Students:before {
	content: "\ECE6";
}
.icon-Studio-Flash:before {
	content: "\ECE7";
}
.icon-Studio-Lightbox:before {
	content: "\ECE8";
}
.icon-Stumbleupon:before {
	content: "\ECE9";
}
.icon-Suit:before {
	content: "\ECEA";
}
.icon-Suitcase:before {
	content: "\ECEB";
}
.icon-Sum-2:before {
	content: "\ECEC";
}
.icon-Sum:before {
	content: "\ECED";
}
.icon-Summer:before {
	content: "\ECEE";
}
.icon-Sun-CloudyRain:before {
	content: "\ECEF";
}
.icon-Sun:before {
	content: "\ECF0";
}
.icon-Sunglasses-2:before {
	content: "\ECF1";
}
.icon-Sunglasses-3:before {
	content: "\ECF2";
}
.icon-Sunglasses-Smiley:before {
	content: "\ECF3";
}
.icon-Sunglasses-Smiley2:before {
	content: "\ECF4";
}
.icon-Sunglasses-W:before {
	content: "\ECF5";
}
.icon-Sunglasses-W2:before {
	content: "\ECF6";
}
.icon-Sunglasses-W3:before {
	content: "\ECF7";
}
.icon-Sunglasses:before {
	content: "\ECF8";
}
.icon-Sunrise:before {
	content: "\ECF9";
}
.icon-Sunset:before {
	content: "\ECFA";
}
.icon-Superman:before {
	content: "\ECFB";
}
.icon-Support:before {
	content: "\ECFC";
}
.icon-Surprise:before {
	content: "\ECFD";
}
.icon-Sushi:before {
	content: "\ECFE";
}
.icon-Sweden:before {
	content: "\ECFF";
}
.icon-Swimming-Short:before {
	content: "\ED00";
}
.icon-Swimming:before {
	content: "\ED01";
}
.icon-Swimmwear:before {
	content: "\ED02";
}
.icon-Switch:before {
	content: "\ED03";
}
.icon-Switzerland:before {
	content: "\ED04";
}
.icon-Sync-Cloud:before {
	content: "\ED05";
}
.icon-Sync:before {
	content: "\ED06";
}
.icon-Synchronize-2:before {
	content: "\ED07";
}
.icon-Synchronize:before {
	content: "\ED08";
}
.icon-T-Shirt:before {
	content: "\ED09";
}
.icon-Tablet-2:before {
	content: "\ED0A";
}
.icon-Tablet-3:before {
	content: "\ED0B";
}
.icon-Tablet-Orientation:before {
	content: "\ED0C";
}
.icon-Tablet-Phone:before {
	content: "\ED0D";
}
.icon-Tablet-Secure:before {
	content: "\ED0E";
}
.icon-Tablet-Vertical:before {
	content: "\ED0F";
}
.icon-Tablet:before {
	content: "\ED10";
}
.icon-Tactic:before {
	content: "\ED11";
}
.icon-Tag-2:before {
	content: "\ED12";
}
.icon-Tag-3:before {
	content: "\ED13";
}
.icon-Tag-4:before {
	content: "\ED14";
}
.icon-Tag-5:before {
	content: "\ED15";
}
.icon-Tag:before {
	content: "\ED16";
}
.icon-Taj-Mahal:before {
	content: "\ED17";
}
.icon-Talk-Man:before {
	content: "\ED18";
}
.icon-Tap:before {
	content: "\ED19";
}
.icon-Target-Market:before {
	content: "\ED1A";
}
.icon-Target:before {
	content: "\ED1B";
}
.icon-Taurus-2:before {
	content: "\ED1C";
}
.icon-Taurus:before {
	content: "\ED1D";
}
.icon-Taxi-2:before {
	content: "\ED1E";
}
.icon-Taxi-Sign:before {
	content: "\ED1F";
}
.icon-Taxi:before {
	content: "\ED20";
}
.icon-Teacher:before {
	content: "\ED21";
}
.icon-Teapot:before {
	content: "\ED22";
}
.icon-Technorati:before {
	content: "\ED23";
}
.icon-Teddy-Bear:before {
	content: "\ED24";
}
.icon-Tee-Mug:before {
	content: "\ED25";
}
.icon-Telephone-2:before {
	content: "\ED26";
}
.icon-Telephone:before {
	content: "\ED27";
}
.icon-Telescope:before {
	content: "\ED28";
}
.icon-Temperature-2:before {
	content: "\ED29";
}
.icon-Temperature-3:before {
	content: "\ED2A";
}
.icon-Temperature:before {
	content: "\ED2B";
}
.icon-Temple:before {
	content: "\ED2C";
}
.icon-Tennis-Ball:before {
	content: "\ED2D";
}
.icon-Tennis:before {
	content: "\ED2E";
}
.icon-Tent:before {
	content: "\ED2F";
}
.icon-Test-Tube:before {
	content: "\ED30";
}
.icon-Test-Tube2:before {
	content: "\ED31";
}
.icon-Testimonal:before {
	content: "\ED32";
}
.icon-Text-Box:before {
	content: "\ED33";
}
.icon-Text-Effect:before {
	content: "\ED34";
}
.icon-Text-HighlightColor:before {
	content: "\ED35";
}
.icon-Text-Paragraph:before {
	content: "\ED36";
}
.icon-Thailand:before {
	content: "\ED37";
}
.icon-The-WhiteHouse:before {
	content: "\ED38";
}
.icon-This-SideUp:before {
	content: "\ED39";
}
.icon-Thread:before {
	content: "\ED3A";
}
.icon-Three-ArrowFork:before {
	content: "\ED3B";
}
.icon-Three-Fingers:before {
	content: "\ED3C";
}
.icon-Three-FingersDrag:before {
	content: "\ED3D";
}
.icon-Three-FingersDrag2:before {
	content: "\ED3E";
}
.icon-Three-FingersTouch:before {
	content: "\ED3F";
}
.icon-Thumb:before {
	content: "\ED40";
}
.icon-Thumbs-DownSmiley:before {
	content: "\ED41";
}
.icon-Thumbs-UpSmiley:before {
	content: "\ED42";
}
.icon-Thunder:before {
	content: "\ED43";
}
.icon-Thunderstorm:before {
	content: "\ED44";
}
.icon-Ticket:before {
	content: "\ED45";
}
.icon-Tie-2:before {
	content: "\ED46";
}
.icon-Tie-3:before {
	content: "\ED47";
}
.icon-Tie-4:before {
	content: "\ED48";
}
.icon-Tie:before {
	content: "\ED49";
}
.icon-Tiger:before {
	content: "\ED4A";
}
.icon-Time-Backup:before {
	content: "\ED4B";
}
.icon-Time-Bomb:before {
	content: "\ED4C";
}
.icon-Time-Clock:before {
	content: "\ED4D";
}
.icon-Time-Fire:before {
	content: "\ED4E";
}
.icon-Time-Machine:before {
	content: "\ED4F";
}
.icon-Time-Window:before {
	content: "\ED50";
}
.icon-Timer-2:before {
	content: "\ED51";
}
.icon-Timer:before {
	content: "\ED52";
}
.icon-To-Bottom:before {
	content: "\ED53";
}
.icon-To-Bottom2:before {
	content: "\ED54";
}
.icon-To-Left:before {
	content: "\ED55";
}
.icon-To-Right:before {
	content: "\ED56";
}
.icon-To-Top:before {
	content: "\ED57";
}
.icon-To-Top2:before {
	content: "\ED58";
}
.icon-Token-:before {
	content: "\ED59";
}
.icon-Tomato:before {
	content: "\ED5A";
}
.icon-Tongue:before {
	content: "\ED5B";
}
.icon-Tooth-2:before {
	content: "\ED5C";
}
.icon-Tooth:before {
	content: "\ED5D";
}
.icon-Top-ToBottom:before {
	content: "\ED5E";
}
.icon-Touch-Window:before {
	content: "\ED5F";
}
.icon-Tourch:before {
	content: "\ED60";
}
.icon-Tower-2:before {
	content: "\ED61";
}
.icon-Tower-Bridge:before {
	content: "\ED62";
}
.icon-Tower:before {
	content: "\ED63";
}
.icon-Trace:before {
	content: "\ED64";
}
.icon-Tractor:before {
	content: "\ED65";
}
.icon-traffic-Light:before {
	content: "\ED66";
}
.icon-Traffic-Light2:before {
	content: "\ED67";
}
.icon-Train-2:before {
	content: "\ED68";
}
.icon-Train:before {
	content: "\ED69";
}
.icon-Tram:before {
	content: "\ED6A";
}
.icon-Transform-2:before {
	content: "\ED6B";
}
.icon-Transform-3:before {
	content: "\ED6C";
}
.icon-Transform-4:before {
	content: "\ED6D";
}
.icon-Transform:before {
	content: "\ED6E";
}
.icon-Trash-withMen:before {
	content: "\ED6F";
}
.icon-Tree-2:before {
	content: "\ED70";
}
.icon-Tree-3:before {
	content: "\ED71";
}
.icon-Tree-4:before {
	content: "\ED72";
}
.icon-Tree-5:before {
	content: "\ED73";
}
.icon-Tree:before {
	content: "\ED74";
}
.icon-Trekking:before {
	content: "\ED75";
}
.icon-Triangle-ArrowDown:before {
	content: "\ED76";
}
.icon-Triangle-ArrowLeft:before {
	content: "\ED77";
}
.icon-Triangle-ArrowRight:before {
	content: "\ED78";
}
.icon-Triangle-ArrowUp:before {
	content: "\ED79";
}
.icon-Tripod-2:before {
	content: "\ED7A";
}
.icon-Tripod-andVideo:before {
	content: "\ED7B";
}
.icon-Tripod-withCamera:before {
	content: "\ED7C";
}
.icon-Tripod-withGopro:before {
	content: "\ED7D";
}
.icon-Trophy-2:before {
	content: "\ED7E";
}
.icon-Trophy:before {
	content: "\ED7F";
}
.icon-Truck:before {
	content: "\ED80";
}
.icon-Trumpet:before {
	content: "\ED81";
}
.icon-Tumblr:before {
	content: "\ED82";
}
.icon-Turkey:before {
	content: "\ED83";
}
.icon-Turn-Down:before {
	content: "\ED84";
}
.icon-Turn-Down2:before {
	content: "\ED85";
}
.icon-Turn-DownFromLeft:before {
	content: "\ED86";
}
.icon-Turn-DownFromRight:before {
	content: "\ED87";
}
.icon-Turn-Left:before {
	content: "\ED88";
}
.icon-Turn-Left3:before {
	content: "\ED89";
}
.icon-Turn-Right:before {
	content: "\ED8A";
}
.icon-Turn-Right3:before {
	content: "\ED8B";
}
.icon-Turn-Up:before {
	content: "\ED8C";
}
.icon-Turn-Up2:before {
	content: "\ED8D";
}
.icon-Turtle:before {
	content: "\ED8E";
}
.icon-Tuxedo:before {
	content: "\ED8F";
}
.icon-TV:before {
	content: "\ED90";
}
.icon-Twister:before {
	content: "\ED91";
}
.icon-Twitter-2:before {
	content: "\ED92";
}
.icon-Twitter:before {
	content: "\ED93";
}
.icon-Two-Fingers:before {
	content: "\ED94";
}
.icon-Two-FingersDrag:before {
	content: "\ED95";
}
.icon-Two-FingersDrag2:before {
	content: "\ED96";
}
.icon-Two-FingersScroll:before {
	content: "\ED97";
}
.icon-Two-FingersTouch:before {
	content: "\ED98";
}
.icon-Two-Windows:before {
	content: "\ED99";
}
.icon-Type-Pass:before {
	content: "\ED9A";
}
.icon-Ukraine:before {
	content: "\ED9B";
}
.icon-Umbrela:before {
	content: "\ED9C";
}
.icon-Umbrella-2:before {
	content: "\ED9D";
}
.icon-Umbrella-3:before {
	content: "\ED9E";
}
.icon-Under-LineText:before {
	content: "\ED9F";
}
.icon-Undo:before {
	content: "\EDA0";
}
.icon-United-Kingdom:before {
	content: "\EDA1";
}
.icon-United-States:before {
	content: "\EDA2";
}
.icon-University-2:before {
	content: "\EDA3";
}
.icon-University:before {
	content: "\EDA4";
}
.icon-Unlike-2:before {
	content: "\EDA5";
}
.icon-Unlike:before {
	content: "\EDA6";
}
.icon-Unlock-2:before {
	content: "\EDA7";
}
.icon-Unlock-3:before {
	content: "\EDA8";
}
.icon-Unlock:before {
	content: "\EDA9";
}
.icon-Up--Down:before {
	content: "\EDAA";
}
.icon-Up--Down3:before {
	content: "\EDAB";
}
.icon-Up-2:before {
	content: "\EDAC";
}
.icon-Up-3:before {
	content: "\EDAD";
}
.icon-Up-4:before {
	content: "\EDAE";
}
.icon-Up:before {
	content: "\EDAF";
}
.icon-Upgrade:before {
	content: "\EDB0";
}
.icon-Upload-2:before {
	content: "\EDB1";
}
.icon-Upload-toCloud:before {
	content: "\EDB2";
}
.icon-Upload-Window:before {
	content: "\EDB3";
}
.icon-Upload:before {
	content: "\EDB4";
}
.icon-Uppercase-Text:before {
	content: "\EDB5";
}
.icon-Upward:before {
	content: "\EDB6";
}
.icon-URL-Window:before {
	content: "\EDB7";
}
.icon-Usb-2:before {
	content: "\EDB8";
}
.icon-Usb-Cable:before {
	content: "\EDB9";
}
.icon-Usb:before {
	content: "\EDBA";
}
.icon-User:before {
	content: "\EDBB";
}
.icon-Ustream:before {
	content: "\EDBC";
}
.icon-Vase:before {
	content: "\EDBD";
}
.icon-Vector-2:before {
	content: "\EDBE";
}
.icon-Vector-3:before {
	content: "\EDBF";
}
.icon-Vector-4:before {
	content: "\EDC0";
}
.icon-Vector-5:before {
	content: "\EDC1";
}
.icon-Vector:before {
	content: "\EDC2";
}
.icon-Venn-Diagram:before {
	content: "\EDC3";
}
.icon-Vest-2:before {
	content: "\EDC4";
}
.icon-Vest:before {
	content: "\EDC5";
}
.icon-Viddler:before {
	content: "\EDC6";
}
.icon-Video-2:before {
	content: "\EDC7";
}
.icon-Video-3:before {
	content: "\EDC8";
}
.icon-Video-4:before {
	content: "\EDC9";
}
.icon-Video-5:before {
	content: "\EDCA";
}
.icon-Video-6:before {
	content: "\EDCB";
}
.icon-Video-GameController:before {
	content: "\EDCC";
}
.icon-Video-Len:before {
	content: "\EDCD";
}
.icon-Video-Len2:before {
	content: "\EDCE";
}
.icon-Video-Photographer:before {
	content: "\EDCF";
}
.icon-Video-Tripod:before {
	content: "\EDD0";
}
.icon-Video:before {
	content: "\EDD1";
}
.icon-Vietnam:before {
	content: "\EDD2";
}
.icon-View-Height:before {
	content: "\EDD3";
}
.icon-View-Width:before {
	content: "\EDD4";
}
.icon-Vimeo:before {
	content: "\EDD5";
}
.icon-Virgo-2:before {
	content: "\EDD6";
}
.icon-Virgo:before {
	content: "\EDD7";
}
.icon-Virus-2:before {
	content: "\EDD8";
}
.icon-Virus-3:before {
	content: "\EDD9";
}
.icon-Virus:before {
	content: "\EDDA";
}
.icon-Visa:before {
	content: "\EDDB";
}
.icon-Voice:before {
	content: "\EDDC";
}
.icon-Voicemail:before {
	content: "\EDDD";
}
.icon-Volleyball:before {
	content: "\EDDE";
}
.icon-Volume-Down:before {
	content: "\EDDF";
}
.icon-Volume-Up:before {
	content: "\EDE0";
}
.icon-VPN:before {
	content: "\EDE1";
}
.icon-Wacom-Tablet:before {
	content: "\EDE2";
}
.icon-Waiter:before {
	content: "\EDE3";
}
.icon-Walkie-Talkie:before {
	content: "\EDE4";
}
.icon-Wallet-2:before {
	content: "\EDE5";
}
.icon-Wallet-3:before {
	content: "\EDE6";
}
.icon-Wallet:before {
	content: "\EDE7";
}
.icon-Warehouse:before {
	content: "\EDE8";
}
.icon-Warning-Window:before {
	content: "\EDE9";
}
.icon-Watch-2:before {
	content: "\EDEA";
}
.icon-Watch-3:before {
	content: "\EDEB";
}
.icon-Watch:before {
	content: "\EDEC";
}
.icon-Wave-2:before {
	content: "\EDED";
}
.icon-Wave:before {
	content: "\EDEE";
}
.icon-Webcam:before {
	content: "\EDEF";
}
.icon-weight-Lift:before {
	content: "\EDF0";
}
.icon-Wheelbarrow:before {
	content: "\EDF1";
}
.icon-Wheelchair:before {
	content: "\EDF2";
}
.icon-Width-Window:before {
	content: "\EDF3";
}
.icon-Wifi-2:before {
	content: "\EDF4";
}
.icon-Wifi-Keyboard:before {
	content: "\EDF5";
}
.icon-Wifi:before {
	content: "\EDF6";
}
.icon-Wind-Turbine:before {
	content: "\EDF7";
}
.icon-Windmill:before {
	content: "\EDF8";
}
.icon-Window-2:before {
	content: "\EDF9";
}
.icon-Window:before {
	content: "\EDFA";
}
.icon-Windows-2:before {
	content: "\EDFB";
}
.icon-Windows-Microsoft:before {
	content: "\EDFC";
}
.icon-Windows:before {
	content: "\EDFD";
}
.icon-Windsock:before {
	content: "\EDFE";
}
.icon-Windy:before {
	content: "\EDFF";
}
.icon-Wine-Bottle:before {
	content: "\EE00";
}
.icon-Wine-Glass:before {
	content: "\EE01";
}
.icon-Wink:before {
	content: "\EE02";
}
.icon-Winter-2:before {
	content: "\EE03";
}
.icon-Winter:before {
	content: "\EE04";
}
.icon-Wireless:before {
	content: "\EE05";
}
.icon-Witch-Hat:before {
	content: "\EE06";
}
.icon-Witch:before {
	content: "\EE07";
}
.icon-Wizard:before {
	content: "\EE08";
}
.icon-Wolf:before {
	content: "\EE09";
}
.icon-Woman-Sign:before {
	content: "\EE0A";
}
.icon-WomanMan:before {
	content: "\EE0B";
}
.icon-Womans-Underwear:before {
	content: "\EE0C";
}
.icon-Womans-Underwear2:before {
	content: "\EE0D";
}
.icon-Women:before {
	content: "\EE0E";
}
.icon-Wonder-Woman:before {
	content: "\EE0F";
}
.icon-Wordpress:before {
	content: "\EE10";
}
.icon-Worker-Clothes:before {
	content: "\EE11";
}
.icon-Worker:before {
	content: "\EE12";
}
.icon-Wrap-Text:before {
	content: "\EE13";
}
.icon-Wreath:before {
	content: "\EE14";
}
.icon-Wrench:before {
	content: "\EE15";
}
.icon-X-Box:before {
	content: "\EE16";
}
.icon-X-ray:before {
	content: "\EE17";
}
.icon-Xanga:before {
	content: "\EE18";
}
.icon-Xing:before {
	content: "\EE19";
}
.icon-Yacht:before {
	content: "\EE1A";
}
.icon-Yahoo-Buzz:before {
	content: "\EE1B";
}
.icon-Yahoo:before {
	content: "\EE1C";
}
.icon-Yelp:before {
	content: "\EE1D";
}
.icon-Yes:before {
	content: "\EE1E";
}
.icon-Ying-Yang:before {
	content: "\EE1F";
}
.icon-Youtube:before {
	content: "\EE20";
}
.icon-Z-A:before {
	content: "\EE21";
}
.icon-Zebra:before {
	content: "\EE22";
}
.icon-Zombie:before {
	content: "\EE23";
}
.icon-Zoom-Gesture:before {
	content: "\EE24";
}
.icon-Zootool:before {
	content: "\EE25";
}

/*
    Common 
*/

.wizard,
.tabcontrol
{
    display: block;
    width: 100%;
    overflow: hidden;
}

.wizard a,
.tabcontrol a
{
    outline: 0;
}

.wizard ul,
.tabcontrol ul
{
    list-style: none !important;
    padding: 0;
    margin: 0;
}

.wizard ul > li,
.tabcontrol ul > li
{
    display: block;
    padding: 0;
}

/* Accessibility */
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info
{
    position: absolute;
    left: -999em;
}

.wizard > .content > .title,
.tabcontrol > .content > .title
{
    position: absolute;
    left: -999em;
}



/*
    Wizard
*/

.wizard > .steps
{
    position: relative;
    display: block;
    width: 100%;
}

.wizard.vertical > .steps
{
    display: inline;
    float: left;
    width: 30%;
}

.wizard > .steps .number
{
    font-size: 1.429em;
}

.wizard > .steps > ul > li
{
    width: 25%;
}

.wizard > .steps > ul > li,
.wizard > .actions > ul > li
{
    float: left;
}

.wizard.vertical > .steps > ul > li
{
    float: none;
    width: 100%;
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active
{
    display: block;
    width: auto;
    margin: 0 0.5em 0.5em;
    padding: 1em 1em;
    text-decoration: none;
    border-radius: 5px;
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active
{
    background: #eee;
    color: #aaa;
    cursor: default;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active
{
    background: #2184be;
    color: #fff;
    cursor: default;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active
{
    background: #9dc8e2;
    color: #fff;
}

.wizard > .steps .error a,
.wizard > .steps .error a:hover,
.wizard > .steps .error a:active
{
    background: #ff3111;
    color: #fff;
}

.wizard > .content
{
    background: #eee;
    display: block;
    margin: 0.5em;
    min-height: 25em;
    overflow: hidden;
    position: relative;
    width: auto;
    border-radius: 5px;
}

.wizard.vertical > .content
{
    display: inline;
    float: left;
    margin: 0 2.5% 0.5em 2.5%;
    width: 65%;
}

.wizard > .content > .body
{
    float: left;
    position: absolute;
    width: 95%;
    height: 95%;
    padding: 2.5%;
}

.wizard > .content > .body ul
{
    list-style: disc !important;
}

.wizard > .content > .body ul > li
{
    display: list-item;
}

.wizard > .content > .body > iframe
{
    border: 0 none;
    width: 100%;
    height: 100%;
}

.wizard > .content > .body input
{
    display: block;
    border: 1px solid #ccc;
}

.wizard > .content > .body input[type="checkbox"]
{
    display: inline-block;
}

.wizard > .content > .body input.error
{
    background: rgb(251, 227, 228);
    border: 1px solid #fbc2c4;
    color: #8a1f11;
}

.wizard > .content > .body label
{
    display: inline-block;
    margin-bottom: 0.5em;
}

.wizard > .content > .body label.error
{
    color: #8a1f11;
    display: inline-block;
    margin-left: 1.5em;
}

.wizard > .actions
{
    position: relative;
    display: block;
    text-align: right;
    width: 100%;
}

.wizard.vertical > .actions
{
    display: inline;
    float: right;
    margin: 0 2.5%;
    width: 95%;
}

.wizard > .actions > ul
{
    display: inline-block;
    text-align: right;
}

.wizard > .actions > ul > li
{
    margin: 0 0.5em;
}

.wizard.vertical > .actions > ul > li
{
    margin: 0 0 0 1em;
}

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active
{
    background: #2184be;
    color: #fff;
    display: block;
    padding: 0.5em 1em;
    text-decoration: none;
    border-radius: 5px;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active
{
    background: #eee;
    color: #aaa !important;
}

.wizard > .loading
{
}

.wizard > .loading .spinner
{
}



/*
    Tabcontrol
*/

.tabcontrol > .steps
{
    position: relative;
    display: block;
    width: 100%;
}

.tabcontrol > .steps > ul
{
    position: relative;
    margin: 6px 0 0 0;
    top: 1px;
    z-index: 1;
}

.tabcontrol > .steps > ul > li
{
    float: left;
    margin: 5px 2px 0 0;
    padding: 1px;

    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.tabcontrol > .steps > ul > li:hover
{
    background: #edecec;
    border: 1px solid #bbb;
    padding: 0;
}

.tabcontrol > .steps > ul > li.current
{
    background: #fff;
    border: 1px solid #bbb;
    border-bottom: 0 none;
    padding: 0 0 1px 0;
    margin-top: 0;
}

.tabcontrol > .steps > ul > li > a
{
    color: #5f5f5f;
    display: inline-block;
    border: 0 none;
    margin: 0;
    padding: 10px 30px;
    text-decoration: none;
}

.tabcontrol > .steps > ul > li > a:hover
{
    text-decoration: none;
}

.tabcontrol > .steps > ul > li.current > a
{
    padding: 15px 30px 10px 30px;
}

.tabcontrol > .content
{
    position: relative;
    display: inline-block;
    width: 100%;
    height: 35em;
    overflow: hidden;
    border-top: 1px solid #bbb;
    padding-top: 20px;
}

.tabcontrol > .content > .body
{
    float: left;
    position: absolute;
    width: 95%;
    height: 95%;
    padding: 2.5%;
}

.tabcontrol > .content > .body ul
{
    list-style: disc !important;
}

.tabcontrol > .content > .body ul > li
{
    display: list-item;
}
/**!**************/
/**! Contents **/
/**! 01. Reset **/
/**! 02. Typography **/
/**! 03. Position **/
/**! 04. Element Size **/
/**! 05. Images **/
/**! 06. Buttons **/
/**! 07. Icons **/
/**! 08. Lists **/
/**! 09. Lightbox **/
/**! 10. Menus **/
/**! 11. Dropdowns **/
/**! 12. Form Elements **/
/**! 13. Accordions **/
/**! 14. Breadcrumbs **/
/**! 15. Radials **/
/**! 16. Tabs **/
/**! 17. Boxes **/
/**! 18. Sliders Flickity **/
/**! 19. Hover Elements **/
/**! 20. Masonry **/
/**! 21. Modals **/
/**! 22. Maps **/
/**! 23. Parallax **/
/**! 24. Notifications **/
/**! 25. Video **/
/**! 26. Colors **/
/**! 27. Image Blocks **/
/**! 28. MailChimp & Campaign Monitor **/
/**! 29. Twitter **/
/**! 30. Transitions **/
/**! 31. Switchable Sections **/
/**! 32. Typed Effect **/
/**! 33. Gradient BG **/
/**! 34. Bars **/
/**! 35. Navigation In Page **/
/**! 36. Helper Classes **/
/**! 37. Spacing **/
/**! 38. Boxed Layout **/
/**! 39. Wizard **/
/**! 40. Alerts **/
/**! 41. Progress - Horizontal **/
/**! 42. Theme Overrides **/
/**!**************/
/**!**************/
/**!**************/
/**! 01. Reset **/
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a {
  margin: 0;
  padding: 0;
}
/**! 02. Typography **/
html {
  font-size: 87.5%;
}
@media all and (max-width: 768px) {
  html {
    font-size: 81.25%;
  }
}
body {
  font-size: 1em;
  line-height: 1.85714286em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  color: #666666;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  color: #252525;
  font-weight: 300;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
}
h1,
.h1 {
  font-size: 3.14285714em;
  line-height: 1.31818182em;
}
h2,
.h2 {
  font-size: 2.35714286em;
  line-height: 1.36363636em;
}
h3,
.h3 {
  font-size: 1.78571429em;
  line-height: 1.5em;
}
h4,
.h4 {
  font-size: 1.35714286em;
  line-height: 1.36842105em;
}
h5,
.h5 {
  font-size: 1em;
  line-height: 1.85714286em;
}
h6,
.h6 {
  font-size: 0.85714286em;
  line-height: 2.16666667em;
}
.lead {
  font-size: 1.1em;
  line-height: 1.68421053em;
}
@media all and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 2.35714286em;
    line-height: 1.36363636em;
  }
  h2,
  .h2 {
    font-size: 1.78571429em;
    line-height: 1.5em;
  }
  h3,
  .h3 {
    font-size: 1.35714286em;
    line-height: 1.85714286em;
  }
  .lead {
    font-size: 1.35714286em;
    line-height: 1.36842105em;
  }
}
p,
ul,
ol,
pre,
table,
blockquote {
  margin-bottom: 1.85714286em;
}
ul,
ol {
  list-style: none;
  line-height: 1.85714286em;
}
ul.bullets {
  list-style: inside;
}
ol {
  list-style-type: upper-roman;
  list-style-position: inside;
}
blockquote {
  font-size: 1.78571429em;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
  border-left: 0;
}
strong {
  font-weight: 600;
}
hr {
  margin: 1.85714286em 0;
  border-color: #fafafa;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}
/*! Typography -- Helpers */
.type--fade {
  opacity: .5;
}
.type--uppercase {
  text-transform: uppercase;
}
.type--bold {
  font-weight: bold;
}
.type--italic {
  font-style: italic;
}
.type--fine-print {
  font-size: 0.85714286em;
}
.type--strikethrough {
  text-decoration: line-through;
  opacity: .5;
}
.type--underline {
  text-decoration: underline;
}
.type--body-font {
  font-family: 'Open Sans', 'Helvetica';
}
/**! 03. Position **/
body {
  overflow-x: hidden;
}
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}
.pos-absolute.container {
  left: 0;
  right: 0;
}
.pos-top {
  top: 0;
}
.pos-bottom {
  bottom: 0;
}
.pos-right {
  right: 0;
}
.pos-left {
  left: 0;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
@media all and (max-width: 767px) {
  .float-left,
  .float-right {
    float: none;
  }
  .float-left-xs {
    float: left;
  }
  .float-right-xs {
    float: right;
  }
}
.pos-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
@media all and (max-width: 767px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
@media all and (max-height: 600px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}
.pos-vertical-align-columns {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.pos-vertical-align-columns > div[class*='col-'] {
  display: table-cell;
  float: none;
  vertical-align: middle;
}
@media all and (max-width: 990px) {
  .pos-vertical-align-columns {
    display: block;
    width: auto;
  }
  .pos-vertical-align-columns > div[class*='col-'] {
    display: block;
  }
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}
@media all and (max-width: 767px) {
  .block--xs {
    display: block;
  }
}
@media all and (max-width: 990px) {
  .text-center-md {
    text-align: center;
  }
  .text-left-md {
    text-align: left;
  }
  .text-right-md {
    text-align: right;
  }
}
@media all and (max-width: 767px) {
  .text-center-xs {
    text-align: center;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
}
/**! 04. Element Size **/
.height-100,
.height-90,
.height-80,
.height-70,
.height-60,
.height-50,
.height-40,
.height-30,
.height-20,
.height-10 {
  height: auto;
  padding: 5em 0;
}
@media all and (max-width: 767px) {
  .height-100,
  .height-90,
  .height-80,
  .height-70,
  .height-60,
  .height-50,
  .height-40,
  .height-30,
  .height-20,
  .height-10 {
    height: auto;
    padding: 4em 0;
  }
}
@media all and (min-height: 600px) and (min-width: 767px) {
  .height-10 {
    height: 10vh;
  }
  .height-20 {
    height: 20vh;
  }
  .height-30 {
    height: 30vh;
  }
  .height-40 {
    height: 40vh;
  }
  .height-50 {
    height: 50vh;
  }
  .height-60 {
    height: 60vh;
  }
  .height-70 {
    height: 70vh;
  }
  .height-80 {
    height: 80vh;
  }
  .height-90 {
    height: 90vh;
  }
  .height-100 {
    height: 100vh;
  }
}
section.height-auto {
  height: auto;
}
section.height-auto .pos-vertical-center {
  top: 0;
  position: relative;
  -webkit-transform: none;
          transform: none;
}
@media all and (max-width: 767px) {
  div[class*='col-'][class*='height-'] {
    padding-top: 5.57142857em !important;
    padding-bottom: 5.57142857em !important;
  }
}
/**! 05. Images **/
img {
  max-width: 100%;
  margin-bottom: 1.85714286em;
}
/*p+img, img:last-child{
	margin-bottom: 0;
}*/
.img--fullwidth {
  width: 100%;
}
[data-grid="2"].masonry {
  -webkit-column-count: 2;
  -webkit-column-gap: 0;
  -moz-column-count: 2;
  -moz-column-gap: 0;
  column-count: 2;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="2"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="2"] li {
  width: 50%;
  display: inline-block;
}
[data-grid="3"].masonry {
  -webkit-column-count: 3;
  -webkit-column-gap: 0;
  -moz-column-count: 3;
  -moz-column-gap: 0;
  column-count: 3;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="3"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="3"] li {
  width: 33.33333333%;
  display: inline-block;
}
[data-grid="4"].masonry {
  -webkit-column-count: 4;
  -webkit-column-gap: 0;
  -moz-column-count: 4;
  -moz-column-gap: 0;
  column-count: 4;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="4"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="4"] li {
  width: 25%;
  display: inline-block;
}
[data-grid="5"].masonry {
  -webkit-column-count: 5;
  -webkit-column-gap: 0;
  -moz-column-count: 5;
  -moz-column-gap: 0;
  column-count: 5;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="5"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="5"] li {
  width: 20%;
  display: inline-block;
}
[data-grid="6"].masonry {
  -webkit-column-count: 6;
  -webkit-column-gap: 0;
  -moz-column-count: 6;
  -moz-column-gap: 0;
  column-count: 6;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="6"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="6"] li {
  width: 16.66666667%;
  display: inline-block;
}
[data-grid="7"].masonry {
  -webkit-column-count: 7;
  -webkit-column-gap: 0;
  -moz-column-count: 7;
  -moz-column-gap: 0;
  column-count: 7;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="7"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="7"] li {
  width: 14.28571429%;
  display: inline-block;
}
[data-grid="8"].masonry {
  -webkit-column-count: 8;
  -webkit-column-gap: 0;
  -moz-column-count: 8;
  -moz-column-gap: 0;
  column-count: 8;
  grid-column-gap: 0;
  column-gap: 0;
}
[data-grid="8"].masonry li {
  width: 100%;
  float: none;
}
[data-grid="8"] li {
  width: 12.5%;
  display: inline-block;
}
@media all and (max-width: 767px) {
  [data-grid]:not(.masonry) li {
    width: 33.333333%;
  }
  [data-grid="2"]:not(.masonry) li {
    width: 50%;
  }
  [data-grid].masonry {
    -webkit-column-count: 1;
    column-count: 1;
  }
}
.background-image-holder {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity .3s linear;
  -webkit-transition: opacity .3s linear;
  opacity: 0;
  background: #252525;
}
.background-image-holder:not([class*='col-']) {
  width: 100%;
}
.background-image-holder.background--bottom {
  background-position: 50% 100% !important;
}
.background-image-holder.background--top {
  background-position: 50% 0% !important;
}
.image--light .background-image-holder {
  background: none;
}
.background-image-holder img {
  display: none;
}
[data-overlay] {
  position: relative;
}
[data-overlay]:before {
  position: absolute;
  content: '';
  background: #252525;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
[data-overlay] *:not(.container):not(.background-image-holder) {
  z-index: 2;
}
[data-overlay].image--light:before {
  background: #fff;
}
[data-overlay].bg--primary:before {
  background: #4a90e2;
}
[data-overlay="1"]:before {
  opacity: 0.1;
}
[data-overlay="2"]:before {
  opacity: 0.2;
}
[data-overlay="3"]:before {
  opacity: 0.3;
}
[data-overlay="4"]:before {
  opacity: 0.4;
}
[data-overlay="5"]:before {
  opacity: 0.5;
}
[data-overlay="6"]:before {
  opacity: 0.6;
}
[data-overlay="7"]:before {
  opacity: 0.7;
}
[data-overlay="8"]:before {
  opacity: 0.8;
}
[data-overlay="9"]:before {
  opacity: 0.9;
}
[data-overlay="10"]:before {
  opacity: 1;
}
[data-overlay="0"]:before {
  opacity: 0;
}
[data-scrim-bottom] {
  position: relative;
}
[data-scrim-bottom]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, #252525));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #252525 100%);
  bottom: 0;
  left: 0;
  z-index: 1;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
[data-scrim-bottom]:not(.image--light) h1,
[data-scrim-bottom]:not(.image--light) h2,
[data-scrim-bottom]:not(.image--light) h3,
[data-scrim-bottom]:not(.image--light) h4,
[data-scrim-bottom]:not(.image--light) h5,
[data-scrim-bottom]:not(.image--light) h6 {
  color: #a5a5a5;
  color: #f1f1f1;
}
[data-scrim-bottom]:not(.image--light) p,
[data-scrim-bottom]:not(.image--light) span,
[data-scrim-bottom]:not(.image--light) ul {
  color: #e6e6e6;
}
[data-scrim-bottom].image--light:before {
  background: #fff;
}
[data-scrim-bottom="1"]:before {
  opacity: 0.1;
}
[data-scrim-bottom="2"]:before {
  opacity: 0.2;
}
[data-scrim-bottom="3"]:before {
  opacity: 0.3;
}
[data-scrim-bottom="4"]:before {
  opacity: 0.4;
}
[data-scrim-bottom="5"]:before {
  opacity: 0.5;
}
[data-scrim-bottom="6"]:before {
  opacity: 0.6;
}
[data-scrim-bottom="7"]:before {
  opacity: 0.7;
}
[data-scrim-bottom="8"]:before {
  opacity: 0.8;
}
[data-scrim-bottom="9"]:before {
  opacity: 0.9;
}
[data-scrim-bottom="10"]:before {
  opacity: 1;
}
[data-scrim-top] {
  position: relative;
}
[data-scrim-top]:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 80%;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left bottom, left bottom, color-stop(0%, #252525), color-stop(100%, rgba(0, 0, 0, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: -webkit-linear-gradient(top, #252525 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: 1;
}
[data-scrim-top]:not(.image--light) h1,
[data-scrim-top]:not(.image--light) h2,
[data-scrim-top]:not(.image--light) h3,
[data-scrim-top]:not(.image--light) h4,
[data-scrim-top]:not(.image--light) h5,
[data-scrim-top]:not(.image--light) h6 {
  color: #fff;
}
[data-scrim-top]:not(.image--light) p,
[data-scrim-top]:not(.image--light) span,
[data-scrim-top]:not(.image--light) ul {
  color: #e6e6e6;
}
[data-scrim-top].image--light:before {
  background: #fff;
}
[data-scrim-top="1"]:before {
  opacity: 0.1;
}
[data-scrim-top="2"]:before {
  opacity: 0.2;
}
[data-scrim-top="3"]:before {
  opacity: 0.3;
}
[data-scrim-top="4"]:before {
  opacity: 0.4;
}
[data-scrim-top="5"]:before {
  opacity: 0.5;
}
[data-scrim-top="6"]:before {
  opacity: 0.6;
}
[data-scrim-top="7"]:before {
  opacity: 0.7;
}
[data-scrim-top="8"]:before {
  opacity: 0.8;
}
[data-scrim-top="9"]:before {
  opacity: 0.9;
}
[data-scrim-top="10"]:before {
  opacity: 1;
}
.imagebg {
  position: relative;
}
.imagebg .container {
  z-index: 2;
}
.imagebg .container:not(.pos-absolute) {
  position: relative;
}
.imagebg:not(.image--light) h1,
.imagebg:not(.image--light) h2,
.imagebg:not(.image--light) h3,
.imagebg:not(.image--light) h4,
.imagebg:not(.image--light) h5,
.imagebg:not(.image--light) h6,
.imagebg:not(.image--light) p,
.imagebg:not(.image--light) ul,
.imagebg:not(.image--light) blockquote {
  color: #fff;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6 {
  color: #252525;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white ul {
  color: #666666;
}
div[data-overlay] h1,
div[data-overlay] h2,
div[data-overlay] h3,
div[data-overlay] h4,
div[data-overlay] h5,
div[data-overlay] h6 {
  color: #fff;
}
div[data-overlay] p,
div[data-overlay] ul {
  color: #fff;
}
.parallax {
  overflow: hidden;
}
.parallax .background-image-holder {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
}
.image--xxs {
  max-height: 1.85714286em;
}
.image--xs {
  max-height: 3.71428571em;
}
.image--sm {
  max-height: 5.57142857em;
}
.image--md {
  max-height: 7.42857143em;
}
/**! 06. Buttons **/
.btn {
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  border-radius: 6px;
  padding-top: 0.46428571em;
  padding-bottom: 0.46428571em;
  padding-right: 2.78571429em;
  padding-left: 2.78571429em;
  border: 1px solid #252525;
  border-width: 1px;
  font-size: inherit;
  line-height: 1.85714286em;
}
.btn .btn__text,
.btn i {
  color: #252525;
  border-color: #252525;
  font-weight: 700;
  font-size: 0.85714286em;
}
.btn[class*='col-'] {
  margin-left: 0;
  margin-right: 0;
}
.btn:active {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.btn.bg--facebook,
.btn.bg--twitter,
.btn.bg--instagram,
.btn.bg--googleplus,
.btn.bg--pinterest,
.btn.bg--dribbble,
.btn.bg--behance,
.btn.bg--dark {
  border-color: rgba(0, 0, 0, 0) !important;
}
.btn.bg--facebook .btn__text,
.btn.bg--twitter .btn__text,
.btn.bg--instagram .btn__text,
.btn.bg--googleplus .btn__text,
.btn.bg--pinterest .btn__text,
.btn.bg--dribbble .btn__text,
.btn.bg--behance .btn__text,
.btn.bg--dark .btn__text {
  color: #fff;
}
.btn.bg--facebook .btn__text i,
.btn.bg--twitter .btn__text i,
.btn.bg--instagram .btn__text i,
.btn.bg--googleplus .btn__text i,
.btn.bg--pinterest .btn__text i,
.btn.bg--dribbble .btn__text i,
.btn.bg--behance .btn__text i,
.btn.bg--dark .btn__text i {
  color: #fff;
}
.btn.bg--facebook:hover,
.btn.bg--twitter:hover,
.btn.bg--instagram:hover,
.btn.bg--googleplus:hover,
.btn.bg--pinterest:hover,
.btn.bg--dribbble:hover,
.btn.bg--behance:hover,
.btn.bg--dark:hover {
  opacity: .9;
}
.btn.bg--error {
  background: #e23636;
  border-color: #e23636 !important;
}
.btn.bg--error:hover {
  background: #e54c4c;
  border-color: #e54c4c !important;
  color: #fff !important;
}
.btn.bg--error .btn__text {
  color: #fff;
}
.btn.bg--error .btn__text i {
  color: #fff;
}
@media all and (min-width: 768px) {
  .btn + .btn {
    margin-left: 1.85714286em;
  }
}
.btn:first-child {
  margin-left: 0;
}
.btn:last-child {
  margin-right: 0;
}
.btn--xs {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 1.39285714em;
  padding-left: 1.39285714em;
}
.btn--sm {
  padding-top: 0.30952381em;
  padding-bottom: 0.30952381em;
  padding-right: 1.85714286em;
  padding-left: 1.85714286em;
}
.btn--lg {
  padding-top: 0.58035714em;
  padding-bottom: 0.58035714em;
  padding-right: 3.48214286em;
  padding-left: 3.48214286em;
}
.btn--lg .btn__text {
  font-size: 1.07142857em;
}
.btn--primary,
.btn--primary:visited {
  background: #4a90e2;
  border-color: #4a90e2;
  text-decoration: none;
}
.btn--primary.btn--stroked,
.btn--primary.btn--stroked:visited,
.btn--primary.btn--stroked:hover {
  background: none;
  border-color: white;
  text-decoration: none;
}
.btn--primary .btn__text,
.btn--primary:visited .btn__text {
  color: #fff;
}
.btn--primary .btn__text i,
.btn--primary:visited .btn__text i {
  color: #fff;
}
.btn--primary:hover {
  background: #609de6;
}
.btn--primary:active {
  background: #3483de;
}
.btn--primary-1,
.btn--primary-1:visited {
  background: #31639c;
  border-color: #31639c;
}
.btn--primary-1 .btn__text,
.btn--primary-1:visited .btn__text {
  color: #fff;
}
.btn--primary-1:hover {
  background: #376faf;
}
.btn--primary-1:active {
  background: #2b5789;
}
.btn--primary-2,
.btn--primary-2:visited {
  background: #465773;
  border-color: #465773;
}
.btn--primary-2 .btn__text,
.btn--primary-2:visited .btn__text {
  color: #fff;
}
.btn--primary-2:hover {
  background: #506383;
}
.btn--primary-2:active {
  background: #3c4b63;
}
.btn--secondary {
  background: #fafafa;
  border-color: #fafafa;
}
.btn--secondary:hover {
  background: #ffffff;
}
.btn--secondary:active {
  background: #f5f5f5;
}
.btn--white {
  background: #fff;
  color: #252525;
  border-color: #fff;
}
.btn--white i {
  color: #252525;
}
.btn--transparent {
  background: none;
  border-color: rgba(0, 0, 0, 0);
  padding-left: 0;
  padding-right: 0;
}
.btn--transparent.btn--white .btn__text {
  color: #fff;
}
.btn--unfilled {
  background: none;
}
.btn--unfilled.btn--white .btn__text {
  color: #fff;
}
.btn--unfilled.btn--white i {
  color: #fff;
}
.btn--floating {
  position: fixed;
  bottom: 3.71428571em;
  right: 3.71428571em;
  z-index: 10;
}
/**! 07. Icons **/
.icon {
  line-height: 1em;
  font-size: 3.14285714em;
}
.icon--xs {
  font-size: 1em;
}
.icon--sm {
  font-size: 2.35714286em;
}
.icon--lg {
  font-size: 5.57142857em;
}
/**! 08. Lists **/
ul:last-child {
  margin: 0;
}
.list-inline li {
  padding: 0 1em;
  margin-left: 0;
}
.list-inline li:first-child {
  padding-left: 0;
}
.list-inline li:last-child {
  padding-right: 0;
}
.list-inline.list-inline--narrow li {
  padding: 0 .5em;
}
.list-inline.list-inline--wide li {
  padding: 0 2em;
}
/**! 09. Lightbox **/
.lb-outerContainer {
  border-radius: 0;
}
.lb-outerContainer .lb-container {
  padding: 0;
}
.lb-outerContainer .lb-container img {
  margin: 0;
}
.lightbox-gallery {
  overflow: hidden;
}
.lightbox-gallery li {
  float: left;
}
.lightbox-gallery li img {
  margin: 0;
  width: 100%;
}
.lightbox-gallery.gallery--gaps li {
  padding: 0.46428571em;
}
/**! 10. Menus **/
.menu-horizontal > li:not(:hover) > a,
.menu-horizontal > li:not(:hover) > span,
.menu-horizontal > li:not(:hover) > .modal-instance > .modal-trigger {
  opacity: .5;
}
.menu-horizontal > li > a,
.menu-horizontal > li > span,
.menu-horizontal > li > .modal-instance > .modal-trigger {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  color: #252525;
}
.menu-horizontal > li > a:hover,
.menu-horizontal > li > span:hover,
.menu-horizontal > li > .modal-instance > .modal-trigger:hover {
  color: #252525;
}
.bg--dark .menu-horizontal > li > a,
.bg--dark .menu-horizontal > li > span {
  color: #fff;
}
.bg--dark .menu-horizontal > li > a:hover,
.bg--dark .menu-horizontal > li > span:hover {
  color: #fff;
}
.menu-vertical {
  width: 100%;
}
.menu-vertical li {
  width: 100%;
}
.menu-vertical li a {
  font-weight: normal;
}
@media all and (min-width: 990px) {
  .menu-horizontal {
    display: inline-block;
  }
  .menu-horizontal > li {
    display: inline-block;
  }
  .menu-horizontal > li:not(:last-child) {
    margin-right: 1.85714286em;
  }
  .menu-vertical {
    display: inline-block;
  }
  .menu-vertical li {
    white-space: nowrap;
  }
  .menu-vertical .dropdown__container {
    top: 0;
  }
  .menu-vertical .dropdown__container .dropdown__content:not([class*='bg-']) {
    background: #ffffff;
  }
  .menu-vertical .dropdown__container .dropdown__content {
    -webkit-transform: translateX(75%);
            transform: translateX(75%);
  }
}
/**! 11. Dropdowns **/
.dropdown {
  position: relative;
}
.dropdown .dropdown__container {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: 999;
}
.dropdown .dropdown__container .dropdown__container:before {
  height: 0;
}
.dropdown .dropdown__content {
  padding: 1.85714286em;
}
.dropdown .dropdown__content:not([class*='col-']) {
  width: 18.57142857em;
}
.dropdown .dropdown__content:not([class*='bg-']) {
  background: #ffffff;
}
.dropdown .dropdown__content:not([class='bg--dark']) .menu-vertical a {
  color: #666666;
}
.dropdown .dropdown__trigger {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dropdown.dropdown--active > .dropdown__container {
  opacity: 1;
}
.dropdown.dropdown--active > .dropdown__container > .container > .row > .dropdown__content {
  pointer-events: all;
}
@media all and (min-width: 991px) {
  .dropdown .dropdown__container:before {
    height: 0.92857143em;
    width: 100%;
    content: '';
    display: block;
  }
  .dropdown .dropdown__content.dropdown__content--md {
    padding: 2.78571429em;
  }
  .dropdown .dropdown__content.dropdown__content--lg {
    padding: 3.71428571em;
  }
  .dropdown .dropdown__content.dropdown__content--xlg {
    padding: 4.64285714em;
  }
}
@media all and (max-width: 767px) {
  .dropdown .dropdown__container {
    min-width: 100%;
    position: relative;
    display: none;
  }
  .dropdown .dropdown__content {
    padding: 1.85714286em;
    left: 0 !important;
  }
  .dropdown.dropdown--active > .dropdown__container {
    display: block;
  }
}
body.dropdowns--hover .dropdown:not(.dropdown--click):hover > .dropdown__container {
  opacity: 1;
}
body.dropdowns--hover .dropdown:not(.dropdown--click):hover > .dropdown__container:before {
  pointer-events: all;
}
body.dropdowns--hover .dropdown:not(.dropdown--click):hover > .dropdown__container .dropdown__content {
  pointer-events: all;
}
body:not(.dropdowns--hover) .dropdown.dropdown--hover:hover > .dropdown__container {
  opacity: 1;
}
body:not(.dropdowns--hover) .dropdown.dropdown--hover:hover > .dropdown__container:before {
  pointer-events: all;
}
body:not(.dropdowns--hover) .dropdown.dropdown--hover:hover > .dropdown__container .dropdown__content {
  pointer-events: all;
}
@media all and (max-width: 990px) {
  body.dropdowns--hover .dropdown:not(.dropdown--click).dropdown--active > .dropdown__container {
    display: block;
  }
  body.dropdowns--hover .dropdown:not(.dropdown--click).dropdown--active > .dropdown__container:before {
    pointer-events: all;
  }
}
@media all and (max-width: 767px) {
  .dropdown.dropdown--absolute .dropdown__container {
    position: absolute;
  }
}
/**! 12. Form Elements **/
form {
  max-width: 100%;
}
form + form {
  margin-top: 30px;
}
form:before,
form:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
form:after {
  clear: both;
}
label {
  margin: 0;
  font-size: 1.14285714em;
  font-weight: 400;
}
input[type],
textarea,
select {
  -webkit-appearance: none;
  background: #fcfcfc;
  padding: 0.46428571em;
  border-radius: 6px;
  border: 1px solid #ececec;
}
input[type]:focus,
textarea:focus,
select:focus {
  outline: none;
}
input[type]:active,
textarea:active,
select:active {
  outline: none;
}
input[type]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type]:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder {
  /* Firefox 18- */
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type]::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
  /* Firefox 19+ */
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #b3b3b3;
  font-size: 1.14285714em;
}
input[type="image"] {
  border: none;
  padding: none;
  width: auto;
}
textarea {
  display: block;
  width: 100%;
  max-width: 100%;
}
select {
  cursor: pointer;
  padding-right: 1.85714286em;
  -webkit-appearance: none;
}
select::ms-expand {
  display: none;
}
input[type="submit"] {
  background: none;
  outline: none;
  border: none;
  background: #4a90e2;
  padding: 0.46428571em 2.78571429em 0.46428571em 2.78571429em;
  color: #fff;
}
@-webkit-keyframes load {
  0% {
    opacity: 0;
    width: 0;
  }
  50% {
    width: 100%;
    opacity: .8;
    left: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
@keyframes load {
  0% {
    opacity: 0;
    width: 0;
  }
  50% {
    width: 100%;
    opacity: .8;
    left: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
button {
  background: none;
}
button[type="submit"].btn--loading {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  color: rgba(0, 0, 0, 0);
}
button[type="submit"].btn--loading * {
  opacity: 0;
}
button[type="submit"].btn--loading:after {
  content: '';
  position: absolute;
  width: 0;
  height: 100%;
  background: #ddd;
  -webkit-animation: load 1.5s ease-out infinite;
          animation: load 1.5s ease-out infinite;
  left: 0;
  top: 0;
}
button[type="submit"].btn--loading .btn__text {
  opacity: 0;
}
button:focus {
  outline: none !important;
}
button.bg--error {
  color: #fff;
}
.input-icon {
  position: relative;
}
.input-icon i {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  position: absolute;
}
.input-checkbox,
.input-radio,
.input-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
}
.input-checkbox input,
.input-radio input,
.input-select input {
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  position: absolute !important;
}
.input-checkbox label,
.input-radio label,
.input-select label {
  display: block !important;
  cursor: pointer;
}
.input-checkbox {
  padding: 0;
}
.input-checkbox label {
  width: 1.85714286em;
  height: 1.85714286em;
  border-radius: 6px;
  background: #000;
}
.input-radio {
  padding: 0;
}
.input-radio label {
  width: 1.85714286em;
  height: 1.85714286em;
  border-radius: 50%;
  background: #000;
}
.input-select {
  position: relative;
}
.input-select i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 1em;
  font-size: .87em;
}
.input-file {
  position: relative;
  display: inline-block;
}
.input-file input {
  display: none;
}
.form-error {
  margin-top: 1.5625em;
  padding: 0.78125em;
  background: #D84D4D;
  color: #fff;
  position: fixed;
  min-width: 350px;
  left: 50%;
  bottom: 1.5625em;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.form-success {
  margin-top: 1.5625em;
  padding: 0.78125em;
  background: #1DC020;
  color: #fff;
  position: fixed;
  min-width: 350px;
  left: 50%;
  bottom: 1.5625em;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.attempted-submit .field-error {
  border-color: #D84D4D !important;
}
.attempted-submit div.recaptcha.field-error {
  border-color: #D84D4D !important;
  border-style: solid;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
}
div.recaptcha iframe {
  min-height: 0;
}
/**! 13. Accordions **/
.accordion li .accordion__title,
.accordion li .accordion__content,
.accordion li .accordion__content * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.accordion li .accordion__title {
  cursor: pointer;
  padding: 0.46428571em 0.92857143em;
  border: 1px solid #ececec;
  border-bottom: none;
  background: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.accordion li:last-child .accordion__title {
  border-bottom: 1px solid #ececec;
}
.accordion li .accordion__content {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}
.accordion li .accordion__content > * {
  display: none;
}
.accordion li .accordion__content > *:first-child {
  padding-top: 0;
}
.accordion li .accordion__content > *:last-child {
  padding-bottom: 0;
}
.accordion li.active .accordion__title {
  background: #4a90e2;
  border-bottom: 1px solid #ececec;
}
.accordion li.active .accordion__content {
  opacity: 1;
  visibility: visible;
  max-height: 500px;
}
.accordion li.active .accordion__content > * {
  display: inline-block;
}
/**! 14. Breadcrumbs **/
.breadcrumb {
  padding: 0;
  margin: 0;
  background: none;
  display: inline-block;
}
.breadcrumb li {
  font-size: 1em;
}
.breadcrumb li + li:before {
  padding: 0 0.46428571em;
}
/**! 15. Pie Charts **/
.radial {
  position: relative;
}
.radial .radial__label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  margin-bottom: 0;
}
/**! 16. Tabs **/
.tabs {
  display: block;
  margin-bottom: 0;
}
.tabs > li {
  display: inline-block;
  opacity: .5;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tabs > .active,
.tabs:hover {
  opacity: 1;
}
.tabs .tab__title {
  cursor: pointer;
}
.tabs .tab__title:not(.btn) {
  padding: 0 1.85714286em;
}
.tabs li:first-child .tab__title:not(.btn) {
  padding-left: 0;
}
.tabs .tab__content {
  display: none;
}
.tabs-content {
  margin-top: 1em;
}
.tabs-content li > .tab__content {
  width: 100%;
  display: none;
}
.tabs-content > .active > .tab__content {
  display: block;
}
.tabs-container[data-content-align="left"] .tabs-content {
  text-align: left;
}
@media all and (max-width: 767px) {
  .tabs-content > li:not(.active) .tab__content {
    display: none !important;
  }
}
/**! 17. Boxes **/
.boxed {
  position: relative;
  overflow: hidden;
  padding: 1.85714286em;
  margin-bottom: 30px;
}
.boxed.boxed--lg {
  padding: 2.78571429em;
}
.boxed.boxed--sm {
  padding: 1.23809524em;
}
.boxed.boxed--border {
  border: 1px solid #ececec;
}
.boxed > div[class*='col-']:first-child:not(.boxed) {
  padding-left: 0;
}
.boxed > div[class*='col-']:last-child:not(.boxed) {
  padding-right: 0;
}
img + .boxed {
  margin-top: -1.85714286em;
}
@media all and (max-width: 767px) {
  .boxed {
    padding: 1.23809524em;
    margin-bottom: 15px;
  }
  .boxed.boxed--lg {
    padding: 1.23809524em;
  }
  .boxed div[class*='col-']:not(.boxed) {
    padding: 0;
  }
  .boxed:last-child {
    margin-bottom: 15px;
  }
}
/**! 18. Sliders Flickity **/
.slides:not(.flickity-enabled) li.imagebg:not(:first-child) {
  display: none;
}
.slides:not(.flickity-enabled) li.imagebg:first-child {
  background: #252525;
  -webkit-animation: backgroundLoad .5s ease alternate infinite;
          animation: backgroundLoad .5s ease alternate infinite;
}
.slides:not(.flickity-enabled) li.imagebg:first-child .container {
  opacity: 0;
}
@-webkit-keyframes backgroundLoad {
  0% {
    background: #252525;
  }
  100% {
    background: #3f3f3f;
  }
}
@keyframes backgroundLoad {
  0% {
    background: #252525;
  }
  100% {
    background: #3f3f3f;
  }
}
.slider.height-10 {
  height: auto;
}
.slider.height-10 .slides .flickity-slider > li {
  height: 10vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-10 .slides li.imagebg {
    min-height: 10vh;
  }
}
.slider.height-20 {
  height: auto;
}
.slider.height-20 .slides .flickity-slider > li {
  height: 20vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-20 .slides li.imagebg {
    min-height: 20vh;
  }
}
.slider.height-30 {
  height: auto;
}
.slider.height-30 .slides .flickity-slider > li {
  height: 30vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-30 .slides li.imagebg {
    min-height: 30vh;
  }
}
.slider.height-40 {
  height: auto;
}
.slider.height-40 .slides .flickity-slider > li {
  height: 40vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-40 .slides li.imagebg {
    min-height: 40vh;
  }
}
.slider.height-50 {
  height: auto;
}
.slider.height-50 .slides .flickity-slider > li {
  height: 50vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-50 .slides li.imagebg {
    min-height: 50vh;
  }
}
.slider.height-60 {
  height: auto;
}
.slider.height-60 .slides .flickity-slider > li {
  height: 60vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-60 .slides li.imagebg {
    min-height: 60vh;
  }
}
.slider.height-70 {
  height: auto;
}
.slider.height-70 .slides .flickity-slider > li {
  height: 70vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-70 .slides li.imagebg {
    min-height: 70vh;
  }
}
.slider.height-80 {
  height: auto;
}
.slider.height-80 .slides .flickity-slider > li {
  height: 80vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-80 .slides li.imagebg {
    min-height: 80vh;
  }
}
.slider.height-90 {
  height: auto;
}
.slider.height-90 .slides .flickity-slider > li {
  height: 90vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-90 .slides li.imagebg {
    min-height: 90vh;
  }
}
.slider.height-100 {
  height: auto;
}
.slider.height-100 .slides .flickity-slider > li {
  height: 100vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  .slider.height-100 .slides li.imagebg {
    min-height: 100vh;
  }
}
.slider .slides .flickity-slider > li:not([class*='col-']) {
  width: 100%;
}
.slider .slides.slides--gapless li[class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}
.slider[data-arrows="true"].slider--arrows-hover:not(:hover) .flickity-prev-next-button {
  opacity: 0;
}
.slider[data-paging="true"]:not(section) {
  margin-bottom: 3.71428571em;
}
.slider[data-paging="true"]:not(section) .flickity-page-dots {
  bottom: -3.71428571em;
}
.slider[data-paging="true"]:not([class*='text-']) .flickity-page-dots {
  text-align: center;
}
.slider[data-children="1"] .flickity-prev-next-button {
  display: none;
}
.slider:not([data-paging="true"]) .slides {
  margin: 0;
}
.slider.controls--dark .flickity-page-dots .dot {
  background: #252525;
}
.slider.controls--dark .flickity-prev-next-button:before {
  color: #252525;
}
section.slider, div.slider {
  padding: 0;
}
section.slider.height-10 {
  height: auto;
}
section.slider.height-10 .slides .flickity-slider > li {
  height: 10vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-10 .slides li.imagebg {
    min-height: 10vh;
  }
}
section.slider.height-20 {
  height: auto;
}
section.slider.height-20 .slides .flickity-slider > li {
  height: 20vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-20 .slides li.imagebg {
    min-height: 20vh;
  }
}
section.slider.height-30 {
  height: auto;
}
section.slider.height-30 .slides .flickity-slider > li {
  height: 30vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-30 .slides li.imagebg {
    min-height: 30vh;
  }
}
section.slider.height-40 {
  height: auto;
}
section.slider.height-40 .slides .flickity-slider > li {
  height: 40vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-40 .slides li.imagebg {
    min-height: 40vh;
  }
}
section.slider.height-50 {
  height: auto;
}
section.slider.height-50 .slides .flickity-slider > li {
  height: 50vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-50 .slides li.imagebg {
    min-height: 50vh;
  }
}
section.slider.height-60 {
  height: auto;
}
section.slider.height-60 .slides .flickity-slider > li {
  height: 60vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-60 .slides li.imagebg {
    min-height: 60vh;
  }
}
section.slider.height-70 {
  height: auto;
}
section.slider.height-70 .slides .flickity-slider > li {
  height: 70vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-70 .slides li.imagebg {
    min-height: 70vh;
  }
}
section.slider.height-80 {
  height: auto;
}
section.slider.height-80 .slides .flickity-slider > li {
  height: 80vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-80 .slides li.imagebg {
    min-height: 80vh;
  }
}
section.slider.height-90 {
  height: auto;
}
section.slider.height-90 .slides .flickity-slider > li {
  height: 90vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-90 .slides li.imagebg {
    min-height: 90vh;
  }
}
section.slider.height-100 {
  height: auto;
}
section.slider.height-100 .slides .flickity-slider > li {
  height: 100vh;
  padding: 0;
}
@media all and (min-width: 768px) {
  section.slider.height-100 .slides li.imagebg {
    min-height: 100vh;
  }
}
section.slider[data-paging="true"] .flickity-page-dots {
  bottom: 1.85714286em;
}
section.slider:not(.image--light)[data-paging="true"] .flickity-page-dots .dot {
  background: #fff;
}
section.slider .slides {
  margin: 0;
}
@media all and (max-width: 767px) {
  section.slider[class*='height-'] .slides .flickity-slider > li {
    height: auto;
    padding: 7.42857143em 0;
  }
  section.slider.space--lg .slides .flickity-slider > li {
    padding: 11.14285714em 0;
  }
  section.slider.space--xlg .slides .flickity-slider > li {
    padding: 11.14285714em 0;
  }
}
section.bg--dark .slider[data-paging="true"] .flickity-page-dots .dot,
section.bg--primary .slider[data-paging="true"] .flickity-page-dots .dot {
  background: #fff;
}
.flickity-page-dots .dot {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #252525;
  border: none;
  margin: 0 0.46428571em;
}
.flickity-page-dots .dot:hover:not(.is-selected) {
  opacity: .6;
}
.text-center .flickity-page-dots,
section.slider .flickity-page-dots {
  text-align: center;
}
.flickity-prev-next-button svg {
  display: none;
}
.flickity-prev-next-button:before {
  font-family: 'stack-interface';
  content: "\E80C";
  font-size: 1em;
  font-weight: normal;
}
.flickity-prev-next-button.previous:before {
  content: "\E80B";
}
.imagebg:not(.image--light) .flickity-page-dots .dot,
.bg--dark .flickity-page-dots .dot {
  background: #fff;
}
/**! 19. Hover Elements **/
.hover-element {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.hover-element * {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.hover-element .hover-element__reveal {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.hover-element .hover-element__reveal .boxed {
  height: 100%;
}
.hover-element:hover .hover-element__reveal,
.hover-element.hover--active .hover-element__reveal {
  opacity: 1;
}
.hover-element img {
  margin-bottom: 0;
}
@media all and (max-width: 1024px) {
  .hover-element {
    cursor: pointer;
  }
}
.row:last-child div[class*='col-']:last-child .hover-element {
  margin-bottom: 0;
}
/**! 20. Masonry **/
.masonry .masonry__container.masonry--active .masonry__item {
  opacity: 1;
  pointer-events: all;
}
.masonry .masonry__container .masonry__item {
  opacity: 0;
  pointer-events: none;
}
.masonry .masonry__filters li {
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.masonry .masonry__filters li.active {
  cursor: default;
}
.masonry.masonry--gapless .masonry__item {
  padding: 0 !important;
  margin-bottom: 0;
}
/**! 21. Modals **/
.modal-instance .modal-body {
  display: none;
}
.modal-container {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.modal-container.modal-active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.modal-container:before {
  background: rgba(0, 0, 0, 0.85);
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.modal-container .modal-content {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: fixed;
  z-index: 2;
  top: 50%;
  left: 50%;
  max-height: 100%;
  overflow-y: scroll;
  border: none;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}
.modal-container .modal-content:not(.height--natural) {
  width: 50%;
  height: 50%;
}
.modal-container .modal-content .modal-close-cross {
  cursor: pointer;
  position: absolute;
  opacity: .5;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  top: 1em;
  right: 1em;
  z-index: 99;
}
.modal-container .modal-content .modal-close-cross:before {
  content: '\D7';
  font-size: 1.5em;
}
.modal-container .modal-content .modal-close-cross:hover {
  opacity: 1;
}
.modal-container .modal-content.imagebg:not(.image--light) .modal-close-cross:before {
  color: #fff;
}
.modal-container .modal-content iframe {
  width: 100%;
  outline: none;
  border: none;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.modal-container .modal-content iframe:first-child + .modal-close-cross:last-child {
  top: -3.71428571em;
}
.modal-content.section-modal {
  pointer-events: none;
}
.modal-content.section-modal [class*='col-'] {
  pointer-events: all;
}
@media all and (max-width: 767px) {
  .modal-container .modal-content {
    width: 97% !important;
    height: auto !important;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
/**! 22. Maps **/
.map-container {
  position: relative;
  overflow: hidden;
}
.map-container iframe,
.map-container .map-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
/**! 23. Parallax **/
.parallax > .background-image-holder,
.parallax .slides li > .background-image-holder {
  height: 100%;
  min-height: 100vh;
  top: -50vh;
  transition: opacity 0.3s ease !important;
  -webkit-transition: opacity 0.3s ease !important;
  -webkit-transform-style: preserve-3d;
}
.parallax:first-child .slides li > .background-image-holder,
.parallax:first-child .background-image-holder {
  top: 0;
}
.main-container > a:first-child + .parallax .background-image-holder {
  top: 0;
}
@media all and (max-width: 1024px) {
  .parallax > .background-image-holder,
  .parallax .slides li > .background-image-holder {
    -webkit-transition: transform 0.016s linear !important;
    -webkit-transition: -webkit-transform 0.016s linear !important;
    transition: -webkit-transform 0.016s linear !important;
    transition: transform 0.016s linear !important;
    transition: transform 0.016s linear, -webkit-transform 0.016s linear !important;
  }
  .parallax.parallax-disable-mobile .background-image-holder,
  .parallax.parallax-disable-mobile .slides li > .background-image-holder {
    top: 0 !important;
    -webkit-transform: none !important;
            transform: none !important;
  }
}
/**! 24. Notifications **/
.notification {
  max-width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: fixed;
  z-index: 99;
  pointer-events: none;
  padding: 0;
  margin: 1em;
  opacity: 0;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.notification:not([class*='bg-']) {
  background: #fff;
}
.notification[class*='col-'] {
  min-width: 400px;
}
.notification .notification-close-cross {
  position: absolute;
  top: 1em;
  z-index: 99;
  right: 1em;
  cursor: pointer;
  transition: 0.1s linear;
  -webkit-transition: 0.1s linear;
  -moz-transition: 0.1s linear;
  opacity: .7;
}
.notification .notification-close-cross:before {
  content: '\D7';
  font-size: 1.5em;
}
.notification .notification-close-cross:hover {
  opacity: 1;
}
.notification.notification--reveal {
  z-index: 99;
  pointer-events: initial;
}
.notification.notification--reveal[data-animation="from-bottom"] {
  animation: from-bottom 0.3s linear 0s forwards;
  -webkit-animation: from-bottom 0.3s linear 0s forwards;
  -moz-animation: from-bottom 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-top"] {
  animation: from-top 0.3s linear 0s forwards;
  -webkit-animation: from-top 0.3s linear 0s forwards;
  -moz-animation: from-top 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-left"] {
  animation: from-left 0.3s linear 0s forwards;
  -webkit-animation: from-left 0.3s linear 0s forwards;
  -moz-animation: from-left 0.3s linear 0s forwards;
}
.notification.notification--reveal[data-animation="from-right"] {
  animation: from-right 0.3s linear 0s forwards;
  -webkit-animation: from-right 0.3s linear 0s forwards;
  -moz-animation: from-right 0.3s linear 0s forwards;
}
.notification.notification--dismissed {
  animation: fade-out 0.4s linear 0s forwards !important;
  -webkit-animation: fade-out 0.4s linear 0s forwards !important;
  -moz-animation: fade-out 0.4s linear 0s forwards !important;
  pointer-events: none;
}
.bg--dark + .notification-close-cross:before {
  color: #fff;
}
a[data-notification-link] {
  text-decoration: none;
}
a[data-notification-link]:hover {
  text-decoration: none;
}
@media all and (max-width: 767px) {
  .notification[class*='col-'] {
    min-width: 0;
  }
}
@keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-bottom {
  from {
    transform: translate3d(0, 100%, 0);
    -webkit-transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-top {
  from {
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-left {
  from {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes from-right {
  from {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/**! 25. Video **/
iframe {
  width: 100%;
  min-height: 350px;
  border: none;
}
@media all and (max-width: 767px) {
  iframe {
    min-height: 220px;
  }
}
.videobg {
  background: #252525;
  position: relative;
  overflow: hidden;
}
.videobg .container,
.videobg .background-image-holder {
  opacity: 1;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.videobg .background-image-holder {
  opacity: 0 !important;
}
.videobg.video-active .container {
  opacity: 1;
}
.videobg.video-active .loading-indicator {
  opacity: 0;
  visibility: hidden;
}
.videobg video {
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  z-index: 0 !important;
  left: 0;
}
@media all and (max-width: 1024px) {
  .videobg .background-image-holder,
  .videobg .container {
    opacity: 1 !important;
  }
  .videobg .loading-indicator {
    display: none;
  }
  .videobg video {
    display: none;
  }
}
.youtube-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 0 !important;
}
.youtube-background .mb_YTPBar {
  opacity: 0;
  height: 0;
  visibility: hidden;
}
@media all and (max-width: 1024px) {
  .youtube-background {
    display: none;
  }
}
.loading-indicator {
  position: absolute !important;
  top: 50%;
  left: 50%;
  z-index: 99 !important;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: loading-spinner 1s infinite ease-in-out;
  animation: loading-spinner 1s infinite ease-in-out;
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
@-webkit-keyframes loading-spinner {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes loading-spinner {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.video-cover {
  position: relative;
}
.video-cover video {
  max-width: 100%;
}
.video-cover iframe {
  background: #252525;
}
.video-cover .background-image-holder {
  z-index: 3;
}
.video-cover .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
}
.video-cover .video-play-icon,
.video-cover .background-image-holder {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
}
.video-cover.reveal-video .video-play-icon,
.video-cover.reveal-video .background-image-holder {
  opacity: 0 !important;
  pointer-events: none;
}
.video-cover[data-scrim-bottom]:before,
.video-cover[data-overlay]:before,
.video-cover[data-scrim-top]:before {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  z-index: 4;
}
.video-cover.reveal-video[data-scrim-bottom]:before,
.video-cover.reveal-video[data-overlay]:before,
.video-cover.reveal-video[data-scrim-top]:before {
  opacity: 0;
  pointer-events: none;
}
.video-play-icon {
  width: 7.42857143em;
  height: 7.42857143em;
  border-radius: 50%;
  position: relative;
  z-index: 4;
  display: inline-block;
  border: 2px solid #ffffff;
  cursor: pointer;
  background: #ffffff;
}
.video-play-icon.video-play-icon--sm {
  width: 3.71428571em;
  height: 3.71428571em;
}
.video-play-icon.video-play-icon--sm:before {
  border-width: 4px 0 4px 9px;
}
.video-play-icon.video-play-icon--xs {
  width: 1.85714286em;
  height: 1.85714286em;
}
.video-play-icon.video-play-icon--xs:before {
  border-width: 3px 0 3px 6px;
  margin-left: -3px;
}
.video-play-icon.bg--primary:before {
  border-color: transparent transparent transparent #fff;
}
.video-play-icon:before {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 50%;
  margin-left: -4px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 12px;
  border-color: transparent transparent transparent #ffffff;
  border-color: transparent transparent transparent #808080;
}
.video-play-icon.video-play-icon--dark {
  border-color: #252525;
  background: #252525;
}
.video-play-icon.video-play-icon--dark:before {
  border-color: transparent transparent transparent #252525;
}
.video-play-icon.video-play-icon--dark:before {
  border-color: transparent transparent transparent #fff;
}
@media all and (max-width: 767px) {
  .video-play-icon {
    width: 4.95238095em;
    height: 4.95238095em;
  }
}
.col-12:not([class*='col-lg']):not([class*='col-md']) .video-cover iframe {
  min-height: 550px;
}
@media all and (max-width: 990px) {
  div[class*='col-'][class*='-12']:not([class*='xs-12']) .video-cover iframe {
    min-height: 350px;
  }
}
div[class*='col-'][class*='-10'] .video-cover iframe {
  min-height: 450px;
}
div[class*='col-'][class*='-8'] .video-cover iframe {
  min-height: 400px;
}
div[class*='col-'][class*='-6'] .video-cover iframe {
  min-height: 350px;
}
@media all and (max-width: 1200px) {
  div[class*='col-'][class*='-6'] .video-cover iframe {
    min-height: 300px;
  }
}
@media all and (max-width: 990px) {
  div[class*='col-'][class*='-6'] .video-cover iframe {
    min-height: 220px;
  }
}
@media all and (max-width: 767px) {
  div[class*='col-'] .video-cover iframe {
    min-height: 220px !important;
  }
}
.modal-container video {
  max-width: 100%;
}
/**! 26. Colors **/
body {
  background: #ffffff;
}
.color--primary {
  color: #4a90e2 !important;
}
a {
  color: #4a90e2;
}
.color--primary-1 {
  color: #31639c !important;
}
.color--primary-2 {
  color: #465773 !important;
}
.color--white {
  color: #fff;
}
.color--dark {
  color: #252525;
}
.color--success {
  color: #4ebf56;
}
.color--error {
  color: #e23636;
}
.bg--dark {
  background: #252525;
}
.bg--dark:not(.nav-bar):not(.bar) {
  color: #ffffff;
}
.bg--dark:not(.nav-bar):not(.bar) h1,
.bg--dark:not(.nav-bar):not(.bar) h2,
.bg--dark:not(.nav-bar):not(.bar) h3,
.bg--dark:not(.nav-bar):not(.bar) h4,
.bg--dark:not(.nav-bar):not(.bar) h5,
.bg--dark:not(.nav-bar):not(.bar) h6,
.bg--dark:not(.nav-bar):not(.bar) i,
.bg--dark:not(.nav-bar):not(.bar) span:not(.btn__text),
.bg--dark:not(.nav-bar):not(.bar) p {
  color: #ffffff;
}
.bg--dark:not(.nav-bar):not(.bar) a:not(.btn) {
  color: #fff;
}
.bg--site {
  background: #ffffff;
}
.bg--secondary {
  background: #fafafa;
}
.bg--primary {
  background: #4a90e2;
}
.bg--primary p,
.bg--primary span,
.bg--primary ul,
.bg--primary a:not(.btn) {
  color: #fff;
}
.bg--primary h1,
.bg--primary h2,
.bg--primary h3,
.bg--primary h4,
.bg--primary h5,
.bg--primary h6,
.bg--primary i {
  color: #fff;
}
.bg--primary .color--primary {
  color: #fff !important;
}
.bg--white {
  background: #fff;
}
.bg--white p,
.bg--white span,
.bg--white ul,
.bg--white a:not(.btn) {
  color: #666666;
}
.bg--white h1,
.bg--white h2,
.bg--white h3,
.bg--white h4,
.bg--white h5,
.bg--white h6,
.bg--white i {
  color: #252525;
}
.bg--error {
  background: #e23636;
}
.bg--success {
  background: #4ebf56;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white span,
.imagebg:not(.image--light) .bg--white ul,
.imagebg:not(.image--light) .bg--white a:not(.btn) {
  color: #666666;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6,
.imagebg:not(.image--light) .bg--white i {
  color: #252525;
}
.imagebg:not(.image--light) .bg--secondary {
  background: rgba(250, 250, 250, 0.2);
}
.bg--primary-1 {
  background: #31639c;
}
.bg--primary-1 p,
.bg--primary-1 span,
.bg--primary-1 ul,
.bg--primary-1 a:not(.btn) {
  color: #fff;
}
.bg--primary-1 h1,
.bg--primary-1 h2,
.bg--primary-1 h3,
.bg--primary-1 h4,
.bg--primary-1 h5,
.bg--primary-1 h6,
.bg--primary-1 i {
  color: #fff;
}
.bg--primary-2 {
  background: #465773;
}
.bg--primary-2 p,
.bg--primary-2 span,
.bg--primary-2 ul,
.bg--primary-2 a:not(.btn) {
  color: #fff;
}
.bg--primary-2 h1,
.bg--primary-2 h2,
.bg--primary-2 h3,
.bg--primary-2 h4,
.bg--primary-2 h5,
.bg--primary-2 h6,
.bg--primary-2 i {
  color: #fff;
}
.image-bg:not(.image-light) *:not(a) {
  color: #fff;
}
.color--facebook {
  color: #3b5998;
}
.color--twitter {
  color: #00aced;
}
.color--googleplus {
  color: #dd4b39;
}
.color--instagram {
  color: #125688;
}
.color--pinterest {
  color: #cb2027;
}
.color--dribbble {
  color: #ea4c89;
}
.color--behance {
  color: #053eff;
}
.bg--facebook {
  background: #3b5998;
  color: #fff;
}
.bg--twitter {
  background: #00aced;
  color: #fff;
}
.bg--googleplus {
  background: #dd4b39;
  color: #fff;
}
.bg--instagram {
  background: #125688;
  color: #fff;
}
.bg--pinterest {
  background: #cb2027;
  color: #fff;
}
.bg--dribbble {
  background: #ea4c89;
  color: #fff;
}
.bg--behance {
  background: #053eff;
  color: #fff;
}
/**! 27. Image Blocks **/
.imageblock {
  position: relative;
  padding: 0;
}
.imageblock > .container,
.imageblock > div[class*='col-']:not(.imageblock__content) {
  padding-top: 7.42857143em;
  padding-bottom: 7.42857143em;
  float: none;
  overflow: hidden;
}
.imageblock.imageblock--lg > .container,
.imageblock.imageblock--lg > div[class*='col-']:not(.imageblock__content) {
  padding-top: 9.28571429em;
  padding-bottom: 9.28571429em;
  float: none;
  overflow: hidden;
}
.imageblock .imageblock__content {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;
  padding: 0;
}
.imageblock .imageblock__content .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.imageblock .imageblock__content .slider .slides > li {
  padding: 0;
  min-height: 100%;
  position: absolute !important;
}
.imageblock.allow-overflow .imageblock__content {
  overflow: visible;
}
@media all and (max-height: 728px) {
  .imageblock > .container,
  .imageblock > div[class*='col-']:not(.imageblock__content) {
    padding-top: 3.71428571em;
    padding-bottom: 3.71428571em;
  }
}
@media all and (max-width: 767px) {
  .imageblock[class*='space-'] {
    padding-bottom: 0;
    padding-top: 0;
  }
  .imageblock .imageblock__content {
    position: relative;
    min-height: 18.57142857em;
  }
  .imageblock > .container,
  .imageblock > div[class*='col-']:not(.imageblock__content) {
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
    float: none;
    overflow: hidden;
  }
  .imageblock.imageblock--lg > .container,
  .imageblock.imageblock--lg > div[class*='col-']:not(.imageblock__content) {
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
    float: none;
    overflow: hidden;
  }
}
/**! 28. MailChimp & Campaign Monitor **/
form[action*='createsend.com'] * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
}
form[action*='createsend.com'].form--active * {
  opacity: 1;
}
form[action*='createsend.com'] .input-checkbox + br {
  display: none;
}
form[action*='createsend.com'].no-labels label {
  display: none;
}
form[action*='createsend.com'] br {
  display: none;
}
form[action*='createsend.com'] p > label:first-child {
  margin-bottom: 0.92857143em;
}
form[action*='list-manage.com'] h2 {
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  color: #252525;
  font-weight: 300;
  -webkit-font-feature-settings: "liga", "clig";
          font-feature-settings: "liga", "clig";
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.35714286em;
  line-height: 1.36842105em;
  margin-bottom: 1.36842105263158em;
  font-weight: 400;
}
form[action*='list-manage.com'] h2.inline-block + .h4.inline-block:not(.typed-text) {
  margin-left: 0.68421052631579em;
}
form[action*='list-manage.com'] .input-group ul {
  overflow: hidden;
}
form[action*='list-manage.com'] .input-group ul li {
  float: left;
}
form[action*='list-manage.com'] * {
  transition: 0.3s linear;
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  opacity: 0;
}
form[action*='list-manage.com'].form--active * {
  opacity: 1;
}
form[action*='list-manage.com'].no-labels label {
  display: none;
}
form[action*='list-manage.com'] .small-meta {
  font-size: 0.5em;
}
/**! 29. Twitter **/
.twitter-feed .user {
  display: none;
}
.twitter-feed .interact {
  display: none;
}
.twitter-feed .timePosted {
  font-size: .87em;
}
/**! 30. Transitions **/
[class*='transition--'] {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  opacity: 0;
}
[class*='transition--'].transition--active {
  opacity: 1;
}
.transition--scale {
  transform: scale(0.98);
  -webkit-transform: scale(0.98);
}
.transition--scale.transition--active {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
}
.transition--slide {
  transform: translate3d(200px, 0, 0);
  -webkit-transform: translate3d(200px, 0, 0);
  transform: translate3d(30vw, 0, 0);
  -webkit-transform: translate3d(30vw, 0, 0);
}
.transition--slide.transition--active {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
/**! 31. Switchable Sections **/
.switchable {
  position: relative;
}
.switchable div[class*='col-']:first-child {
  float: left;
  right: auto;
}
.switchable div[class*='col-']:first-child:not([class*='pull']):not([class*='push']) {
  left: 0;
}
.switchable div[class*='col-']:last-child {
  float: right;
  left: auto;
}
.switchable div[class*='col-']:last-child:not([class*='pull']):not([class*='push']) {
  right: 0;
}
.switchable.switchable--switch div[class*='col-']:first-child {
  float: right;
  right: 0;
  left: auto;
}
.switchable.switchable--switch div[class*='col-']:first-child:not([class*='pull']):not([class*='push']) {
  left: auto;
}
.switchable.switchable--switch div[class*='col-']:last-child {
  float: left;
  left: 0;
  right: auto;
}
.switchable .switchable__text {
  margin-top: 3.71428571em;
}
.switchable > div[class*='col-'] {
  padding: 0;
}
/**! 32. Typed Effect **/
.typed-text {
  display: inline-block;
}
.typed-text.typed-text--cursor:after {
  content: '|';
  font-size: 1.2em;
  -webkit-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
  position: relative;
  right: 6px;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/**! 33. Gradient BG **/
[data-gradient-bg] {
  position: relative;
  background: #252525;
}
[data-gradient-bg] > canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
[data-gradient-bg] > canvas + .background-image-holder {
  opacity: .2 !important;
}
/**! 34. Bars **/
.bar {
  padding: 1.85714286em 0;
}
.bar .logo {
  margin: 0;
  position: relative;
  top: 4px;
}
.bar .menu-horizontal {
  position: relative;
  top: 6px;
}
.bar:not([class*='visible-']) + .bar {
  margin-top: 0.92857143em;
}
.bar.bar--xs {
  padding: 0.46428571em 0;
}
.bar.bar--sm {
  padding: 0.92857143em 0;
}
.bar.bar--lg {
  padding: 2.78571429em 0;
}
.bar.bar--lg .logo {
  top: 0;
}
.bar.bar--xlg {
  padding: 4.64285714em 0;
}
.bar.bar--xlg .logo {
  top: 0;
}
.bar.bg--dark .logo-dark {
  display: none;
}
.bar:not(.bg--dark):not(.bar--transparent) .logo-light {
  display: none;
}
@media all and (max-width: 767px) {
  .bar.bar--mobile-sticky[data-scroll-class*='fixed'].pos-fixed {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
  }
  .bar.bar--mobile-sticky[data-scroll-class*='fixed'] + .bar.pos-fixed {
    top: 3.71428571em;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
  }
}
@media all and (min-width: 991px) {
  .bar__module:not(:only-child) {
    display: inline-block;
  }
  .bar__module:not(:last-child) {
    margin-right: 0.92857143em;
  }
  .bar--transparent:not(.bar--dark) {
    background: none;
  }
  .bar--transparent:not(.bar--dark) .logo-dark {
    display: none;
  }
  .bar--transparent:not(.bar--dark) .logo-light {
    display: inline-block;
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .menu-horizontal > li > a,
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .menu-horizontal > li > span {
    color: #fff;
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']) {
    border-color: rgba(255, 255, 255, 0.3);
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']) .btn__text {
    color: #fff;
  }
  .bar--transparent:not(.bar--dark):not(.pos-fixed) .btn:not([class*='primary']):hover {
    border-color: rgba(255, 255, 255, 0.7);
  }
  .bar--absolute {
    position: absolute;
  }
  .bar--absolute,
  .pos-fixed {
    z-index: 99;
    width: 100%;
  }
  .bar.pos-fixed {
    position: fixed;
    top: 0;
    -webkit-animation: fadeInDown 0.3s ease-out forwards;
            animation: fadeInDown 0.3s ease-out forwards;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  }
  .bar.pos-fixed:not([class*='bg-']) {
    background: #ffffff;
  }
  .bar.pos-fixed:not([class*='bg-']) .logo-dark {
    display: inline-block;
  }
  .bar.pos-fixed:not([class*='bg-']) .logo-light {
    display: none;
  }
  .bar.pos-fixed.bg--dark {
    background: #252525;
  }
}
@media all and (max-width: 767px) {
  .bar__module {
    margin-bottom: 0.92857143em;
  }
  .bar__module + .bar__module {
    margin-top: 1.85714286em;
  }
  .bar__module .btn {
    display: block;
  }
  .bar__module .btn + .btn {
    margin-left: 0 !important;
  }
  .bar__module .btn:not(:last-child) {
    margin-bottom: 0.92857143em;
  }
  .bar + nav.bar {
    padding-top: 0;
  }
}
/**! 35. Navigation InPage **/
.page-navigator {
  position: fixed;
  padding: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 1.85714286em;
  z-index: 10;
}
.page-navigator ul {
  display: inline-block;
  padding: 0.92857143em;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 1.85714286em;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}
.page-navigator ul:hover {
  background: rgba(0, 0, 0, 0.6);
}
.page-navigator ul li:not(:last-child) {
  margin-bottom: 1.85714286em;
}
@media all and (max-width: 767px) {
  .page-navigator {
    right: 0;
  }
  .page-navigator ul {
    border-radius: 1.85714286em 0 0 1.85714286em;
    padding: 1.85714286em 0.92857143em;
  }
}
.page-navigator li a {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  display: block;
  position: relative;
}
.page-navigator li a:not(:hover) {
  opacity: .5;
}
.page-navigator li a.inner-link--active {
  opacity: 1;
  animation: bulge .5s ease;
  -webkit-animation: bulge .5s ease;
}
@keyframes bulge {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes bulge {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.page-navigator li a[data-title]:before {
  content: attr(data-title);
  position: absolute;
  right: 12px;
  top: -14px;
  background: #222;
  color: #fff;
  border-radius: 6px;
  padding: 4px 8px;
  display: inline-block;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  white-space: nowrap;
}
.page-navigator li a[data-title]:not(:hover):before {
  opacity: 0;
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
}
/**! 36. Helper Classes **/
.clearfix {
  overflow: hidden;
}
.clearfix-after:after {
  content: "";
  display: table;
  clear: both;
}
.allow-overflow {
  overflow: visible;
}
.container .row--gapless {
  padding-left: 15px;
  padding-right: 15px;
}
.container .row--gapless > div[class*='col-'] {
  padding: 0;
}
@media all and (max-width: 767px) {
  .text-left-xs {
    text-align: left;
  }
}
@media all and (max-width: 991px) {
  .text-left-sm {
    text-align: left;
  }
}
section > .row--gapless {
  padding-left: 0;
  padding-right: 0;
}
section > .row--gapless > div[class*='col-'] {
  padding: 0;
}
div.right {
  float: right;
}
div.left {
  float: left;
}
section.text-right > .container:last-child > .row:only-child > div[class*='col-']:only-child {
  float: right;
}
/**! 37. Spacing **/
section,
footer {
  padding-top: 7.42857143em;
  padding-bottom: 7.42857143em;
}
section.space--xxs,
footer.space--xxs {
  padding-top: 1.85714286em;
  padding-bottom: 1.85714286em;
}
section.space--xs,
footer.space--xs {
  padding-top: 3.71428571em;
  padding-bottom: 3.71428571em;
}
section.space--sm,
footer.space--sm {
  padding-top: 4.95238095em;
  padding-bottom: 4.95238095em;
}
section.space--md,
footer.space--md {
  padding-top: 11.14285714em;
  padding-bottom: 11.14285714em;
}
section.space--lg,
footer.space--lg {
  padding-top: 14.85714286em;
  padding-bottom: 14.85714286em;
}
section.space--xlg,
footer.space--xlg {
  padding-top: 29.71428571em;
  padding-bottom: 29.71428571em;
}
section.space--0,
footer.space--0 {
  padding: 0;
}
section.section--even,
footer.section--even {
  padding-top: 7.42857143em;
  padding-bottom: 7.42857143em;
}
section.space-bottom--sm,
footer.space-bottom--sm {
  padding-bottom: 4.95238095em;
}
@media all and (max-width: 767px) {
  section,
  footer,
  section.section--even {
    padding: 5.57142857em 0;
  }
  section.space--lg,
  footer.space--lg,
  section.section--even.space--lg,
  section.space--md,
  footer.space--md,
  section.section--even.space--md {
    padding: 5.57142857em 0;
  }
  section.space--xlg,
  footer.space--xlg,
  section.section--even.space--xlg {
    padding: 8.35714286em 0;
  }
}
div[class*='col-'] > div[class*='col-']:first-child {
  padding-left: 0;
}
div[class*='col-'] > div[class*='col-']:last-child {
  padding-right: 0;
}
@media all and (max-width: 767px) {
  .col-xs-6:nth-child(odd) {
    padding-right: 7.5px;
  }
  .col-xs-6:nth-child(even) {
    padding-left: 7.5px;
  }
}
@media all and (min-width: 768px) {
  .mt--1 {
    margin-top: 1.85714286em;
  }
  .mt--2 {
    margin-top: 3.71428571em;
  }
  .mt--3 {
    margin-top: 5.57142857em;
  }
  .mb--1 {
    margin-bottom: 1.85714286em;
  }
  .mb--2 {
    margin-bottom: 3.71428571em;
  }
  .mb--3 {
    margin-bottom: 5.57142857em;
  }
}
@media all and (max-width: 990px) {
  .mt--1,
  .mt--2 {
    margin-top: 1.85714286em;
  }
  .mt--3 {
    margin-top: 2.78571429em;
  }
}
.unpad {
  padding: 0;
}
.unpad--bottom {
  padding-bottom: 0;
}
.unpad--top {
  padding-top: 0;
}
section.unpad--bottom {
  padding-bottom: 0;
}
section.unpad {
  padding: 0;
}
section.unpad--top {
  padding-top: 0;
}
.unmarg--bottom {
  margin-bottom: 0;
}
.unmarg {
  margin: 0;
}
.unmarg--top {
  margin-top: 0;
}
/**! 38. Boxed Layout **/
@media all and (min-width: 1280px) {
  body.boxed-layout {
    padding: 3.71428571em 0;
    background: #ededed;
  }
  body.boxed-layout section:not([class*='bg-']):not(.imagebg),
  body.boxed-layout footer:not([class*='bg-']):not(.imagebg),
  body.boxed-layout nav:not([class*='bg-']):not(.bar--transparent):not(.bar--absolute),
  body.boxed-layout .tabs-container:not([class*='bg-']):not(.imagebg) {
    background: #ffffff;
  }
  body.boxed-layout .nav-container,
  body.boxed-layout .main-container,
  body.boxed-layout > section,
  body.boxed-layout nav {
    max-width: 1280px;
    margin: 0 auto;
  }
}
/**! 39. Wizard **/
.wizard__body {
  list-style: none;
}
.wizard__step:not(.active) {
  display: none;
}
.wizard__step .wizard__title {
  display: none;
}
.wizard__controls {
  overflow: hidden;
}
.wizard__controls .wizard-prev {
  float: left;
}
.wizard__controls .wizard-next {
  float: right;
}
.wizard__controls .wizard-prev:hover,
.wizard__controls .wizard-next:hover {
  -webkit-transform: none !important;
          transform: none !important;
}
.wizard__controls .wizard-prev.inactive,
.wizard__controls .wizard-next.inactive {
  pointer-events: none;
  opacity: .3;
  cursor: not-allowed;
}
/**! 40. Alerts **/
.alert {
  overflow: hidden;
  border: 1px solid #e6e6e6;
  padding: 0.92857143em;
}
.alert .alert__body,
.alert .alert__close {
  display: inline-block;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.alert .alert__body {
  float: left;
}
.alert .alert__close {
  float: right;
  cursor: pointer;
}
.alert.bg--error {
  background: #fce8e8;
  border-color: #e23636;
}
.alert.bg--error .alert__close {
  color: #e23636;
}
.alert.bg--success {
  background: #e4f5e5;
  border-color: #4ebf56;
}
.alert.bg--success .alert__close {
  color: #4ebf56;
}
.alert.bg--primary {
  background: #fafcfe;
  border-color: #4a90e2;
}
.alert.bg--primary .alert__body > span {
  color: #666666;
}
.alert.bg--primary .alert__close {
  color: #4a90e2;
}
.alert.alert--dismissed {
  display: none;
}
/**! 41. Progress - Horizontal **/
.progress-horizontal:after {
  content: "";
  display: table;
  clear: both;
}
.progress-horizontal .progress-horizontal__bar {
  position: relative;
  overflow: hidden;
}
.progress-horizontal .progress-horizontal__progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
/**! 42. Theme Overrides **/
/*! -- Stack Customizers -- */
.box-shadow {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.box-shadow-shallow {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
}
.box-shadow-wide {
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.border--round {
  border-radius: 6px;
}
.border--round:before {
  border-radius: 6px;
}
.border--round .background-image-holder {
  border-radius: 6px;
}
.border--round [data-scrim-top]:before,
.border--round [data-scrim-bottom]:before,
.border--round [data-overlay]:before {
  border-radius: 6px;
}
.imageblock.border--round .background-image-holder {
  border-radius: 6px 0 0 6px;
}
@media all and (max-width: 767px) {
  .imageblock.border--round .background-image-holder {
    border-radius: 6px 6px 0 0;
  }
}
.theme--square .border--round,
.theme--square .btn {
  border-radius: 0px;
}
.theme--bordered {
  border: 0.92857143em solid #252525;
}
.main-container.transition--fade:not(.transition--active) {
  cursor: wait;
}
@media all and (min-width: 1280px) {
  body.boxed-layout > section.bar-3:first-of-type {
    border-radius: 6px 6px 0 0;
  }
  body.boxed-layout .main-container > footer:last-child {
    border-radius: 0 0 6px 6px;
  }
}
body.boxed-layout .modal-container section:not([class*='bg-']) {
  background: none;
}
/*! -- Stack Helpers -- */
@media all and (max-width: 767px) {
  .block--xs {
    margin-top: 0.92857143em;
  }
}
.container .container {
  max-width: 100%;
}
.switchable-toggle {
  cursor: pointer;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}
.back-to-top {
  position: fixed;
  width: 3.71428571em;
  height: 3.71428571em;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  right: 1.85714286em;
  bottom: 3.71428571em;
  padding-top: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  z-index: 99;
  border: 1px solid #ececec;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
}
.back-to-top i {
  color: #252525;
}
.back-to-top:not(.active) {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  -webkit-transform: translate3d(0, 20px, 0);
  pointer-events: none;
}
.back-to-top.active:hover {
  transform: translate3d(0, -5px, 0);
  -webkit-transform: translate3d(0, -5px, 0);
}
.disable-scroll-bars {
  -ms-overflow-style: none;
}
.disable-scroll-bars::-webkit-scrollbar {
  display: none;
}
/*! -- Stack Animations -- */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    -webkit-transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
    -webkit-transform: translate3d(0, -100px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100px, 0);
    -webkit-transform: translate3d(0, -100px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
    -webkit-transform: translate3d(0, -50px, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
    -webkit-transform: translate3d(0, -50px, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
    -webkit-transform: translate3d(50px, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
    -webkit-transform: translate3d(50px, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
    -webkit-transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
    -webkit-transform: translate3d(-50px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
  }
}
@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2);
    -webkit-transform: scale(2);
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2);
    -webkit-transform: scale(2);
  }
}
@keyframes kenBurns {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
@-webkit-keyframes kenBurns {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
/*! -- Stack Sections -- */
section:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom):not(.space--xxs) + section:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.border--bottom):not(.space--xxs) {
  padding-top: 0;
}
section:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom) + footer:not(.unpad):not(.imagebg):not([class*='bg--']):not(.imageblock):not(.unpad--bottom):not(.border--bottom) {
  padding-top: 0;
}
section:not(.imagebg):not([class*='bg-']) + section.bg--secondary {
  border-top: 1px solid #ebebeb;
}
section.bg--secondary:not(.imagebg) + section:not(.imagebg):not([class*='bg-']) {
  border-top: 1px solid #ebebeb;
}
section.bg--secondary:not(.unpad):not(.imageblock):not(.unpad--bottom):not(.border--bottom) + section.bg--secondary {
  padding-top: 0;
}
section.bg--secondary + footer:not(.bg--dark):not(.bg--secondary) {
  border-top: 1px solid #ebebeb;
}
section.bg--dark + section.bg--dark {
  padding-top: 0;
}
section.bg--dark:last-of-type + footer.bg--dark {
  background: #1b1b1b;
}
section.border--bottom:not([data-gradient-bg]) {
  border-bottom: 1px solid #ececec;
}
section.unpad {
  overflow: hidden;
}
section:not([class*='bg--']) + footer.bg--secondary {
  border-top: 1px solid #ebebeb;
}
section.text-center div[class*='col-']:first-child:last-child {
  margin: 0 auto;
  float: none;
}
.section--overlap {
  z-index: 2;
  position: relative;
}
/*! -- Stack Typography -- */
@media all and (max-width: 1024px) {
  html {
    font-size: 80%;
  }
}
h1,
.h1 {
  letter-spacing: -0.01em;
}
h1:not(:last-child),
.h1:not(:last-child) {
  margin-bottom: 0.59090909090909em;
}
@media all and (min-width: 768px) {
  h1.h1--large,
  .h1.h1--large {
    font-weight: 200;
    font-size: 4.428571428571429em;
    line-height: 1.048387096774194em;
  }
  h1.h1--large:not(:last-child),
  .h1.h1--large:not(:last-child) {
    margin-bottom: 0.419354838709677em;
  }
  h1.h1--large.type--uppercase,
  .h1.h1--large.type--uppercase {
    letter-spacing: 10px;
    margin-right: -10px;
  }
  h1.h1--large + p.lead,
  .h1.h1--large + p.lead {
    margin-top: 2.052631578947368em;
  }
}
h2,
.h2 {
  margin-bottom: 0.78787878787879em;
}
h3,
.h3 {
  margin-bottom: 1.04em;
}
h3 strong,
.h3 strong {
  font-weight: 400;
}
blockquote {
  font-family: 'Merriweather', serif;
  font-style: italic;
  font-weight: 300;
}
blockquote:not(:last-child) {
  margin-bottom: 1.04em;
}
blockquote > p {
  font-size: 1em !important;
}
h4,
.h4 {
  margin-bottom: 1.36842105263158em;
  font-weight: 400;
}
h4.inline-block + .h4.inline-block:not(.typed-text),
.h4.inline-block + .h4.inline-block:not(.typed-text) {
  margin-left: 0.68421052631579em;
}
h5,
.h5 {
  font-weight: 600;
}
h5:not(:last-child),
.h5:not(:last-child) {
  margin-bottom: 1.85714286em;
}
h6,
.h6 {
  font-weight: 700;
}
h6:not(:last-child),
.h6:not(:last-child) {
  margin-bottom: 2.16666666666667em;
}
h6.type--uppercase,
.h6.type--uppercase {
  letter-spacing: 1px;
  margin-right: -1px;
}
span.h1:not(.inline-block),
span.h2:not(.inline-block),
span.h3:not(.inline-block),
span.h4:not(.inline-block),
span.h5:not(.inline-block),
span.h6:not(.inline-block) {
  display: block;
}
b {
  font-weight: 600;
}
hr {
  border-color: #ECECEC;
}
.bg--dark hr {
  border-color: #585858;
}
[class*='bg-']:not(.bg--white):not(.bg--secondary) p,
[class*='imagebg']:not(.image--light) p {
  opacity: .9;
}
.lead {
  font-weight: 400;
  color: #808080;
}
.lead:not(:last-child) {
  margin-bottom: 1.36842105263158em;
}
.lead + .btn:last-child {
  margin-top: 0.92857143em;
}
p:last-child {
  margin-bottom: 0;
}
p strong {
  color: #252525;
}
pre {
  padding: 0.92857143em;
  background: #fafafa;
  border: 1px solid #ececec;
  border-radius: 6px;
  line-height: 20px;
  max-height: 500px;
}
.bg--secondary > pre {
  background: #f5f5f5;
  border-color: #ddd;
}
.text-block {
  margin-bottom: 1.85714286em;
}
.text-block h2,
.text-block .h2 {
  margin-bottom: 0.3939393939394em;
}
.text-block h5,
.text-block .h5 {
  margin: 0;
}
.text-block h4:not(:last-child),
.text-block .h4:not(:last-child) {
  margin-bottom: 0.3421052631579em;
}
.text-block h3,
.text-block .h3 {
  margin-bottom: 0.52em;
}
@media all and (min-width: 768px) {
  div[class*='col-'] .text-block + .text-block {
    margin-top: 3.71428571em;
  }
}
.heading-block {
  margin-bottom: 3.71428571em;
}
.heading-block h1,
.heading-block h2,
.heading-block h3,
.heading-block h4,
.heading-block h5,
.heading-block h6,
.heading-block .h1,
.heading-block .h2,
.heading-block .h3,
.heading-block .h4,
.heading-block .h5,
.heading-block .h6 {
  margin-bottom: 0;
}
/*! -- Stack Colours -- */
.bg--dark .bg--secondary {
  background: #343434;
}
/*! -- Stack Links -- */
a {
  color: #4a90e2;
  font-weight: 700;
}
a:hover {
  color: #2275d7;
  text-decoration: underline;
}
a.block {
  font-weight: normal;
  text-decoration: none;
  color: #666666;
}
p a,
span a,
label a {
  font-size: 1em;
  text-decoration: underline;
  font-weight: 400;
  line-height: 1.85714286em;
}
p + a:not(.btn) {
  font-size: 0.85714286em;
  line-height: 2.16666667em;
}
.imagebg:not(.image--light) a {
  color: #fff;
  font-weight: 600;
}
/*! -- Stack Tables -- */
table {
  width: 100%;
  border-collapse: separate;
}
table th,
table td {
  padding: 0.92857143em;
}
table th {
  background: #fafafa;
  color: #252525;
}
table tr:not(:last-of-type) {
  border-bottom: 1px solid #ececec;
}
table.border--round {
  border-radius: 6px;
  border: 1px solid #ececec;
}
.table--alternate-column th:nth-child(odd) {
  background: none;
}
.table--alternate-column td:nth-child(even) {
  background: #fafafa;
}
.table--alternate-row tbody tr:nth-child(even) {
  background: #fafafa;
}
.bg--dark table.border--round {
  border-radius: 6px;
  border: 1px solid #3f3f3f;
}
.bg--dark table th {
  background: #3f3f3f;
  color: #fff;
}
.bg--dark .table--alternate-row tbody tr:nth-child(even) {
  background: #323232;
}
/*! -- Stack Lists -- */
ul:not([class*='menu']) li > a {
  font-weight: normal;
}
ul:not([class*='menu']) li > a:hover {
  text-decoration: none;
}
ol {
  list-style-position: outside;
  list-style-type: decimal;
}
ol li:not(:last-child) {
  margin-bottom: 1.85714286em;
}
ol.lead li:not(:last-child) {
  margin-bottom: 1.26315789473684em;
}
.list-inline {
  margin-left: 0;
  display: inline-block;
}
.list-inline li {
  display: inline-block;
  padding: 0;
}
.list-inline li:not(:last-child) {
  margin-right: 1.85714286em;
}
.list-inline:not(:last-child) {
  margin-right: 1.85714286em;
}
.list-inline--images img {
  max-height: 2.78571429em;
}
@media all and (min-width: 768px) {
  .list-inline--images li:not(:last-child) {
    margin-right: 5.57142857em;
  }
}
@media all and (max-width: 767px) {
  .list-inline--images li:not(:last-child) {
    margin-bottom: 1.85714286em;
  }
}
.list--loose > li:not(:last-child) {
  margin-bottom: 0.46428571em;
}
.list--hover li {
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
}
.list--hover li:not(:hover) {
  opacity: .6;
}
.social-list a {
  color: #252525;
}
.imagebg:not(.image--light) .social-list a {
  color: #fff;
}
.results-list > li > a:first-child {
  display: flex;
  align-items: center;
  margin-bottom: 0.92857143em;
}
.results-list > li > a:first-child span {
  display: inline-block;
  margin-left: 0.46428571em;
}
.results-list > li > a:first-child h4 {
  display: inline-block;
  margin-bottom: 0;
}
.results-list > li > a:first-child:hover h4,
.results-list > li > a:first-child:hover span {
  text-decoration: underline;
}
.results-list > li:not(:last-child) {
  margin-bottom: 1.85714286em;
  padding-bottom: 1.85714286em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media all and (max-width: 767px) {
  .list-inline:not(.social-list):not(.list-inline--images) {
    display: block;
  }
  .list-inline:not(.social-list):not(.list-inline--images) li {
    display: block;
    margin: 0;
  }
  .list-inline:not(.social-list):not(.list-inline--images) li:not(:last-child) {
    margin-bottom: 0.46428571em;
  }
}
@media all and (max-width: 767px) {
  .list-inline {
    min-width: 100%;
  }
}
.row--list span.h6 {
  margin-bottom: 0;
}
.row--list span.h3:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 767px) {
  .row--list > li {
    margin-bottom: 0.92857143em;
  }
}
/*! -- Stack Rules -- */
hr:first-child {
  margin-top: 0;
}
hr.short {
  width: 2.78571429em;
  border-color: #4a90e2;
}
hr[data-title] {
  margin: 2.78571429em 0;
  text-align: center;
}
hr[data-title]:before {
  content: attr(data-title);
  background: #ffffff;
  position: relative;
  bottom: 14px;
  padding: 0.92857143em;
  font-style: italic;
}
.bg--dark hr:not(.short),
.imagebg hr:not(.short),
.bg--primary hr:not(.short) {
  opacity: .3;
}
/*! -- Stack Buttons -- */
.btn {
  position: relative;
}
.btn:not([class*='primary']) {
  border-color: #d3d3d3;
}
.btn:not([class*='primary']):hover {
  border-color: #252525;
}
.btn.type--uppercase {
  letter-spacing: .5px;
}
.btn.type--uppercase .btn__text {
  letter-spacing: .5px;
  margin-right: -0.5px;
}
.btn .label {
  top: -0.92857143em;
  right: -3.71428571em;
}
.btn.btn--lg .btn__text {
  font-weight: 600;
}
.btn.btn--lg.type--uppercase .btn__text {
  letter-spacing: 1px;
}
.btn + p.type--fine-print,
.btn + span.type--fine-print {
  margin-top: 1.08333333333334em;
}
.btn.block {
  margin-left: 0;
}
.btn.block + .btn.block {
  margin-top: 0.92857143em;
}
.btn:hover {
  transform: translate3d(0, -2px, 0);
  -webkit-transform: translate3d(0, -2px, 0);
}
.btn.btn--sm + .btn--sm {
  margin-left: 0.92857143em;
}
p > .btn {
  text-decoration: none;
}
@media all and (max-width: 767px) {
  .btn:not(:last-child) {
    margin-bottom: 1.85714286em;
  }
}
.btn--icon {
  padding-left: 5.57142857em;
}
.btn--icon i {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  padding: 0 13px;
  border-radius: 6px 0 0 6px;
  font-size: 16px;
  line-height: 41px;
}
.btn--icon i.socicon {
  line-height: 42px;
}
.btn--cart {
  width: 3.71428571em;
  height: 3.71428571em;
  border: 2px solid #252525;
  display: block;
  text-align: center;
  border-radius: 6px;
  opacity: .4;
  padding: 0;
}
.btn--cart:hover {
  opacity: 1;
}
.btn--cart .btn__text {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 1.142857142857143em;
}
.imagebg:not(.image--light) .btn--icon:not([class*='bg']):not([class*='primary']) i {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}
@media all and (min-width: 768px) {
  .btn-group .btn {
    margin-bottom: 0 !important;
    margin-left: 0;
  }
  .btn-group .btn + .btn {
    margin-left: 0;
  }
  .btn-group .btn:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .btn-group .btn:first-child {
    border-radius: 6px 0 0 6px;
  }
  .btn-group .btn:last-child {
    border-radius: 0 6px 6px 0;
  }
  .btn-group .btn:first-child:nth-last-child(2),
  .btn-group .btn:last-child:nth-child(2) {
    width: 50%;
  }
}
.btn-group {
  border: none;
  padding: 0;
}
@media all and (max-width: 767px) {
  .btn-group .btn {
    display: block;
  }
}
.form-submitted {
  margin-top: 150px;
  margin-bottom: 20px;
  font-size: 32px;
}
.btn--primary.disabled {
  background: gray;
  border-color: #fff;
  pointer-events: none;
}
.bg--primary div:not([class*='feature']) .btn--primary {
  background: #fff;
  border-color: #fff;
}
.bg--primary div:not([class*='feature']) .btn--primary .btn__text {
  color: #4a90e2;
}
.bg--primary > .btn--primary {
  background: #fff;
  border-color: #fff;
}
.bg--primary > .btn--primary .btn__text {
  color: #4a90e2;
}
.bg--dark .btn:not(.btn--primary) {
  border-color: #3f3f3f;
}
.bg--dark .btn:not(.btn--primary):hover {
  border-color: #656565;
}
.bg--dark .btn .btn__text {
  color: #fff;
}
.imagebg:not(.image--light) .btn:not([class*='primary']) {
  border-color: rgba(255, 255, 255, 0.3);
}
.imagebg:not(.image--light) .btn:not([class*='primary']):hover {
  border-color: #fff;
}
.imagebg.image--light .btn:not([class*='primary']) {
  border-color: #252525;
  background: rgba(255, 255, 255, 0.3);
}
.imagebg.image--light .btn:not([class*='primary']):hover {
  background: rgba(255, 255, 255, 0.5);
}
h1 + .btn,
.h1 + .btn {
  margin-top: 1.85714286em;
}
h2 + .btn,
.h2 + .btn {
  margin-top: 0.92857143em;
}
/*! -- Stack Images -- */
img:last-child {
  margin-bottom: 0;
}
img.flag {
  max-height: 1.85714286em;
}
img.image--sm:not(:last-child) {
  margin-bottom: 0.92857143em;
}
img.promo.border--round {
  border: 1px solid #ececec;
}
p.lead img {
  max-height: 1.68421053em;
}
.imagebg h1,
.imagebg h2,
.imagebg h3,
.imagebg h4,
.imagebg h5,
.imagebg h6 {
  position: relative;
}
.imagebg:not(.image--light) span {
  color: #fff;
}
.imagebg.border--round {
  overflow: hidden;
}
section.parallax .row .background-image-holder {
  -webkit-transform: none !important;
          transform: none !important;
  top: 0 !important;
}
.triptych.border--round img {
  position: relative;
  border-radius: 6px;
  width: 33.333333%;
  float: left;
  margin: 0;
}
.triptych.border--round img:nth-child(2) {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  z-index: 2;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.gallery > div[class*='col-'] {
  margin-bottom: 30px;
}
.gallery-1 {
  overflow: hidden;
  position: relative;
}
.gallery-1 .gallery__image:not(:last-child) {
  margin-bottom: 30px;
}
.gallery-1 > div[class*='col-']:first-child > .gallery__image {
  height: 800px;
}
.gallery-1 > div[class*='col-']:last-child > .gallery__image {
  height: calc(385px);
}
.gallery__image {
  position: relative;
  overflow: hidden;
}
@media all and (max-width: 767px) {
  .gallery-1 .gallery__image {
    max-height: 300px;
    margin-bottom: 15px;
  }
  .gallery-1 .gallery__image:not(:last-child) {
    margin-bottom: 15px;
  }
}
.section--ken-burns {
  overflow: hidden;
}
.section--ken-burns > .background-image-holder,
.section--ken-burns > img:only-child {
  animation: kenBurns 15s ease alternate infinite;
  -webkit-animation: kenBurns 15s ease alternate infinite;
}
/*! -- Stack Titles -- */
.breadcrumbs {
  list-style: none;
}
.breadcrumbs li {
  font-size: 0.85714285714286em;
  display: inline-block;
}
.breadcrumbs li:not(:last-child) {
  margin-right: 1.08333333333334em;
}
.breadcrumbs li:not(:last-child):after {
  content: '\BB';
  margin-left: 1.08333333333334em;
}
.breadcrumbs li a {
  font-weight: normal;
}
.imagebg .breadcrumbs,
.bg--primary .breadcrumbs {
  color: #fff;
}
.imagebg .breadcrumbs a,
.bg--primary .breadcrumbs a {
  color: #fff;
  font-weight: 600;
}
.elements-title {
  border-top: none !important;
}
.elements-title + .tabs-container:not( :nth-last-child(2)),
.elements-title + section:not( :nth-last-child(2)) {
  margin-bottom: 9.28571429em;
}
.elements-title + section:not(.imagebg):not([class*='bg-']):not(.unpad) {
  padding-top: 1.85714286em;
}
/*! -- Stack Labels -- */
.label {
  display: inline-block;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: .5px;
  color: #fff;
  text-transform: uppercase;
  height: 26px;
  min-width: 65px;
  padding: 0 10px;
  text-align: center;
  border-radius: 50px;
  position: absolute;
  z-index: 3;
  top: 1.23809524em;
  right: 1.23809524em;
}
.label:not([class*='bg--']) {
  background: #31639c;
}
.label.label--inline {
  position: relative;
  top: 0;
  right: 0;
}
.label.label--inline:not(:last-child) {
  margin-right: 0.92857143em;
}
.label.label--inline + span,
.label.label--inline + p {
  position: relative;
  top: 1px;
}
.label.switchable-toggle {
  left: 50%;
  right: auto;
  transform: translate3d(-50%, 0, 0);
  -webkit-transform: translate3d(-50%, 0, 0);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
}
.bg--primary-1 .label:not([class*='bg--']) {
  background: #465773;
}
/*! -- Stack Bars -- */
.menu-horizontal > li,
.menu-vertical > li {
  font-family: 'Open Sans', 'Roboto', 'Helvetica', Sans-Serif;
}
.bar .logo {
  max-height: 2.5em;
  max-width: none;
}
.menu-horizontal > li > a,
.menu-horizontal > li > .modal-instance > a {
  font-weight: 500;
}
.menu-horizontal > li a:hover {
  text-decoration: none;
}
.hamburger-toggle i {
  color: #252525;
}
@media all and (min-width: 990px) {
  .menu-horizontal li:not(:last-child) {
    margin-right: 1.23809524em;
  }
  .bar__module:not(:only-child) .menu-horizontal {
    top: 0;
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .bar-2 .menu-horizontal > li,
  .bar-1 .menu-horizontal > li {
    display: inline-block;
  }
  .bar-2 .menu-horizontal > li:not(:first-child),
  .bar-1 .menu-horizontal > li:not(:first-child) {
    margin-left: 0.92857143em;
  }
  .bar-2 .bar__module,
  .bar-1 .bar__module {
    margin-bottom: 1.85714286em;
  }
  .bar-2 .row div[class*='col-']:last-child .bar__module:last-child,
  .bar-1 .row div[class*='col-']:last-child .bar__module:last-child {
    margin-bottom: 0;
  }
}
.bar-1 .menu-horizontal > li > .dropdown__trigger,
.bar-1 .menu-horizontal > li > a {
  font-size: 0.857142857142857em;
  line-height: 2.166666666666667em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .5px;
}
@media all and (min-width: 1024px) {
  .bar-1 .bar__module + .bar__module {
    margin-left: 1.85714286em;
  }
}
@media all and (min-width: 991px) {
  .bar-2 .logo {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.bar-3 {
  font-size: 0.85714286em;
}
.bar-3 .menu-horizontal {
  top: 0;
}
@media all and (max-width: 990px) {
  .bar-3 .menu-horizontal li {
    display: inline-block;
  }
  .bar-3 .menu-horizontal li:not(:last-child) {
    margin-right: 0.92857143em;
  }
}
@media all and (min-width: 990px) {
  .bar-toggle .col-md-1 .logo {
    top: 10px;
  }
}
@media all and (max-width: 767px) {
  .bar-4 .logo-light {
    display: none;
  }
  .bar-4 .logo-dark {
    margin-bottom: 1.85714286em;
  }
  .bar.bg--dark {
    background: #252525;
  }
  .bar.bg--dark .hamburger-toggle i {
    color: #fff;
  }
}
/*! -- Stack Utilties -- */
.hidden {
  display: none !important;
  visibility: hidden !important;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
@media all and (min-width: 768px) and (max-width: 990px) {
  .text-left-sm {
    text-align: left;
  }
  .text-right-sm {
    text-align: right;
  }
  .text-center-sm {
    text-align: center;
  }
}
@media all and (max-width: 767px) {
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
  .text-center-xs {
    text-align: center;
  }
}
/*! -- Stack Alerts -- */
.alert {
  border-radius: 6px;
}
.alert:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.alert .alert__close {
  font-size: 1.35714286em;
}
/*! -- Stack Menu Toggle -- */
.menu-toggle {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  width: 3.71428571em;
  height: 3.71428571em;
  border-radius: 50%;
  text-align: center;
  background: #fff;
  display: inline-block;
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
}
.menu-toggle i {
  font-size: 1.78571429em;
  position: relative;
  top: 13px;
  color: #252525;
}
.menu-toggle:hover {
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  transform: translate3d(0, -2px, 0);
  -webkit-transform: translate3d(0, -2px, 0);
}
/*! -- Stack Nav Utility -- */
.nav-utility {
  padding: 0.92857143em 0;
}
.nav-utility .nav-utility__module {
  font-size: 0.85714285714286em;
}
.nav-utility .nav-utility__module a {
  color: #666666;
  font-weight: normal;
}
.nav-utility .nav-utility__module a i {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  display: inline-block;
}
.nav-utility .nav-utility__module a:hover i {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
}
.nav-utility .nav-utility__module.right:not(:last-child) {
  margin-left: 2.16666666666667em;
}
.utility-toggle {
  display: inline-block;
  cursor: pointer;
  width: 3px;
  height: 3px;
  position: relative;
  bottom: 8px;
  border-radius: 50%;
  background: #252525;
}
.utility-toggle:before,
.utility-toggle:after {
  content: '';
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  background: #252525;
}
.utility-toggle:before {
  top: 6px;
}
.utility-toggle:after {
  top: 12px;
}
/*! -- Stack Nav Stacked -- */
.bar-stacked .logo {
  margin-bottom: 3.71428571em;
}
/*! -- Stack Nav Side Menu -- */
.notification.side-menu {
  z-index: 9999;
  background: #ffffff;
  padding-top: 1.85714286em;
  margin: 0;
  height: 100%;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  width: 27.85714286em;
}
.notification.side-menu .side-menu__module {
  overflow: hidden;
  padding: 2.78571429em;
}
.notification.side-menu .side-menu__module + hr:not(:last-child) {
  margin: 0;
}
.notification.side-menu .menu-vertical li a {
  font-size: 1.35714286em;
  line-height: 1.36842105em;
}
.notification.side-menu .menu-vertical li a:hover {
  text-decoration: none;
}
.notification.side-menu .btn:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.notification.side-menu .btn + ul.list--loose {
  margin-top: 0.92857143em;
}
.notification.side-menu .notification-close-cross {
  top: 0.92857143em;
  right: 2.78571429em;
  margin-top: 8px;
}
.notification.side-menu .social-list:not(:first-child) {
  margin-top: 1.85714286em;
}
.menu-toggle.pos-fixed {
  position: fixed;
  right: 5.57142857em;
  -webkit-animation: fadeInDown .3s ease forwards;
          animation: fadeInDown .3s ease forwards;
}
@media all and (min-width: 768px) {
  .side-menu .side-menu__module span.type--fine-print {
    position: relative;
    top: 8px;
  }
}
@media all and (max-width: 767px) {
  .side-menu {
    width: 100%;
  }
  .side-menu .side-menu__module .float-right,
  .side-menu .side-menu__module .float-left {
    display: block;
  }
}
/*! -- Stack Nav Sidebar Column -- */
.nav-container.nav-container--sidebar + .main-container {
  width: calc(100vw - 20.428571428571463em);
  float: right;
}
.nav-sidebar-column {
  position: fixed;
  z-index: 3;
  left: 0;
  width: 20.42857143em;
  border-right: 1px solid #ececec;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  padding: 2.78571429em 1.85714286em;
}
.nav-sidebar-column:not([class*='bg-']) {
  background: #ffffff;
}
.nav-sidebar-column.bg--dark {
  border-right: 1px solid #3f3f3f;
}
.nav-sidebar-column::-webkit-scrollbar {
  display: none;
}
.nav-sidebar-column .logo {
  max-height: 2.32142857em;
  margin-bottom: 0.92857143em;
}
.nav-sidebar-column .text-block > p {
  margin-bottom: 0.92857143em;
}
.nav-sidebar-column .menu-vertical li.dropdown:not(:hover):after {
  opacity: .35;
}
.nav-sidebar-column .menu-vertical a:hover {
  text-decoration: none;
}
.nav-sidebar-column .dropdown .dropdown__container,
.nav-sidebar-column .dropdown .dropdown__content {
  left: 0 !important;
  position: relative;
  pointer-events: all;
}
.nav-sidebar-column .dropdown .dropdown__container:before {
  height: 0;
}
.nav-sidebar-column .dropdown .dropdown__content {
  -webkit-transform: none !important;
          transform: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
  padding-top: 0.46428571em;
  padding-right: 0;
  padding-left: 0.46428571em;
  background: none !important;
}
.nav-sidebar-column .dropdown .menu-vertical li.dropdown:after {
  right: 1.85714286em;
}
.nav-sidebar-column .dropdown:not(.dropdown--active) .dropdown__container {
  display: none;
}
.nav-sidebar-column .dropdown.dropdown--hover:hover > .dropdown__container {
  display: block;
}
.nav-sidebar-column .social-list {
  margin-bottom: 0.92857143em;
}
body.dropdowns--hover .nav-sidebar-column .dropdown:hover > .dropdown__container {
  display: block;
}
.nav-sidebar-column-toggle {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  left: 0;
  width: 3.71428571em;
  height: 3.71428571em;
  background: #ffffff;
  text-align: center;
  position: fixed;
  z-index: 4;
  cursor: pointer;
  border-radius: 0 0 6px 0;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.1);
}
.nav-sidebar-column-toggle > i {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  font-size: 1.78571429em;
  position: relative;
  top: 11px;
}
.nav-sidebar-column-toggle:not(.toggled-class) > i {
  opacity: .5;
}
.nav-sidebar-column-toggle.toggled-class i:before {
  content: '\E80B';
}
@media all and (max-width: 990px) {
  .nav-sidebar-column {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    left: -20.42857143em;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0);
  }
  .nav-sidebar-column.active {
    left: 0;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .nav-sidebar-column-toggle.toggled-class {
    left: 20.42857143em;
  }
  .nav-container.nav-container--sidebar + .main-container {
    width: 100%;
    float: none;
  }
}
.nav-container.nav-container--right .nav-sidebar-column {
  right: 0;
  left: auto;
}
.nav-container.nav-container--right + .main-container {
  float: left;
}
.nav-container.nav-container--right .nav-sidebar-column-toggle {
  left: auto;
  right: 0;
  border-radius: 0 0 0 6px;
  box-shadow: -2px 1px 4px rgba(0, 0, 0, 0.1);
}
@media all and (max-width: 990px) {
  .nav-container.nav-container--right .nav-sidebar-column {
    right: -20.42857143em;
    left: auto;
  }
  .nav-container.nav-container--right .nav-sidebar-column.active {
    left: auto;
    right: 0;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .nav-container.nav-container--right .nav-sidebar-column-toggle.toggled-class {
    left: auto;
    right: 20.42857143em;
  }
}
/*! -- Stack Nav Fullscreen -- */
.menu-fullscreen {
  color: #fff;
}
.menu-fullscreen .pos-absolute {
  width: 100%;
}
.menu-fullscreen a {
  color: #fff;
}
.menu-fullscreen a:hover {
  text-decoration: none;
}
.menu-fullscreen .social-list {
  margin-right: 0;
}
.menu-fullscreen:before {
  background: rgba(0, 0, 0, 0.9);
}
.menu-fullscreen .modal-content .modal-close-cross {
  right: 3.71428571em;
  top: 1em;
}
@media all and (max-width: 767px) {
  .menu-fullscreen .pos-bottom {
    position: relative;
  }
}
/*! -- Stack Dropdowns -- */
.dropdown > .dropdown__trigger:after {
  font-family: "stack-interface";
  display: inline-block;
  font-size: 8px;
  position: relative;
  bottom: 2px;
}
.dropdown > .dropdown__trigger .image--xxs {
  margin-right: 0.46428571em;
}
.dropdown .dropdown__content {
  border-radius: 6px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08);
  padding: 1.23809524em 1.85714286em;
}
.dropdown .dropdown__content h5:not(:last-child) {
  margin-bottom: 0.92857143em;
}
.dropdown .dropdown__content .background-image-holder {
  border-radius: 6px 0 0 6px;
}
.dropdown .dropdown__content > .pos-absolute[class*='col-'] {
  left: 0;
  top: 0;
  height: 100%;
  position: absolute !important;
}
.dropdown .dropdown__content > .pos-absolute[class*='col-'][data-overlay]:before {
  border-radius: 6px 0 0 6px;
}
.menu-horizontal > .dropdown > .dropdown__trigger:after {
  content: '\E80A';
  margin-left: 4px;
}
.menu-vertical > .dropdown > .dropdown__trigger {
  display: inline-block;
  width: 100%;
}
@media all and (max-width: 767px) {
  .dropdown [class*='col-'] + [class*='col-'] {
    margin-top: 0.92857143em;
  }
  .dropdown .dropdown__content:not([class*='bg-']) {
    border: none;
    box-shadow: none;
  }
}
@media all and (max-width: 767px) {
  .dropdown__container {
    left: 0 !important;
  }
}
.menu-vertical li {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.menu-vertical li:not(:hover):not(.dropdown--active) {
  opacity: .75;
}
.menu-vertical li:not(:last-child) {
  margin-bottom: 0.30952381em;
}
.menu-vertical li.dropdown {
  position: relative;
}
.menu-vertical li.dropdown:after {
  content: '\E80C';
  font-family: 'stack-interface';
  right: 0;
  top: 0;
  position: absolute;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  display: inline-block;
  transform: translate3d(-10px, 0, 0);
  -webkit-transform: translate3d(-10px, 0, 0);
}
.menu-vertical li.dropdown:hover:after {
  transform: translate3d(-5px, 0, 0);
  -webkit-transform: translate3d(-5px, 0, 0);
}
.menu-vertical li.separate {
  border-top: 1px solid #e6e6e6;
  margin-top: 0.92857143em;
  padding-top: 0.92857143em;
}
.menu-vertical li.separate.dropdown:after {
  top: .92857143em;
}
.menu-vertical + h5 {
  margin-top: 1.85714286em;
}
.dropdown__container > .container {
  width: 100vw;
  max-width: 1140px;
}
@media all and (max-width: 990px) {
  .menu-vertical .dropdown.dropdown--active:after {
    opacity: 0;
  }
}
@media all and (min-width: 991px) {
  .dropdown .dropdown__container:before {
    height: 18px;
  }
}
/*! -- Stack Twitter Feeds -- */
.tweets-feed .interact {
  display: none;
}
.tweets-feed .user a:hover {
  text-decoration: none;
}
.tweets-feed .user img {
  border-radius: 50%;
}
.tweets-feed-1 {
  border-radius: 6px;
  border: 1px solid #ececec;
  overflow: hidden;
}
.tweets-feed-1 li {
  overflow: hidden;
  padding: 1.85714286em;
  padding-bottom: 0;
}
.tweets-feed-1 li:not(:last-child) {
  border-bottom: 1px solid #ececec;
}
.tweets-feed-1 .user {
  width: 20%;
  float: left;
  margin-bottom: 0.92857143em;
}
.tweets-feed-1 .user img {
  max-height: 3.71428571em;
  margin-right: 0.46428571em;
}
.tweets-feed-1 .user [data-scribe="element:name"],
.tweets-feed-1 .user [data-scribe="element:screen_name"] {
  display: none;
}
.tweets-feed-1 .tweet,
.tweets-feed-1 .timePosted {
  width: 80%;
  float: right;
}
.tweets-feed-1 .tweet {
  margin-bottom: 0.46428571em;
}
.tweets-feed-1 .timePosted {
  font-size: 0.85714286em;
  line-height: 2.16666667em;
}
.tweets-feed-1:not(:last-child) + .btn {
  margin-top: 2.78571429em;
}
.imagebg .tweets-feed-1,
.bg--dark .tweets-feed-1 {
  border-color: rgba(255, 255, 255, 0.1);
}
.imagebg .tweets-feed-1 li:not(:last-child),
.bg--dark .tweets-feed-1 li:not(:last-child) {
  border-color: rgba(255, 255, 255, 0.1);
}
.tweets-feed-2 .user {
  display: none;
}
.tweets-feed-2 .timePosted {
  display: none;
}
.tweets-feed-2 li {
  overflow: hidden;
}
.tweets-feed-2 li:before {
  font-family: 'socicon';
  content: "\E08D";
}
.tweets-feed-2 li .tweet {
  width: 85%;
  float: right;
}
.tweets-feed.slider .user img {
  display: inline-block;
  clear: both;
  margin-bottom: 0.92857143em;
}
.tweets-feed.slider .user [data-scribe="element:name"] {
  display: none;
}
.tweets-feed.slider .user [data-scribe="element:screen_name"] {
  display: block;
  font-size: 1.35714286em;
  line-height: 1.36842105em;
  font-weight: normal;
}
.tweets-feed.slider .tweet {
  margin-top: 1.36842105263158em;
  margin-bottom: 0.68421052631579em;
  font-size: 1.35714286em;
  line-height: 1.36842105em;
}
.tweets-feed.slider .timePosted {
  margin: 0;
}
.tweets-feed.slider:not(:last-child) + .btn {
  margin-top: 2.78571429em;
}
/*! -- Stack Instagram -- */
.instafeed a {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.instafeed a:hover {
  opacity: .75;
}
.instafeed:not(.instafeed--gapless) li {
  padding: 0.46428571em;
}
.instafeed.instafeed--gapless li {
  margin-bottom: -1px;
}
.instafeed + .btn {
  margin-top: 2.78571429em;
}
.instafeed[data-grid="1"][data-amount="8"] li {
  display: inline-block;
  width: 12.5%;
}
.instagram {
  position: relative;
}
.instagram.unpad .btn {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  margin: 0;
}
/*! -- Stack Cards -- */
.card__top {
  position: relative;
  overflow: hidden;
}
.card__action a {
  color: #666666;
  text-decoration: none;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.card__action a i {
  color: #252525;
  font-size: 1em;
}
.card__action a span {
  position: relative;
  bottom: 2px;
}
.card__action a:not(:hover) {
  opacity: .5;
}
.card p a {
  text-decoration: none;
}
.masonry__item .card.boxed {
  margin-bottom: 0;
}
.card-1 .card__avatar {
  margin-bottom: 0.92857143em;
}
.card-1 .card__avatar img {
  max-height: 2.78571429em;
  margin-right: 0.46428571em;
  margin-bottom: 0;
}
.card-1 .card__avatar,
.card-1 .card__meta {
  display: inline-block;
}
.card-1 .card__meta {
  float: right;
}
.card-1 .card__body {
  border-bottom: 1px solid #ececec;
  padding-bottom: 0.92857143em;
  margin-bottom: 0.92857143em;
}
.card-1 .card__body img {
  margin-bottom: 0.92857143em;
}
.card-1 .card__body h4 {
  margin-bottom: 0.342105263157895em;
}
.bg--dark .card-1 .card__body {
  border-color: #3f3f3f;
}
@media all and (max-width: 767px) {
  .card .list-inline:not(.social-list) li {
    display: inline-block;
  }
  .card .list-inline:not(.social-list) li:not(:first-child) {
    margin-left: 0.92857143em;
  }
}
.card-2 .card__top img {
  border-radius: 6px 6px 0 0;
}
.card-2 .card__body {
  border-bottom: 1px solid #ececec;
  padding-bottom: 1.85714286em;
}
.card-2 .card__body h4 {
  margin: 0;
}
.card-2 .card__body p {
  margin-top: 0.92857143em;
}
.card-2 .card__bottom {
  overflow: hidden;
}
.card-2 .card__bottom > div {
  display: inline-block;
  width: 50%;
  float: left;
}
.card-2 .card__bottom .h6 {
  margin-bottom: 0.541666666666667em;
}
.card-2 .card__body,
.card-2 .card__bottom {
  padding: 1.85714286em;
  border: 1px solid #ececec;
}
.card-2 .card__body {
  border-bottom: none;
  border-top: none;
}
.card-2 .card__bottom {
  border-radius: 0 0 6px 6px;
  padding: 0.92857143em 1.85714286em;
}
.card-2 .card__action i {
  font-size: 1.78571429em;
}
.bg--dark .card-2 .card__body,
.bg--dark .card-2 .card__bottom {
  border-color: #3f3f3f;
}
/*! -- Stack Checkmarks & Crosses -- */
.checkmark {
  display: inline-block;
  position: relative;
  width: 1.23809524em;
  height: 1.23809524em;
  border-radius: 50%;
  text-align: center;
}
.checkmark:not([class*='bg-']) {
  background: #4a90e2;
}
.checkmark:before {
  content: '\2713';
  color: #fff;
  font-size: 11px;
  position: absolute;
  width: 100%;
  left: 0;
  top: -4px;
}
.checkmark.checkmark--cross:before {
  content: '\2717';
}
.checkmark:not(:last-child) {
  margin-right: 13px;
}
/*! -- Stack Icons -- */
.icon:not(.block) {
  display: inline-block;
  padding-left: 4px;
}
.icon.icon--xs {
  font-size: 1.14285714285714em;
}
.text-block .icon + h4 {
  margin-top: 0.6842105263158em;
}
.imagebg .icon {
  color: #fff;
}
p .material-icons {
  font-size: 1em;
}
p .material-icons:first-child {
  margin-right: 0.30952381em;
}
.material-icons {
  font-family: 'Material Icons' !important;
}
.icon-circle {
  position: relative;
  display: inline-block;
  width: 3.71428571em;
  height: 3.71428571em;
  border-radius: 50%;
  border: 1px solid #ececec;
  background: #fafafa;
  text-align: center;
}
.icon-circle i {
  font-size: 24px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  left: 0;
}
/*! -- Stack Tooltips -- */
.tooltip {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 1.23809524em;
  height: 1.23809524em;
  border-radius: 50%;
  background: #252525;
  text-align: center;
  cursor: pointer;
}
.tooltip .tooltip__anchor {
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  position: absolute;
  top: -5px;
  left: 6px;
}
.tooltip .tooltip__text {
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  text-align: left;
  z-index: 10;
  position: absolute;
  width: 280px;
  background: #fafafa;
  border: 1px solid #ececec;
  padding: 0.61904762em;
  left: 1.48571429em;
  top: -0.92857143em;
  opacity: 0;
  pointer-events: none;
  cursor: default;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
  line-height: 20px;
}
.tooltip:hover .tooltip__text {
  opacity: 1;
}
.tooltip:last-child {
  margin-left: 0.92857143em;
}
[data-tooltip] {
  position: relative;
  overflow: visible;
}
[data-tooltip]:after {
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  content: attr(data-tooltip);
  position: absolute;
  top: -24px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #252525;
  color: #fff;
  border: 1px solid #e7e7e7;
  padding: 2px 12px;
  pointer-events: none;
  white-space: nowrap;
  font-size: 11px;
  line-height: 18px;
  font-weight: 600;
  border-radius: 2px;
  z-index: 2;
  font-family: 'Open Sans';
}
[data-tooltip]:not(:hover):after {
  opacity: 0;
}
/*! -- Stack Boxes -- */
.boxed {
  border-radius: 6px;
}
.boxed .background-image-holder {
  border-radius: 6px;
}
.boxed:before {
  border-radius: 6px;
}
.boxed:not(:last-child) {
  margin-bottom: 30px;
}
.boxed:not([class*='bg-']) {
  background: #ffffff;
}
.boxed.bg--secondary .boxed {
  background: #fafafa;
}
@media all and (min-width: 768px) {
  .switchable .imagebg .boxed {
    padding-left: 5.57142857em;
  }
}
.bg--dark .boxed:not([class*='bg-']),
.imagebg:not(.image--light) .boxed:not([class*='bg-']) {
  background: none;
  color: #fff;
}
.bg--dark .boxed:not([class*='bg-']).boxed--border,
.imagebg:not(.image--light) .boxed:not([class*='bg-']).boxed--border {
  border-color: #3f3f3f;
}
.bg--dark .boxed[class*='bg-'].boxed--border,
.imagebg:not(.image--light) .boxed[class*='bg-'].boxed--border {
  border: none;
}
.imagebg:not(.image--light) .boxed:not(.bg--white):not(.bg--none) {
  background: rgba(20, 20, 20, 0.3);
}
.imagebg:not(.image--light) .boxed:not(.bg--white):not(.bg--none).boxed--border {
  border: none;
}
.imagebg:not(.image--light) .boxed:not(.bg--white):not(.bg--none) .boxed {
  background: none;
}
.bg--primary .boxed:not(.imagebg) {
  color: #666666;
}
.bg--primary .boxed:not(.imagebg) p {
  color: #666666;
}
.bg--primary .boxed:not(.imagebg) h1,
.bg--primary .boxed:not(.imagebg) h2,
.bg--primary .boxed:not(.imagebg) h3,
.bg--primary .boxed:not(.imagebg) h4,
.bg--primary .boxed:not(.imagebg) h5,
.bg--primary .boxed:not(.imagebg) h6,
.bg--primary .boxed:not(.imagebg) .h1,
.bg--primary .boxed:not(.imagebg) .h2,
.bg--primary .boxed:not(.imagebg) .h3,
.bg--primary .boxed:not(.imagebg) .h4,
.bg--primary .boxed:not(.imagebg) .h5,
.bg--primary .boxed:not(.imagebg) .h6,
.bg--primary .boxed:not(.imagebg) i {
  color: #252525;
}
.bg--primary .boxed:not(.imagebg) a {
  color: #4a90e2;
}
.bg--primary .boxed:not(.imagebg) .color--primary {
  color: #4a90e2 !important;
}
@media all and (min-width: 768px) {
  .boxed.boxed--emphasis {
    transform: scale(1.075);
    -webkit-transform: scale(1.075);
  }
}
/*! -- Stack Features Small -- */
.feature:not(.boxed) {
  margin-bottom: 60px;
}
.feature.feature--featured:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: #4a90e2;
  left: 0;
}
.feature h5 {
  margin: 0;
}
.feature h4 {
  margin-bottom: 0.342105263157895em;
}
.feature h4 + p {
  max-width: 22.28571429em;
}
.feature i + h5 {
  margin-top: 1.85714286em;
}
.feature i + h4 {
  margin-top: 0.68421052631579em;
}
.feature i.icon--lg + h4 {
  margin-top: 1.36842105263158em;
}
.feature i.icon--lg + .h5 {
  margin-top: 0.92857143em;
}
.feature img + .boxed {
  border-radius: 0 0 6px 6px;
  border-top: none;
}
a.feature {
  display: block;
  text-decoration: none;
  font-weight: normal;
  color: #666666;
}
a.feature img:first-child:not(:last-child) {
  margin-bottom: 0.92857143em;
}
@media all and (max-width: 767px) {
  a.feature {
    margin-bottom: 0.92857143em !important;
  }
}
.imagebg:not(.image--light) .feature.bg--white a:not(.btn) {
  color: #4a90e2;
}
.imagebg:not(.image--light) .feature.bg--white .label {
  color: #fff;
}
@media all and (max-width: 767px) {
  .feature .feature__body form .row {
    margin-left: 0;
    margin-right: 0;
  }
}
section.text-center .feature-6 h4 + p {
  margin: 0 auto;
}
@media all and (min-width: 768px) {
  .row div[class*='col-']:nth-child(1):nth-last-child(3) .feature,
  .row div[class*='col-']:nth-child(2):nth-last-child(2) .feature,
  .row div[class*='col-']:nth-child(3):last-child .feature {
    margin-bottom: 0;
  }
}
a.block > .feature {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
a.block:hover > .feature {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.feature-1 {
  min-height: 11.14285714em;
}
.feature-1 p {
  margin: 0;
}
.feature-1 p.lead {
  min-height: 5.05263157894737em;
}
.feature-1 > img:first-child {
  border-radius: 6px 6px 0 0;
}
.feature-1 > a.block img {
  border-radius: 6px 6px 0 0;
}
.feature-1 > a.block + .feature__body {
  border-radius: 0 0 6px 6px;
}
.feature-1[class*='box-shadow'] {
  border-radius: 6px;
  transition: 0.35s ease-out;
  -webkit-transition: 0.35s ease-out;
  -moz-transition: 0.35s ease-out;
}
.feature-1[class*='box-shadow']:hover {
  transform: translate3d(0, -10px, 0);
  -webkit-transform: translate3d(0, -10px, 0);
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.hover-shadow {
  border-radius: 6px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  overflow: hidden;
  border: 1px solid #ececec;
  transition: 0.35s ease-out;
  -webkit-transition: 0.35s ease-out;
  -moz-transition: 0.35s ease-out;
}
.hover-shadow:hover {
  transform: translate3d(0, -10px, 0);
  -webkit-transform: translate3d(0, -10px, 0);
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.bg--dark .hover-shadow {
  border-color: #222;
}
.bg--dark .hover-shadow:hover {
  box-shadow: 0 23px 40px #000000;
}
.feature-2 {
  overflow: hidden;
}
.feature-2 .feature__body {
  width: 75%;
  float: right;
}
.feature-2 h5 {
  margin-bottom: 0.46428571em;
}
.feature-2 p:last-child {
  margin: 0;
}
.feature-2 i {
  width: 25%;
  float: left;
}
.feature-large .feature-2 + .feature-2:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 990px) {
  .feature-2 .feature__body,
  .feature-2 i {
    width: 100%;
    float: none;
  }
  .feature-2 p:first-of-type {
    margin-top: 0.92857143em;
  }
}
.feature-3 i {
  margin-bottom: 0.16666666666667em;
}
.feature-3 p {
  min-height: 7.42857143em;
}
.feature-4 .btn {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 0;
  padding: 0.92857143em;
}
.feature-4 .btn:hover {
  -webkit-transform: none;
          transform: none;
}
.feature-4 p {
  min-height: 9.28571429em;
}
.feature-4 p:last-of-type {
  margin-bottom: 3.71428571em;
}
.feature-5 i {
  width: 25%;
  float: left;
}
.feature-5 .feature__body {
  width: 75%;
  float: right;
}
.feature-5 p {
  min-height: 5.57142857em;
}
.feature-5 p:last-of-type {
  margin-bottom: 0.92857143em;
}
.feature-5:not([class*='bg-']) {
  color: #252525;
}
.feature-6 p {
  min-height: 9.28571429em;
}
.feature-6 p:last-child {
  margin: 0;
}
.feature-7 {
  height: 13em;
  margin-bottom: 30px;
}
.feature-7[data-overlay]:before {
  border-radius: 6px;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.feature-7[data-overlay]:hover:before {
  opacity: .75;
}
.feature-7 .background-image-holder {
  border-radius: 6px;
}
.feature-7 h3 {
  margin: 0;
}
.row--gapless .feature-7 {
  margin: 0;
  border-radius: 0;
}
.row--gapless .feature-7 .background-image-holder {
  border-radius: 0;
}
.row--gapless .feature-7:before {
  border-radius: 0;
}
@media all and (max-width: 767px) {
  .feature-7 .pos-vertical-center {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
  }
}
.feature-8 {
  padding: 2.78571429em 0 3.71428571em 0;
}
.feature-8:not(.boxed) {
  margin-bottom: 0;
}
.feature-8 .feature__body {
  max-width: 70%;
  margin: 0 auto;
}
.feature-8 p {
  min-height: 5.57142857em;
}
@media all and (max-width: 990px) {
  .feature-8 .feature__body {
    max-width: 85%;
  }
}
@media all and (max-width: 767px) {
  .feature p {
    min-height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .feature.boxed {
    margin-bottom: 15px;
  }
  .feature:not(.boxed) {
    margin-bottom: 30px;
  }
  .feature.feature-8 {
    margin-bottom: 0;
  }
}
.feature-8 img {
  max-height: 14.85714286em;
}
.feature-9 h4 {
  margin: 0;
}
.feature-9:not(.boxed) {
  margin-bottom: 30px;
}
@media all and (min-width: 768px) {
  .features-small-14 .text-block {
    margin-bottom: 3.71428571em;
  }
  .features-small-14 h4 {
    min-height: 2.736842105263158em;
  }
}
/*! -- Stack Features Large -- */
@media all and (min-width: 768px) {
  .staggered div[class*='col-']:nth-child(2):last-child {
    margin-top: 13em;
  }
  .staggered div[class*='col-']:nth-child(2):last-child:not(:last-child) {
    margin-bottom: 9.28571429em;
  }
  .staggered div[class*='col-'] .feature:not(:last-child) {
    margin-bottom: 9.28571429em;
  }
}
.feature-large h4:first-child {
  margin-bottom: 0.68421052631579em;
}
.feature-large .feature:not(.boxed) {
  margin-bottom: 30px;
}
.feature-large .feature-3.text-center p {
  margin: 0 auto;
}
.feature-large .lead + .feature-large__group {
  margin-top: 3.71428571em;
}
.feature-large .feature-large__group {
  overflow: hidden;
}
.feature-large-1 .lead {
  margin-bottom: 2.73684210526316em;
}
@media all and (min-width: 768px) {
  .feature-large-2 div[class*='col-']:first-child {
    margin-top: 5.57142857em;
  }
  .feature-large-2 div[class*='col-']:last-child {
    margin-top: 7.42857143em;
  }
}
@media all and (max-width: 767px) {
  .feature-large-2 img {
    margin: 1.85714286em 0;
  }
}
.feature-large-7.switchable .boxed div[class*='col-']:only-child {
  float: none;
}
.feature-large-13 p.lead + .text-block {
  margin-top: 3.71428571em;
}
.feature-large-13 div[class*='col-'] .text-block + .text-block {
  margin-top: 2.78571429em;
}
@media all and (min-width: 1200px) {
  .feature-large-13:not(.text-center) .text-block p {
    max-width: 26em;
  }
}
.feature-large-14 h5 {
  margin-bottom: 0.46428571em;
}
.feature-large-14 .boxed {
  margin-bottom: 0;
}
.feature-large-14 .boxed.boxed--border img {
  border-radius: 6px;
}
@media all and (min-width: 768px) {
  .feature-large-14.switchable .switchable__text {
    margin-top: 5.57142857em;
  }
}
/*! -- Stack Accordions -- */
.accordion .accordion__title {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  opacity: .5;
  margin-bottom: 0.92857143em;
}
.accordion .accordion__title:hover {
  opacity: 1;
}
.accordion .accordion__title:before {
  font-family: "stack-interface";
  content: '\E80D';
  margin-right: 6px;
  color: #252525;
}
.accordion .accordion__title span {
  display: inline-block;
}
.accordion li.active {
  margin-bottom: 0.92857143em;
}
.accordion li.active .accordion__title {
  opacity: 1;
}
.accordion li.active .accordion__title:before {
  content: '\E80A';
}
.accordion li.active .accordion__content {
  animation: fadeUp .5s ease forwards;
  -webkit-animation: fadeUp .5s ease forwards;
}
.accordion-1 li.active .accordion__title {
  border-color: #4a90e2;
}
.accordion-1 li.active .accordion__title span {
  color: #fff;
}
.accordion-1 li.active .accordion__title:before {
  color: #fff;
}
.accordion-1 li .accordion__title {
  border-radius: 6px;
  border-bottom: 1px solid #ECECEC;
}
.accordion-2 li .accordion__title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #ECECEC;
  padding-left: 0;
}
.accordion-2 li.active .accordion__title {
  background: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.bg--dark .accordion-2 li .accordion__title,
.imagebg:not(.image--light) .accordion-2 li .accordion__title,
.bg--primary .accordion-2 li .accordion__title,
.bg--dark .accordion-1 li .accordion__title,
.imagebg:not(.image--light) .accordion-1 li .accordion__title,
.bg--primary .accordion-1 li .accordion__title {
  border-color: #4b4b4b;
}
.bg--dark .accordion-2 li .accordion__title:before,
.imagebg:not(.image--light) .accordion-2 li .accordion__title:before,
.bg--primary .accordion-2 li .accordion__title:before,
.bg--dark .accordion-1 li .accordion__title:before,
.imagebg:not(.image--light) .accordion-1 li .accordion__title:before,
.bg--primary .accordion-1 li .accordion__title:before {
  color: #fff;
}
.imagebg:not(.image--light) .accordion li .accordion__title,
.bg--primary .accordion li .accordion__title {
  border-color: rgba(255, 255, 255, 0.3);
}
/*! -- Stack Tabs -- */
.tabs-container.tabs--vertical {
  overflow: hidden;
}
.tabs-container.tabs--vertical .tabs {
  width: 25%;
  float: left;
}
.tabs-container.tabs--vertical .tabs li {
  display: block;
}
.tabs-container.tabs--vertical .tabs li:not(:last-child) {
  border-right: none;
  border-bottom: 1px solid #ECECEC;
}
.tabs-container.tabs--vertical .tabs-content {
  width: 70%;
  float: right;
  margin-top: 0;
}
.tabs-container.tabs--vertical .tabs-content > li:not(.active) .tab__content {
  animation: fadeOutUp .5s ease forwards;
  -webkit-animation: fadeOutUp .5s ease forwards;
}
.tabs-container.tabs--vertical .tabs-content > li.active .tab__content {
  animation: fadeInUp .5s ease forwards;
  -webkit-animation: fadeInUp .5s ease forwards;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.tabs-container.tabs--vertical.tabs--icons .tabs {
  width: 15%;
  text-align: center;
}
.tabs-container.tabs--vertical.tabs--icons .tabs-content {
  width: 80%;
}
@media all and (max-width: 767px) {
  .tabs-container.tabs--vertical .tabs,
  .tabs-container.tabs--vertical .tabs-content {
    width: 100%;
    float: none;
  }
  .tabs-container.tabs--vertical .tabs {
    margin-bottom: 1.85714286em;
  }
  .tabs-container.tabs--vertical .tabs-content > li:not(.active) .tab__content {
    animation: fadeOutRight .5s ease forwards;
    -webkit-animation: fadeOutRight .5s ease forwards;
  }
  .tabs-container.tabs--vertical .tabs-content > li.active .tab__content {
    animation: fadeInLeft .5s ease forwards;
    -webkit-animation: fadeInLeft .5s ease forwards;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
}
.tabs {
  display: inline-block;
  border: 1px solid #ECECEC;
  border-radius: 6px;
  margin-bottom: 1.85714286em;
}
.tabs li {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  padding: 0.92857143em 1.85714286em;
}
.tabs li:not(:last-child) {
  border-right: 1px solid #ECECEC;
}
.tabs li.active {
  opacity: 1;
}
.tabs li.active .tab__title {
  color: #4a90e2;
}
.tabs li.active .tab__title span {
  color: #4a90e2;
}
.tabs li:not(.active) {
  opacity: .5;
}
.tabs li:hover {
  opacity: 1;
  cursor: pointer;
}
.tabs .tab__title {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.tabs .tab__title:not(.btn) {
  padding-right: 0;
  padding-left: 0;
}
.tabs .tab__title i + span {
  margin-top: 0.46428571em;
  display: inline-block;
  margin-bottom: 0;
}
.bg--primary .tabs {
  border-color: #fff;
}
.bg--primary .tabs li.active .tab__title {
  color: #fff;
}
.bg--primary .tabs li.active .tab__title span {
  color: #fff;
}
.bg--primary .tabs li:not(:last-child) {
  border-color: #fff;
}
@media all and (min-width: 768px) {
  .tabs.tabs--spaced {
    margin-bottom: 3.71428571em;
  }
}
.tabs-content {
  position: relative;
}
.tabs-content > li:not(.active) .tab__content {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  opacity: 0;
  pointer-events: none;
  animation: fadeOutRight .5s ease forwards;
  -webkit-animation: fadeOutRight .5s ease forwards;
}
.tabs-content > li.active {
  animation: fadeInLeft .5s ease forwards;
  -webkit-animation: fadeInLeft .5s ease forwards;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
@media all and (max-width: 767px) {
  .tabs li {
    display: block;
  }
  .tabs li:not(:last-child) {
    border-bottom: 1px solid #ECECEC;
    border-right: none;
  }
}
.tabs--folder .tabs {
  overflow: hidden;
  margin-bottom: 0;
  border-radius: 6px 6px 0 0;
  border-bottom: none;
}
.tabs--folder .tabs > li {
  border-top: 3px solid rgba(0, 0, 0, 0);
}
.tabs--folder .tabs > li.active {
  border-top: 3px solid #4a90e2;
}
.tabs--folder .tabs-content {
  overflow: hidden;
  position: relative;
  bottom: 8px;
  padding: 1.85714286em;
  border: 1px solid #ECECEC;
  border-radius: 0 0 6px 6px;
}
.tabs--folder .tabs-content > li:not(.active) .tab__content {
  top: 1.85714286em;
  left: 1.85714286em;
}
.tabs--folder .tab__title .h5 {
  margin: 0;
  display: inline-block;
}
.tabs--folder .tab__title .label {
  position: relative;
  color: #fff !important;
  top: -2px;
  right: 0;
  margin-left: 0.46428571em;
  min-width: 0;
}
/*! -- Stack Switchable Sections -- */
@media all and (min-width: 768px) {
  .switchable .container > .row {
    flex-flow: row-reverse;
  }
  .switchable.switchable--switch .container > .row {
    flex-flow: row;
  }
}
.switchable .switchable__text {
  margin-top: 1.85714286em;
}
.switchable [class*='col-']:first-child .switchable__text {
  margin-bottom: 2.78571429em;
}
.section--overlap .row {
  position: relative;
  margin-top: -13em;
}
@media all and (max-width: 990px) {
  .switchable .switchable__text {
    margin-top: 0;
  }
}
@media all and (max-width: 767px) {
  .switchable .switchable__text {
    margin-top: 1.85714286em;
  }
  .switchable .imageblock__content[class*='col-'] {
    float: none !important;
  }
  .switchable .row > div[class*='col-'] {
    float: none !important;
  }
  .switchable .row > div[class*='col-']:last-child:not(:only-child) {
    margin-top: 1.85714286em;
  }
}
/*! -- Stack CTA -- */
.cta .btn:first-child:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.cta.boxed:last-child {
  margin-bottom: 0;
}
@media all and (min-width: 768px) {
  .cta--horizontal h4,
  .cta--horizontal .lead,
  .cta--horizontal .btn {
    margin: 0;
  }
  .cta--horizontal h4,
  .cta--horizontal .lead {
    position: relative;
    top: 4px;
  }
  .bg--dark .cta-1 > div[class*='col-']:last-child,
  .imagebg .cta-1 > div[class*='col-']:last-child {
    border-left: none;
  }
}
@media all and (min-width: 1200px) {
  .cta-1.boxed--border {
    padding: 0;
  }
  .cta-1 > div[class*='col-'] {
    padding: 1.23809524em 15px;
  }
  .cta-1 > div[class*='col-']:last-child {
    border-left: 1px solid #ECECEC;
  }
}
@media all and (max-width: 990px) {
  .cta-1 {
    text-align: center;
  }
  .cta-1 > div[class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
  .cta-1 > div[class*='col-']:not(:last-child) {
    margin-bottom: 0.92857143em;
  }
  .cta-1 > div[class*='col-']:last-child {
    margin-top: 1.85714286em;
  }
}
@media all and (max-width: 767px) {
  .cta .lead {
    margin: 1.36842105263158em 0;
  }
}
.cta-2 {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.cta-2 h2 {
  margin: 1.57575757575758em 0;
}
.cta-2.bg--primary:hover {
  background: #5798e4;
}
.cta-2:hover {
  background: #ffffff;
}
@media all and (min-width: 768px) {
  .cta-3 .switchable__text {
    margin-top: 5.57142857em;
  }
}
/*! -- Stack Videos -- */
.video-play-icon:before {
  border-color: transparent transparent transparent #252525;
  margin-top: -3px;
}
.modal-instance .video-play-icon + span {
  position: relative;
  display: inline-block;
  margin-left: 0.92857143em;
  white-space: nowrap;
  bottom: 22px;
}
.modal-instance .video-play-icon.video-play-icon--xs + span {
  bottom: 8px;
  margin-left: 0.46428571em;
}
.video-cover {
  background: #252525;
}
.video-cover.border--round {
  overflow: hidden;
}
.video-cover.border--round iframe {
  border-radius: 6px;
}
.video-cover:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.video-cover + span.h4 + span {
  display: inline-block;
  margin-left: 0.92857143em;
}
.video-cover .video-play-icon {
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
}
.video-cover .video-play-icon:hover {
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  transform: translate3d(-50%, -52%, 0);
  -webkit-transform: translate3d(-50%, -52%, 0);
}
.imagebg .video-cover .video-play-icon {
  z-index: 10;
}
@media all and (min-width: 768px) {
  h2 + .video-cover {
    margin-top: 3.71428571em;
  }
}
@media all and (min-width: 768px) {
  .video-1 .video-play-icon {
    margin-right: 1.85714286em;
  }
  .video-1 h2 {
    display: inline-block;
    position: relative;
    bottom: 14px;
  }
  div[class*='col-sm-6'] .video-1 h2 {
    bottom: 0;
    display: block;
  }
  div[class*='col-sm-6'] .video-1 .video-play-icon {
    margin-bottom: 0.46428571em;
  }
}
.video-1 h2 {
  margin: 0;
}
.imageblock__content .modal-instance {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
}
/*! -- Stack Modals -- */
.modal-container .modal-content:not(.height--natural) {
  height: auto;
  width: auto;
}
.modal-instance:not(.block) {
  display: inline-block;
}
.modal-instance.block > .btn {
  display: block;
}
@media all and (min-width: 768px) {
  .modal-instance + span:last-child {
    display: inline-block;
    margin-left: 0.92857143em;
  }
}
.modal-container .modal-content {
  max-height: 95%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
}
.modal-container .modal-content .feature:only-child {
  margin-bottom: 0;
}
.modal-container .modal-content .feature:only-child .boxed {
  margin-bottom: 0;
}
.modal-container .modal-content .imagebg:not(.image--light) .modal-close-cross:before {
  color: #fff;
}
.modal-container .modal-content .container {
  width: 100vw;
}
@media all and (max-width: 767px) {
  .modal-container .modal-content > .modal-close-cross {
    top: 3em;
    background: #222;
    width: 2em;
    height: 2em;
    text-align: center;
    border-radius: 50%;
    color: #fff;
  }
  .modal-container:not(.modal-active) .modal-content {
    display: none;
  }
}
.modal-content {
  -ms-overflow-style: none;
}
.modal-content::-webkit-scrollbar {
  display: none;
}
/*! -- Stack Notifications -- */
.notification {
  max-width: inherit;
  margin: 1.85714286em;
  box-shadow: none;
}
.notification:not([class*='bg--']) {
  background: none;
}
.notification > .boxed {
  margin: 0;
}
.notification > .feature,
.notification .feature__body {
  margin-bottom: 0;
}
.search-box {
  width: 100%;
  margin: 0;
  padding: 1.85714286em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.search-box.notification--reveal {
  z-index: 9999;
}
.search-box .notification-close-cross {
  top: 2.32142857em;
  right: 1.85714286em;
}
.notification-close-cross.notification-close-cross--circle {
  background: #222;
  width: 2em;
  height: 2em;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}
/*! -- Stack Loader -- */
.loader {
  border: 3px solid #4a90e2;
  border-radius: 30px;
  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  opacity: 0;
  position: fixed;
  z-index: 10;
  top: 50%;
  width: 30px;
  -webkit-animation: pulsate 0.7s ease-out infinite;
          animation: pulsate 0.7s ease-out infinite;
}
.loader.loader--fade {
  opacity: 0;
  -webkit-animation: none;
          animation: none;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
}
/*! -- Stack Lightboxes -- */
.lb-close {
  position: relative;
  top: 10px;
}
.lb-close:before {
  content: '\D7';
  color: #fff;
  font-size: 2.5em;
  font-weight: 400;
}
.lb-close:hover {
  text-decoration: none;
}
.lb-prev:after,
.lb-next:after {
  font-family: 'stack-interface';
  position: fixed;
}
.lb-prev:after {
  content: '\E801';
  left: 1.85714286em;
}
.lb-next:after {
  content: '\E802';
  right: 1.85714286em;
}
.lb-data {
  overflow: hidden;
  min-height: 30px;
}
a[data-lightbox] {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
a[data-lightbox]:hover {
  opacity: .8;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
/*! -- Stack Covers -- */
.cover .typed-headline {
  margin-bottom: 3.71428571em;
}
.cover .typed-headline .type--bold {
  font-weight: 400;
}
.cover .triptych {
  margin-top: 3.71428571em;
}
.cover .btn + .modal-instance {
  margin-top: 1.85714286em;
}
.cover h3 + form,
.cover .h3 + form {
  margin-top: 2.78571429em;
}
.cover .lead + .boxed {
  margin-top: 3.71428571em;
}
.cover.unpad--bottom {
  overflow: hidden;
}
.cover .modal-instance.block:not(:first-child):not(:last-child) {
  margin: 2.78571429em 0;
}
.cover .pos-bottom {
  margin-bottom: 1.85714286em;
}
@media all and (min-width: 768px) {
  .cover .row .row:last-child {
    margin-top: 5.57142857em;
  }
}
@media all and (max-width: 767px) {
  .cover .pos-bottom.pos-absolute {
    position: relative;
    margin-top: 1.85714286em;
  }
}
@media all and (max-width: 990px) {
  .cover .mt--3 {
    margin-top: 0;
  }
}
@media all and (min-width: 768px) {
  .cover .triptych {
    margin-top: 7.42857143em;
  }
}
.cover-fullscreen {
  min-height: 100vh;
}
.cover-fullscreen.slider[data-paging="true"] .slides .flickity-slider > li {
  height: 100vh;
}
.cover-fullscreen.slider[data-paging="true"] .pos-bottom {
  bottom: 5.57142857em;
  width: 100%;
}
.cover-fullscreen.slider[data-paging="true"] h4 {
  margin: 0;
}
.cover-slider {
  overflow: hidden;
}
.cover-slider .slider {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.cover-slider .slider:nth-child(1) {
  -webkit-transform: translateY(-30%);
          transform: translateY(-30%);
}
.cover-slider .slider:nth-child(2) {
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
}
.cover-slider .slider:nth-child(3) {
  -webkit-transform: translateY(-20%);
          transform: translateY(-20%);
}
.cover-slider .slider .slide {
  opacity: .05;
}
.cover-slider .typed-headline {
  margin: 0;
}
.cover-slider .typed-headline + .btn {
  margin-top: 0.92857143em;
}
.cover-slider .slider-container {
  transform: rotateZ(-30deg) translateY(-10%);
  -webkit-transform: rotateZ(-30deg) translateY(-10%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
@media all and (min-width: 768px) {
  .cover-slider + .cta-4 {
    position: absolute;
    background: none;
    color: #fff;
    border-bottom: none !important;
    width: 100%;
    transform: translate3d(0, -100%, 0);
    -webkit-transform: translate3d(0, -100%, 0);
  }
  .cover-slider + .cta-4 a {
    color: #fff;
    font-weight: 600;
  }
}
.cover-features.space--lg {
  padding-bottom: 3.71428571em;
}
.cover-features .row:nth-child(2) {
  margin-top: 11.14285714em;
}
@media all and (max-width: 767px) {
  .cover-features.space--lg {
    padding-bottom: 1.85714286em;
  }
  .cover-features .row:nth-child(2) {
    margin-top: 3.71428571em;
  }
}
/*! -- Stack Processes -- */
.process-1 {
  padding: 3.71428571em 0;
  position: relative;
  overflow: hidden;
}
.process-1 .process__item {
  width: 50%;
}
.process-1 .process__item:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.process-1:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  left: 50%;
  top: 0;
  background: #4a90e2;
}
.process__item {
  position: relative;
}
.process__item:after {
  content: '';
  width: 1.85714286em;
  height: 1.85714286em;
  background: #fff;
  border: 2px solid #4a90e2;
  border-radius: 50%;
  position: absolute;
  top: 0;
  margin-left: 1px;
}
@media all and (min-width: 768px) {
  .process-1 .process__item:nth-child(odd) {
    text-align: right;
    padding-right: 3.71428571em;
  }
  .process-1 .process__item:nth-child(odd):after {
    right: -0.92857143em;
  }
  .process-1 .process__item:nth-child(even) {
    margin-left: 50%;
    padding-left: 3.71428571em;
  }
  .process-1 .process__item:nth-child(even):after {
    left: -0.92857143em;
  }
}
@media all and (max-width: 767px) {
  .process-1:before,
  .process-2:before {
    left: 0.92857143em;
  }
  .process-1 .process__item,
  .process-2 .process__item {
    width: 100%;
    padding-left: 3.71428571em;
  }
  .process-1 .process__item:after,
  .process-2 .process__item:after {
    left: 0;
  }
}
.bg--dark .process-1:before,
.imagebg .process-1:before,
.bg--dark .process-2:before,
.imagebg .process-2:before {
  background: #fff;
}
.bg--dark .process-1 .process__item:after,
.imagebg .process-1 .process__item:after,
.bg--dark .process-2 .process__item:after,
.imagebg .process-2 .process__item:after {
  background: #fff;
}
.bg--dark .process-1 .process__item:after,
.bg--dark .process-2 .process__item:after {
  border-color: #252525;
}
.process-2 {
  position: relative;
}
.process-2 .process__item {
  padding-top: 3.71428571em;
}
.process-2 .process__item:after {
  display: inline-block;
  text-align: center;
}
.process-2:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  top: 0.92857143em;
  left: 0;
  background: #4a90e2;
}
@media all and (max-width: 767px) {
  .process-2:before {
    height: 100%;
    width: 2px;
    left: 1.85714286em;
    margin-left: 2px;
  }
  .process-2 .process__item:after {
    top: 3.71428571em;
  }
}
.process-3 {
  list-style: none;
  position: relative;
}
.process-3 li {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.process-3 li .process__number {
  float: left;
  width: 15%;
  width: 3.71428571em;
  height: 3.71428571em;
  border-radius: 50%;
  border: 2px solid #4a90e2;
  background: #fff;
  text-align: center;
}
.process-3 li .process__number span {
  font-size: 1.35714286em;
  font-weight: bold;
  color: #4a90e2;
  position: relative;
  top: 12px;
}
.process-3 li .process__body {
  width: 80%;
  float: right;
}
.process-3 li .process__body h4 {
  margin-bottom: 0.342105263157895em;
}
.process-3 li:not(:last-child) {
  margin-bottom: 3.71428571em;
}
.process-3:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  left: 1.85714286em;
  top: 0;
  border-left: 1px dashed #ddd;
}
.bg--dark .process-3 li .process__number {
  background: #252525;
}
.bg--dark .process-3:before {
  border-color: #aaa;
}
.process-stepper > li {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  display: inline-block;
  float: left;
  text-align: center;
  position: relative;
}
.process-stepper > li:before {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  content: '';
  width: 0.92857143em;
  height: 0.92857143em;
  background: #ccc;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.92857143em;
}
.process-stepper > li:after {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  background: #ccc;
  top: 5px;
  left: 0;
}
.process-stepper > li:first-child:after,
.process-stepper > li:last-child:after {
  width: 50%;
}
.process-stepper > li:first-child:after {
  left: 50%;
}
.process-stepper > li.active:before,
.process-stepper > li.active:after {
  background: #4a90e2;
}
.process-stepper > li > span {
  cursor: default;
}
.process-stepper[data-process-steps="6"] > li {
  width: 16.666666666666667%;
}
.process-stepper[data-process-steps="5"] > li {
  width: 20%;
}
.process-stepper[data-process-steps="4"] > li {
  width: 25%;
}
.process-stepper[data-process-steps="3"] > li {
  width: 33.33333333%;
}
.process-stepper[data-process-steps="2"] > li {
  width: 50%;
}
.bg--dark .process-stepper li:not(.active):before,
.bg--dark .process-stepper li:not(.active):after {
  background: #585858;
}
/*! -- Stack Typed Text -- */
span + .typed-text.h2 {
  margin-left: 4px;
}
span + .typed-text.h1 {
  margin-left: 6px;
}
@media all and (max-width: 767px) {
  .typed-text {
    display: block;
  }
  .typed-headline {
    margin-bottom: 1.85714286em;
  }
  .typed-headline h1,
  .typed-headline .h1 {
    margin-bottom: 0;
  }
}
/*! -- Stack Forms -- */
form:before,
form:after {
  content: '';
}
.bg--dark input,
.imagebg:not(.image--light) input,
.bg--dark textarea,
.imagebg:not(.image--light) textarea,
.bg--dark select,
.imagebg:not(.image--light) select,
.bg--dark .input-select:after,
.imagebg:not(.image--light) .input-select:after {
  color: #666666;
}
.bg--dark label,
.imagebg:not(.image--light) label {
  color: #fff;
}
.bg--dark form.attempted-submit input.field-error {
  background: #D84D4D;
  color: #fff;
}
form {
  position: relative;
}
form > div[class*='col-']:not(:last-child),
form > .row > div[class*='col-']:not(:last-child) {
  margin-bottom: 0.92857143em;
}
form .boxed:last-child {
  margin: 0;
}
form.form--clearfix {
  margin-left: -15px;
}
@media all and (min-width: 768px) {
  div[class*='col-'] > form div[class*='col-']:last-child:nth-child(2) {
    padding-right: 0;
  }
  div[class*='col-'] > form div[class*='col-']:first-child:nth-last-child(2) {
    padding-left: 0;
  }
  form.form--horizontal > div[class*='col-'] {
    margin: 0;
  }
}
@media all and (max-width: 767px) {
  .row form > .row {
    margin-left: 0;
    margin-right: 0;
  }
}
h2 + form,
.h2 + form {
  margin-top: 2.78571429em;
}
h3 + form,
.h3 + form,
.lead + form {
  margin-top: 2.78571429em;
}
.cover .lead + form {
  margin-top: 3.71428571em;
}
form + span.type--fine-print {
  margin-top: 1.08333333333334em;
  display: inline-block;
}
.form--inline > span.h4 {
  color: #666666;
}
.form--inline input,
.form--inline [class*='input-'] {
  display: inline-block;
  max-width: 200px;
}
.form--inline > span:not(:last-child),
.form--inline input:not(:last-child),
.form--inline [class*='input-']:not(:last-child) {
  margin-right: 0.92857143em;
}
.form--inline button {
  max-width: 200px;
}
.bg--dark .form--inline > span.h4,
.imagebg:not(.image--light) .form--inline > span.h4 {
  color: #fff;
}
button,
input[type="submit"] {
  height: 3.25000000000001em;
}
button.btn,
input[type="submit"].btn {
  font-size: 0.85714286em;
  font-weight: 700;
  padding-left: 0;
  padding-right: 0;
}
button.btn.btn--primary,
input[type="submit"].btn.btn--primary {
  color: #fff;
}
button.btn.type--uppercase,
input[type="submit"].btn.type--uppercase {
  letter-spacing: .5px;
  margin-right: -0.5px;
}
button.checkmark.checkmark--cross,
input[type="submit"].checkmark.checkmark--cross {
  width: 1.85714286em;
  height: 1.85714286em;
  border: none;
  background: #e23636;
}
button.checkmark.checkmark--cross:before,
input[type="submit"].checkmark.checkmark--cross:before {
  content: '\D7';
  font-size: 18px;
  top: -1px;
}
button[type="submit"].btn--loading:after,
input[type="submit"][type="submit"].btn--loading:after {
  background: #4a90e2;
}
.bg--primary button.btn {
  border-color: rgba(255, 255, 255, 0.5);
  color: #fff;
}
.bg--primary button.btn:hover {
  border-color: #fff;
}
.bg--primary button.btn.btn--primary {
  color: #4a90e2;
  border-color: #4a90e2;
}
.bg--primary button.btn.btn--primary-1 {
  border-color: #31639c;
}
input,
select {
  height: 2.78571429em;
}
input.field-error {
  border-color: #EBA8A8;
}
input[type] + input[type],
input[type] + .input-checkbox,
input[type] + button,
input[type] + .input-select {
  margin-top: 0.92857143em;
}
input,
[class*='input-'] label,
select,
textarea {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
input:not([class*='col-']),
select:not([class*='col-']),
.input-select:not([class*='col-']),
textarea:not([class*='col-']),
button[type="submit"]:not([class*='col-']) {
  width: 100%;
}
input[type],
select,
textarea {
  padding-left: 0.92857143em;
}
input[type]:focus,
select:focus,
textarea:focus {
  border-color: #76abe9;
}
input[type="image"] {
  border: none;
  padding: none;
  width: auto;
}
label {
  font-size: .85714286em;
}
.bg--primary label,
.bg--primary-1 label {
  color: #fff;
}
.input-icon i {
  top: 8px;
  left: 8px;
}
.input-icon input {
  padding-left: 2.78571429em;
}
.input-icon.input-icon--right i {
  left: auto;
  right: 8px;
}
.input-icon.input-icon--right input {
  padding-right: 2.78571429em;
  padding-left: .92857143em;
}
.text-center .input-checkbox label,
.text-center .input-radio label {
  margin: 0 auto;
}
.input-checkbox label,
.input-radio label {
  font-size: 1em;
}
.input-checkbox {
  margin-top: 0.46428571em;
}
.input-checkbox.field-error label {
  border-color: #EBA8A8;
}
.input-checkbox label {
  background: none;
  border: 1px solid #d3d3d3;
  text-align: center;
  position: relative;
}
.input-checkbox label:not(:last-child) {
  margin-right: 0.46428571em;
}
.input-checkbox label:hover {
  border-color: #4a90e2;
}
.input-checkbox label:before {
  content: '';
  left: 0;
  border-radius: 6px;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #4a90e2;
  opacity: 0;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.input-checkbox input:checked + label {
  border-color: #4a90e2;
  background: #4a90e2;
}
.input-checkbox input:checked + label:after {
  content: 'L';
  -webkit-transform: rotateY(180deg) rotateZ(-45deg);
          transform: rotateY(180deg) rotateZ(-45deg);
  color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  top: -2px;
  font-weight: 700;
}
.input-checkbox input:checked + label:before {
  animation: pulse .45s ease forwards;
  -webkit-animation: pulse .45s ease forwards;
}
.input-checkbox + span {
  display: inline-block;
  position: relative;
  bottom: 10px;
  margin-left: 0.92857143em;
  font-size: 0.85714286em;
  white-space: nowrap;
}
.input-checkbox + button[type] {
  margin-top: 0.92857143em;
}
.input-checkbox + span + button[type] {
  margin-top: 0.92857143em;
}
.bg--dark .input-checkbox:not(.checked) label {
  border-color: #a5a5a5;
}
.bg--dark .input-checkbox + span {
  opacity: .75;
}
.input-checkbox.input-checkbox--switch label {
  width: 3.71428571em;
  border-radius: 60px;
}
.input-checkbox.input-checkbox--switch label:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-color: #d3d3d3;
  opacity: 1;
  left: 2px;
  top: 2px;
}
.input-checkbox.input-checkbox--switch label:hover:before {
  border-color: #4a90e2;
}
.input-checkbox.input-checkbox--switch input:checked + label {
  background: none;
}
.input-checkbox.input-checkbox--switch input:checked + label:before {
  -webkit-animation: none !important;
          animation: none !important;
  background: #4a90e2;
  border-color: #4a90e2;
  -webkit-transform: translateX(1.85714286em);
          transform: translateX(1.85714286em);
}
.input-checkbox.input-checkbox--switch input:checked + label:after {
  display: none;
}
.input-radio.field-error label {
  border-color: #EBA8A8;
}
.input-radio .input__label {
  font-size: .85714286em;
  display: inline-block;
}
.input-radio .input__label + label {
  margin-top: 0.92857143em;
}
.input-radio label {
  position: relative;
  background: none;
  border: 1px solid #d3d3d3;
}
.input-radio label:hover {
  border-color: #4a90e2;
}
.input-radio label:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #4a90e2;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  opacity: 0;
  left: 0;
}
.input-radio input:checked + label {
  border-color: #4a90e2;
  background-color: #4a90e2;
}
.input-radio input:checked + label:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  left: 7px;
  top: 7px;
  background-color: #fff;
  border-radius: 50%;
}
.input-radio input:checked + label:before {
  -webkit-animation: pulse .4s ease forwards;
          animation: pulse .4s ease forwards;
}
.input-radio--innerlabel label {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  height: 2.78571429em;
  line-height: 2.50714286em;
  padding: 0 0.92857143em;
  border: 1px solid #ececec;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.input-radio--innerlabel:hover {
  border-color: #4a90e2;
}
.input-radio--innerlabel input:checked + label {
  border-color: #4a90e2;
  background: #4a90e2;
  color: #fff;
}
.input-radio--innerlabel input:checked + label:after {
  display: none;
}
.input-radio--innerlabel input:checked + label:before {
  -webkit-animation: none;
          animation: none;
}
@media all and (max-width: 1024px) {
  .input-radio input:checked + label:after {
    top: 6px;
    left: 6px;
  }
}
.bg--primary .input-radio--innerlabel {
  border-color: #76abe9;
}
.bg--primary .input-radio--innerlabel:hover {
  border-color: #fff;
}
.bg--primary .input-radio--innerlabel.checked {
  background: #fff;
  border-color: #fff;
}
.bg--primary .input-radio--innerlabel.checked label {
  color: #4a90e2;
}
.bg--primary-1 .input-radio--innerlabel {
  border-color: #3e7cc2;
}
.bg--primary-1 .input-radio--innerlabel:hover {
  border-color: #fff;
}
.bg--primary-1 .input-radio--innerlabel.checked {
  background: #fff;
  border-color: #fff;
}
.bg--primary-1 .input-radio--innerlabel.checked label {
  color: #31639c;
}
.input-select {
  position: relative;
}
.input-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
}
.input-select select::-ms-expand {
  display: none;
}
.input-select:not(:last-child) {
  margin-bottom: 0.92857143em;
}
.input-select:after {
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  font-size: 30px;
  content: '\2263';
  pointer-events: none;
  padding: 0 13px;
  border-left: 1px solid #ececec;
  line-height: 31px;
}
.input-select.input-select--borderless:after {
  border-left: none;
}
.input-select:focus:after {
  border-color: #4a90e2;
}
.input-select select:focus {
  border-color: #4a90e2;
}
.input-number {
  position: relative;
}
.input-number > input[type="number"] {
  padding-left: .46428571em;
  -moz-appearance: textfield;
  width: 100%;
  text-align: center;
}
.input-number > input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
.input-number .input-number__controls {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  padding: 0 0.92857143em;
}
.input-number .input-number__controls > span {
  position: absolute;
  display: block;
  width: 10%;
  min-width: 3.71428571em;
  height: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  text-align: center;
  padding-top: 6px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.input-number .input-number__controls > span:hover {
  color: #4a90e2;
}
.input-number .input-number__controls .input-number__increase {
  right: 0;
  border-left: 1px solid #ececec;
}
.input-number .input-number__controls .input-number__decrease {
  left: 0;
  border-right: 1px solid #ececec;
}
/*! -- Stack Subscribe Forms -- */
.subscribe-form-1 h3 {
  margin-bottom: 0.52em;
}
.subscribe-form-1.boxed form {
  margin: 1.85714286em;
}
@media all and (min-width: 991px) {
  .subscribe-form-2 p {
    top: 2px;
    position: relative;
  }
  .subscribe-form-2 input {
    margin: 0;
  }
  .subscribe-form-2 form [class*='col-'] {
    margin: 0 !important;
  }
}
@media all and (max-width: 990px) {
  .subscribe-form-2 {
    text-align: center;
  }
  .subscribe-form-2 form {
    margin-top: 1.85714286em;
  }
}
/*! -- Stack Elaborate Forms -- */
@media all and (max-width: 767px) {
  .elaborate-form-1 .pos-vertical-center {
    padding-left: 15px;
    padding-right: 15px;
  }
  .elaborate-form-1 form .col-xs-6:nth-child(even) {
    padding-left: 15px;
  }
  .elaborate-form-1 form .col-xs-6:nth-child(odd) {
    padding-right: 15px;
  }
  .elaborate-form-1 .row form > .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
/* Stack Boxed Forms */
.boxed-form h4 {
  margin-bottom: 0.684210526315789em;
}
.boxed-form:last-child {
  margin-bottom: 0;
}
/* Stack Datepicker Form */
.datepicker {
  cursor: pointer;
}
.datepicker.datepicker--fluid + .picker {
  max-width: none;
}
.picker {
  transition: all .3s ease;
  z-index: 10;
  max-width: 100%;
  -webkit-transition: all .3s ease;
  margin-top: 0.46428571em;
  position: absolute;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 6px;
  padding: 0.92857143em;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
}
.picker .picker__header {
  text-align: center;
  margin-bottom: 0.92857143em;
}
.picker .picker__header > div {
  font-weight: 600;
  display: inline-block;
  color: #252525;
}
.picker .picker__header > div:not(:last-child) {
  margin-right: 0.46428571em;
}
.picker .picker__header .picker__nav--prev,
.picker .picker__header .picker__nav--next {
  height: 10px;
  width: 10px;
  cursor: pointer;
}
.picker .picker__header .picker__nav--prev:after,
.picker .picker__header .picker__nav--next:after {
  font-family: 'stack-interface';
}
.picker .picker__header .picker__nav--next {
  float: right;
}
.picker .picker__header .picker__nav--next:after {
  content: '\E80C';
}
.picker .picker__header .picker__nav--prev {
  float: left;
}
.picker .picker__header .picker__nav--prev:after {
  content: '\E80B';
}
.picker .picker__day {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  cursor: pointer;
}
.picker .picker__day.picker__day--highlighted {
  color: #4a90e2;
  font-weight: 600;
}
.picker .picker__day:hover {
  color: #4a90e2;
}
.picker:not(.picker--opened) {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.picker .picker__holder {
  outline: none !important;
  border: none !important;
}
.picker table td {
  padding: 0.46428571em;
  padding-top: 0;
}
.picker .picker__footer {
  text-align: center;
}
.picker .picker__button--today {
  float: left;
}
.picker .picker__button--close {
  float: right;
}
.picker button {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  height: auto;
  border: none;
  display: inline-block;
}
.picker button:not(:hover) {
  opacity: .5;
}
/* Stack Horizontal Forms */
.form-horizontal {
  display: flex;
}
.form-horizontal > div[class*='col-'],
.form-horizontal > div[class*='col-']:not(:last-child) {
  margin-bottom: 0;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.form-horizontal .picker {
  top: 100%;
}
@media all and (max-width: 990px) {
  .form-horizontal {
    display: block;
  }
  label {
    margin-top: 0.46428571em;
  }
  button {
    margin-top: 1.85714286em;
  }
}
/*! -- Stack Sliders -- */
.flickity-prev-next-button {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  border-radius: 0;
  background: #252525;
  width: 2.78571429em;
  height: 5.57142857em;
}
.flickity-prev-next-button:not(:hover) {
  opacity: .5;
}
.flickity-prev-next-button:hover {
  background: #252525;
}
.flickity-prev-next-button.previous {
  left: 0;
}
.flickity-prev-next-button.next {
  right: 0;
}
.flickity-prev-next-button:before {
  color: #fff;
}
.slider.slider--inline-arrows .flickity-prev-next-button {
  background: none;
}
.slider.slider--inline-arrows .flickity-prev-next-button:before {
  font-size: 1.78571429em;
  color: #252525;
  content: '\E802';
}
.slider.slider--inline-arrows .flickity-prev-next-button.previous:before {
  content: '\E801';
}
.slider.slider--columns .previous {
  left: 15px;
}
.slider.slider--columns .next {
  right: 15px;
}
.slider.border--round .slides li {
  border-radius: 6px;
  overflow: hidden;
}
.slider.boxed--border .slides li {
  border: 1px solid #ececec;
}
.slider .slides li .feature:not(.feature-9) {
  margin-bottom: 0;
}
.slide .boxed:last-child {
  margin-bottom: 0;
}
.slide:not([class*='col-']) > img:only-child {
  width: 100%;
}
.bg--dark .slider.slider--inline-arrows .flickity-prev-next-button:before,
.bg--primary .slider.slider--inline-arrows .flickity-prev-next-button:before,
.imagebg:not(.image--light) .slider.slider--inline-arrows .flickity-prev-next-button:before {
  color: #fff;
}
@media all and (max-width: 990px) {
  .flickity-prev-next-button {
    display: none;
  }
}
@media all and (min-width: 768px) {
  h2 + .slider,
  .h2 + .slider {
    margin-top: 3.71428571em;
  }
}
.slider.slider--ken-burns:not(.parallax) .slide.is-selected > .background-image-holder,
.slider.slider--ken-burns:not(.parallax) .slide.is-selected > img:only-child {
  animation: kenBurns 15s ease alternate infinite;
  -webkit-animation: kenBurns 15s ease alternate infinite;
}
.radial {
  margin-left: auto;
  margin-right: auto;
}
.radial .radial__label {
  transition: opacity .3s ease;
  -webkit-transition: opacity .3s ease;
}
.radial:not(.radial--active) .radial__label {
  opacity: 0;
}
.radial:not(:last-child) {
  margin-bottom: 1.85714286em;
}
@media all and (max-width: 767px) {
  .radial {
    margin-bottom: 1.85714286em;
  }
}
/*! -- Stack Pricing -- */
.pricing {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  position: relative;
}
.pricing h3:first-child {
  margin: 0;
}
.pricing .label {
  top: 2.78571429em;
  right: 2.78571429em;
}
.pricing .h2 {
  margin-bottom: 0.3939393939394em;
}
.pricing ul li:not(:last-child) {
  margin-bottom: 0.46428571em;
}
.pricing ul li .checkmark + span {
  position: relative;
  bottom: 3px;
}
.pricing:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.pricing .h1 {
  font-size: 4.21428571428571em;
  line-height: 1.10169491525424em;
  margin-bottom: 0.22033898305085em;
}
.pricing .h1 .pricing__dollar {
  font-size: 0.51020408163265em;
  position: relative;
  bottom: 20px;
}
.pricing .feature-6 p {
  min-height: 5.57142857em;
}
.pricing-1 .btn:last-child {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 0 0 6px 6px;
  padding: 0.92857143em 0;
  margin: 0;
}
.pricing-1 .btn:last-child:hover {
  -webkit-transform: none !important;
          transform: none !important;
}
.pricing-1 ul {
  margin-bottom: 5.57142857em;
}
.pricing-1 p {
  min-height: 5.57142857em;
}
.pricing-1 p:first-of-type {
  margin-top: 1.85714286em;
}
.pricing-1 p:last-of-type {
  margin-bottom: 5.57142857em;
}
.pricing-1 img + p:first-of-type {
  margin-top: 0;
}
@media all and (min-width: 991px) {
  .pricing-2 ul:first-child {
    margin-top: 4.64285714em;
  }
}
@media all and (max-width: 990px) {
  .pricing-2 {
    text-align: center;
  }
  .pricing-2 ul:first-child {
    margin-top: 1.85714286em;
  }
}
.pricing-3 {
  border: 1px solid #ececec;
  border-radius: 6px;
}
.pricing-3 .pricing__head {
  margin: 0;
  border-bottom: 1px solid #ececec;
}
.pricing-3 .pricing__head + .btn {
  display: block;
  border-radius: 0;
  padding: 0.92857143em 0;
}
.pricing-3 ul {
  border-radius: 0 0 6px 6px;
}
.pricing-3 ul li {
  padding: 0.92857143em 0;
}
.pricing-3 ul li:not(:last-child) {
  border-bottom: 1px solid #ececec;
  margin: 0;
}
.pricing-3 ul:last-child {
  margin: 0;
}
@media all and (max-width: 990px) {
  .pricing-3 {
    margin-bottom: 1.85714286em;
  }
}
@media all and (min-width: 768px) {
  .pricing-section-1 .pricing:not(.boxed--emphasis) {
    left: 0.92857143em;
  }
  .pricing-section-1 .pricing.boxed--emphasis {
    right: 0.92857143em;
  }
}
@media all and (min-width: 990px) {
  .pricing-section-2 div[class*='col-'] {
    padding: 0;
  }
  .pricing-section-2 div[class*='col-']:not(:first-child):not(:last-child) .pricing__head {
    border-radius: 0;
  }
  .pricing-section-2 div[class*='col-']:first-child .pricing {
    border-radius: 6px 0 0 6px;
  }
  .pricing-section-2 div[class*='col-']:first-child .pricing .pricing__head {
    border-radius: 6px 0 0 0;
  }
  .pricing-section-2 div[class*='col-']:last-child .pricing {
    border-radius: 0 6px 6px 6px;
    border-right: 1px solid #ececec;
  }
  .pricing-section-2 div[class*='col-']:last-child .pricing .pricing__head {
    border-radius: 0 6px 0 0;
  }
  .pricing-section-2 .pricing {
    border: 1px solid #ececec;
    border-radius: 0 0 0 6px;
    border-right: none;
  }
  .pricing-section-2 .pricing:hover {
    box-shadow: none;
  }
  .pricing-section-2 .pricing ul {
    margin: 0;
  }
}
.pricing-section-2.bg--dark .pricing,
.pricing-section-2.bg--dark .pricing .pricing__head,
.pricing-section-2.bg--dark .pricing ul li:not(:last-child),
.pricing-section-2.bg--dark div[class*='col-']:last-child .pricing {
  border-color: #3f3f3f;
}
.pricing-section-2.imagebg .pricing,
.pricing-section-2.imagebg .pricing .pricing__head,
.pricing-section-2.imagebg .pricing ul li:not(:last-child),
.pricing-section-2.imagebg div[class*='col-']:last-child .pricing {
  border-color: rgba(255, 255, 255, 0.2);
}
/*! -- Stack Progress Horizontal -- */
.progress-horizontal:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.progress-horizontal.progress-horizontal--lg .progress-horizontal__bar {
  height: 1.85714286em;
}
.progress-horizontal.progress-horizontal--sm .progress-horizontal__bar {
  height: 0.46428571em;
}
.progress-horizontal__bar {
  height: 0.92857143em;
  border-radius: 100px;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.progress-horizontal__bar:not(:last-child) {
  margin-bottom: 0.46428571em;
}
.progress-horizontal__progress {
  background: #4a90e2;
  border-radius: 100px;
}
/*! -- Stack Maps -- */
iframe[src*='google.com/maps'] {
  border: none;
}
.map-container {
  min-height: 400px;
}
.map-container:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.map-container.border--round {
  overflow: hidden;
  z-index: 2;
}
.map-container.border--round .gm-style {
  border-radius: 6px;
}
.map-container iframe {
  left: 0;
}
@media all and (min-width: 768px) {
  .background-image-holder .map-container,
  .imageblock__content .map-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .map-container + div[class*='col-'] {
    margin-top: 3.71428571em;
  }
}
/*! -- Stack Testimonials -- */
.testimonial {
  position: relative;
  overflow: hidden;
}
.testimonial h5:nth-last-child(2) {
  margin: 0;
}
.testimonial .testimonial__image {
  margin-bottom: 1.85714286em;
}
@media all and (min-width: 1025px) {
  .testimonial-1 .switchable__text {
    margin-top: 7.42857143em;
  }
}
.testimonial-2 {
  float: none;
  width: 100%;
}
.testimonial-2 .testimonial__image img {
  max-height: 3.71428571em;
  float: left;
  margin-right: 1.85714286em;
}
.testimonial-2 .boxed {
  width: 100%;
}
.bg--secondary .testimonial-2 .boxed {
  background: #fff;
}
@media all and (max-width: 990px) {
  .testimonial-2 p {
    min-height: 7.42857143em;
  }
}
@media all and (max-width: 767px) {
  .testimonial-2 p {
    min-height: 0;
  }
}
@media all and (min-width: 768px) {
  .lead + .testimonial-2 {
    margin-top: 3.71428571em;
  }
}
@media all and (min-width: 768px) {
  .testimonials-1 .list-inline {
    position: relative;
    top: 0.92857143em;
  }
  .testimonials-1 .list-inline li:not(:last-child) {
    margin-right: 3.71428571em;
  }
}
@media all and (max-width: 767px) {
  .testimonials-1 .list-inline {
    margin-top: 1.85714286em;
  }
  .testimonials-1 .list-inline li {
    margin-bottom: 1.85714286em;
  }
}
/*! -- Stack Masonry -- */
.masonry__item:not(:empty) {
  margin-bottom: 30px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.masonry__item:not(:empty) article {
  margin: 0;
}
.masonry__item:not(:empty) article .boxed {
  margin: 0;
}
.masonry__item:empty {
  height: 0;
  min-height: 0;
}
.masonry hr + .masonry__container,
.masonry hr + .row {
  margin-top: 4.64285714em;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) {
  display: inline-block;
  cursor: pointer;
  height: 3.71428571em;
  position: relative;
  z-index: 9;
  min-width: 143px;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters {
  cursor: pointer;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  z-index: 2;
  display: inline-block;
  position: absolute;
  left: 0;
  text-align: left;
  padding: 0.92857143em 1.85714286em;
  border: 1px solid #ececec;
  background: #ffffff;
  border-radius: 6px;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul {
  margin: 0;
  cursor: pointer;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  display: block;
  color: #666666;
  white-space: nowrap;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li:not(:last-child) {
  margin-bottom: 0.92857143em;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li.active {
  color: #4a90e2;
  cursor: pointer;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li.active:after {
  content: '\25BC';
  font-size: 8px;
  margin-left: 6px;
  position: relative;
  bottom: 2px;
  display: inline-block;
  -webkit-transform: scaleX(1.4) scaleY(0.9);
          transform: scaleX(1.4) scaleY(0.9);
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li:hover {
  color: #4a90e2;
}
.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul:hover li.active:after {
  opacity: 0;
}
.masonry-filter-holder:not(.masonry-filters--horizontal):not(:hover) .masonry__filters ul {
  max-height: 1.85714286em;
  overflow: hidden;
}
.masonry-filter-holder:not(.masonry-filters--horizontal):not(:hover) .masonry__filters ul li:not(.active) {
  display: none;
}
.masonry-filter-holder:not(.masonry-filters--horizontal):hover .masonry__filters {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
}
.masonry-filter-holder:not(.masonry-filters--horizontal):hover .masonry__filters ul li.active {
  cursor: default;
}
.masonry-filters--horizontal {
  display: inline-block;
}
.masonry-filters--horizontal .masonry__filters {
  display: inline-block;
  position: relative;
  margin-left: 0.92857143em;
}
.masonry-filters--horizontal .masonry__filters ul li {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  cursor: pointer;
  display: inline-block;
}
.masonry-filters--horizontal .masonry__filters ul li:not(:last-child) {
  margin-right: 1.85714286em;
}
.masonry-filters--horizontal .masonry__filters ul li.active {
  color: #4a90e2;
}
.masonry-filters--horizontal .masonry__filters ul li:not(.active) {
  opacity: .5;
}
.masonry-filters--horizontal .masonry__filters ul li:hover {
  opacity: 1;
}
.masonry-filter-holder + .masonry__container {
  margin-top: 3.71428571em;
}
.bg--dark .masonry-filter-holder .masonry__filters {
  background: #2a2a2a;
  border-color: #3f3f3f;
}
.bg--dark .masonry-filter-holder .masonry__filters li {
  color: #fff;
}
.masonry.masonry-demos .masonry__item:not(:empty) {
  margin-bottom: 3.71428571em;
}
.masonry.masonry-demos .masonry__item h5 {
  margin-bottom: 0;
}
.masonry.masonry-demos .masonry__item:not([style]) .hover-item {
  min-height: 450px;
  background: #323232;
  border: 1px solid #3f3f3f;
}
.masonry.masonry-demos .hover-item {
  transition: 0.35s ease-out;
  -webkit-transition: 0.35s ease-out;
  -moz-transition: 0.35s ease-out;
  border-radius: 6px;
  overflow: hidden;
}
.masonry.masonry-demos .hover-item:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.masonry.masonry-demos .masonry__container {
  margin-top: 3.71428571em;
}
/*! -- Stack Blog -- */
article .article__title {
  margin-bottom: 3.71428571em;
}
article .article__title > a {
  display: block;
}
article .article__title > a:hover {
  text-decoration: none;
  opacity: .75;
}
article .article__title h2 {
  margin-bottom: 0.3939393939394em;
}
article .article__title h1 {
  margin-bottom: 0.29545454545455em;
}
article .article__body img,
article .article__body iframe,
article .article__body .video-cover {
  border-radius: 6px;
}
article .article__body img:not(:last-child),
article .article__body iframe:not(:last-child),
article .article__body .video-cover:not(:last-child) {
  margin-bottom: 1.85714286em;
}
article .article__body > a:first-child > img {
  margin-bottom: 1.85714286em;
}
article .article__body p,
article .article__body ul {
  font-size: 1.35714286em;
  line-height: 1.68421053em;
  font-weight: 400;
  color: #808080;
}
article .article__body p:not(:last-child),
article .article__body ul:not(:last-child) {
  margin-bottom: 1.36842105263158em;
}
article .article__body p + .btn:last-child,
article .article__body ul + .btn:last-child {
  margin-top: 0.92857143em;
}
article .article__body p + h5,
article .article__body ul + h5 {
  margin-top: 2.78571429em;
}
article .article__body blockquote {
  text-align: center;
}
article .article__body blockquote:not(:last-child) {
  margin-top: 2.08em;
  margin-bottom: 2.08em;
}
article .article__share {
  margin-top: 5.57142857em;
}
article.imagebg {
  padding: 1.85714286em;
  overflow: hidden;
}
article.imagebg:before {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
article.imagebg:hover:before {
  opacity: .6;
}
article.imagebg .article__title {
  margin: 0;
  position: absolute;
  bottom: 0;
  z-index: 2;
  max-width: 90%;
}
article.imagebg .article__title span {
  font-size: 0.85714286em;
}
article.imagebg .label {
  z-index: 2;
  top: 1.85714286em;
  right: 1.85714286em;
}
article.feature-1 h5 {
  min-height: 3.71428571em;
}
article.feature-1 a img {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
article.feature-1 a:hover img {
  opacity: .85;
}
figure[class*='col-'] {
  padding: 0 !important;
}
figure.pull-right {
  margin-left: 2.78571429em;
}
figure.pull-left {
  margin-right: 2.78571429em;
}
figure img:not(:last-child) {
  margin-bottom: 0.46428571em !important;
}
figure figcaption {
  font-size: 0.85714286em;
  font-style: italic;
}
section.unpad > article .imagebg + div {
  padding: 5.57142857em 0;
}
.article__author {
  padding-bottom: 1.85714286em;
}
.article__author img {
  max-height: 3.71428571em;
  margin-bottom: 0.46428571em;
}
.slides--gapless article.imagebg {
  height: 27.85714286em;
}
.masonry-blog-magazine article,
.masonry-blog-magazine .masonry__promo {
  height: 338px;
}
.masonry-blog-magazine .height--tall {
  height: calc(706px);
}
@media all and (min-width: 991px) {
  .masonry__promo.boxed--lg {
    padding: 7.42857143em;
  }
}
@media all and (max-width: 990px) {
  .masonry-blog-magazine .height--tall {
    height: 338px;
  }
  .masonry__promo .pos-vertical-center {
    top: 0;
    -webkit-transform: none;
            transform: none;
  }
}
@media all and (max-width: 1200px) {
  .masonry__promo.boxed--lg {
    padding: 3.71428571em;
  }
}
.pagination {
  text-align: center;
  margin-top: 1.85714286em;
}
.pagination:after {
  content: "";
  display: table;
  clear: both;
}
.pagination a {
  font-weight: normal;
}
.pagination div[class*='col-'] {
  padding: 0;
}
.pagination ol {
  margin-bottom: 0;
  display: inline-block;
}
.pagination ol li {
  display: inline-block;
  margin: 0;
  font-weight: 700;
  line-height: 23px;
  width: 1.85714286em;
  height: 1.85714286em;
  border-radius: 50%;
  border: 1px solid #ececec;
}
.pagination ol li > a {
  font-weight: 700;
}
.pagination ol li:not(:last-child) {
  margin-right: 0.46428571em;
}
.pagination ol li.pagination__current {
  cursor: default;
  background: #ececec;
  color: #aaa;
}
.pagination__prev,
.pagination__next {
  display: inline-block;
}
.pagination__next {
  float: right;
}
.pagination__prev {
  float: left;
}
.blog-article-wide:not(:last-child) {
  border-bottom: 1px solid #ececec;
  margin-bottom: 3.71428571em;
}
.masonry-blog-list hr + .masonry__container {
  margin-top: 0;
}
.masonry-blog-list article {
  border-bottom: 1px solid #ececec;
  padding-top: 2.78571429em;
  margin-bottom: 5.57142857em;
}
.masonry-blog-list article .article__body {
  padding-bottom: 5.57142857em;
}
.masonry-blog-magazine .masonry__item a {
  font-weight: normal;
}
.masonry-blog-magazine .masonry__item a:hover {
  text-decoration: none;
}
.masonry-filter-container {
  margin-bottom: 1.85714286em;
}
.masonry-filter-container > span {
  position: relative;
}
.masonry-filter-container > span + .masonry-filter-holder {
  margin-left: 0.92857143em;
}
.comments__list li {
  padding: 1.85714286em;
  border: 1px solid #ececec;
  border-bottom: none;
}
.comments__list li:last-child {
  border-bottom: 1px solid #ececec;
}
.comments__list li:nth-child(odd) {
  background: #fcfcfc;
}
.comments__list li:nth-child(even) {
  background: #f9f9f9;
}
.comments__list li:first-child {
  border-radius: 6px 6px 0 0;
}
.comments__list li:last-child {
  border-radius: 0 0 6px 6px;
}
.bg--secondary .comments__list li:nth-child(even) {
  background: #fff;
}
@media all and (min-width: 768px) {
  .comments__list li {
    padding: 2.78571429em;
  }
}
.comment {
  overflow: hidden;
}
.comment:not(:last-child) {
  border-bottom: 1px solid #ececec;
  padding-bottom: 1.85714286em;
}
.comment .comment__avatar {
  width: 15%;
  float: left;
}
.comment .comment__avatar img {
  max-height: 3.71428571em;
}
.comment .comment__meta {
  font-size: 0.85714286em;
}
.comment .comment__meta a {
  display: inline-block;
}
.comment .comment__meta a:not(:first-child) {
  margin-left: 1.08333333333334em;
}
.comment .comment__meta + p {
  margin-top: 0.92857143em;
}
.comment .comment__body {
  width: 85%;
  float: right;
}
.comment .comment__body h5.type--fine-print {
  margin: 0;
}
.comment + .comment {
  margin-top: 1.85714286em;
  padding-left: 15%;
}
@media all and (min-width: 768px) {
  .comments + .comments-form {
    margin-top: 3.71428571em;
  }
}
@media all and (min-width: 768px) {
  h3 + .related-blog-list {
    margin-top: 3.71428571em;
  }
}
.related-blog-list li:not(:last-child) {
  margin-bottom: 1.85714286em;
}
.related-blog-list article img {
  border-radius: 6px;
}
.related-blog-list article .article__title {
  margin-top: 0.92857143em;
  margin-bottom: 0;
}
.related-blog-list article .article__title .h4 {
  margin-bottom: 0.68421052631579em;
}
@media all and (min-width: 991px) {
  .sidebar {
    margin-top: 7.42857143em;
    position: relative;
    bottom: 18px;
  }
}
.sidebar__widget > h5:first-child {
  margin-bottom: 0.92857143em;
}
.sidebar__widget:not(:last-child) {
  margin-bottom: 3.71428571em;
}
.tag-cloud li {
  display: inline-block;
}
.tag-cloud li:not(:last-child) {
  margin-right: 0.46428571em;
}
.tag-cloud li:not(:last-child):after {
  content: ',';
}
.conversation__head {
  border-radius: 6px 6px 0 0;
}
.conversation__head.boxed:not(:last-child) {
  margin: 0;
}
.conversation__head h4 {
  margin: 0;
}
.conversation__head .conversation__avatar {
  margin-top: 0.92857143em;
}
.conversation__head .conversation__avatar img {
  max-height: 1.85714286em;
  display: inline-block;
  margin-right: 0.46428571em;
  margin-bottom: 0;
}
@media all and (min-width: 768px) {
  .conversation__avatar {
    position: absolute;
    top: 1.85714286em;
    right: 2.78571429em;
  }
}
.conversation__reply {
  border-top: none !important;
  border-bottom: none;
  border-radius: 0;
}
.conversation__reply.boxed:not(:last-child) {
  margin-bottom: 0;
}
.conversation__reply + .conversation__comments .comments__list li:first-child {
  border-radius: 0;
  border-top: none;
}
/*! -- Stack Portfolio -- */
.project-thumb > a {
  display: block;
}
.project-thumb:not(.hover-element) > a {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  margin-bottom: 1.85714286em;
}
.project-thumb:not(.hover-element) > a:hover {
  opacity: .85;
}
.project-thumb.hover-element {
  margin: 0;
}
.project-thumb.hover-element > a {
  height: 24.14285714em;
  text-decoration: none;
  font-weight: normal;
}
.project-thumb.hover-element .background-image-holder {
  transition: 0.5s ease-out;
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
}
.project-thumb.hover-element .hover-element__reveal .project-thumb__title {
  padding: 1.85714286em;
  z-index: 2;
  position: relative;
  color: #fff;
}
.project-thumb.hover-element .hover-element__reveal .project-thumb__title h5 {
  margin: 0;
}
.project-thumb.hover-element.hover--active {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
}
.project-thumb.hover-element.hover--active:hover {
  opacity: .85;
}
.project-thumb.hover-element:not(.hover--active)[data-scrim-top] .hover-element__reveal {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  transform: translate3d(0, -100px, 0);
  -webkit-transform: translate3d(0, -100px, 0);
}
.project-thumb.hover-element:not(.hover--active)[data-scrim-top]:hover .hover-element__reveal {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}
.project-thumb.hover-element [data-scrim-top]:before {
  height: 100%;
}
.project-thumb h4 {
  margin-bottom: 0;
}
.project-thumb.height-40 {
  min-height: 350px;
}
@media all and (max-width: 767px) {
  .project-thumb.height-40 {
    height: 350px;
    min-width: 100%;
  }
}
@media all and (min-width: 768px) {
  .col-xs-12:not(.col-sm-6) .project-thumb.hover-element > a {
    height: 46.42857143em;
  }
}
.project-thumb.border--round .background-image-holder {
  border-radius: 6px;
}
.project-thumb.border--round > a {
  border-radius: 6px;
  overflow: hidden;
}
.project-thumb.border--round:before {
  border-radius: 6px;
  overflow: hidden;
}
.col-md-4 .project-thumb > a:not(.imagebg) {
  margin-bottom: 0.92857143em;
}
/*! -- Stack Shop -- */
.cart-form button[type="submit"] {
  margin-top: 1.85714286em;
}
.cart-total > div {
  overflow: hidden;
}
@media all and (min-width: 768px) {
  .cart-total {
    margin-top: 3.71428571em;
  }
  .cart-customer-details {
    margin-top: 7.42857143em;
  }
}
@media all and (max-width: 767px) {
  .cart-total {
    margin-top: 1.85714286em;
  }
}
.cart-customer-details div[class*='col-'] {
  margin-top: 0.46428571em;
}
.cart-customer-details .input-checkbox {
  margin: 1.85714286em 0;
}
.cart-customer-details .input-checkbox + span {
  bottom: 38px;
}
.product {
  margin-bottom: 0.92857143em;
}
.product img {
  margin-bottom: 0.92857143em;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.product a:hover img {
  transform: translate3d(0, -5px, 0);
  -webkit-transform: translate3d(0, -5px, 0);
}
.product .label + img {
  margin-top: 2.78571429em;
}
.product h5 {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 0.46428571em;
}
.product .h4 {
  margin-bottom: 0;
}
.product .h4:first-child {
  clear: left;
}
.product .product__controls {
  overflow: hidden;
  margin-bottom: 0.92857143em;
}
.product .product__controls label,
.product .product__controls button {
  position: relative;
  top: 6px;
}
.product.product--tile {
  padding: 1.85714286em;
  border-radius: 6px;
}
@media all and (min-width: 1200px) {
  .product .btn--cart {
    position: absolute;
    right: 1.85714286em;
    bottom: 1.85714286em;
  }
}
.col-sm-6:not(.col-md-3) .product {
  text-align: center;
}
.col-md-3 .product--tile h5 {
  display: block;
}
.masonry--tiles {
  padding: 10px;
}
.masonry--tiles .masonry__item:not(:empty) {
  padding: 0 6px;
  margin-bottom: 12px;
}
.masonry--tiles .masonry__item:not(:empty) .product--tile {
  margin: 0;
}
.masonry--tiles .masonry__item:not(:empty) .product--tile .label {
  top: 1.85714286em;
  right: 2.78571429em;
}
/*! -- Stack Wizard -- */
.wizard {
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  opacity: 0;
	font-size: 18px;
}
.wizard.active {
  opacity: 1;
}
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  background: #4a90e2;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  padding: 0.46428571em 1.85714286em;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;
}
.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  background: none;
  color: gold;
}
.wizard > .steps a {
  position: relative;
}
.wizard > .steps a:before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  background: #eee;
  display: block;
  bottom: 0;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}
.wizard > .steps a:after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 7px;
  height: 2px;
  background: #eee;
  left: 0;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}
.wizard > .steps li:first-child a:after {
  width: 50%;
  left: 50%;
}
.wizard > .steps li:last-child a:after {
  width: 50%;
}
.wizard > .steps li.current a:after,
.wizard > .steps li.current a:before {
  background-color: #4a90e2;
}
.wizard > .steps a,
.wizard > .steps a:hover {
  padding: 0.5em 1em 1.78571429em 1em;
}
.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active,
.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  background: none;
  color: white;
}
.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  margin: 0;
}
.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.wizard > .steps > ul,
.wizard > .actions {
  text-align: center;
}
.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: none;
  display: inline-block;
}
.wizard > section {
  padding: 0;
}
.wizard > .content {
  background: #fafafa00;
  margin-top: 0;
  margin-bottom: 0;
 
}
.wizard > .content > .body {
  /*padding: 1.85714286em;*/
  width: 100%;
  height: 100%;
}
.wizard > .steps .number {
  font-size: 1em;
}
@media all and (max-width: 767px) {
  .wizard > .steps .number {
    display: block;
  }
  .wizard > .content {
    min-height: 25em;
  }
  .wizard .pos-vertical-center {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -wekbit-transform: translateY(-50%);
  }
}
.wizard.bg--white {
  background: none !important;
}
.wizard.bg--white > .content {
  background: #fff;
}
.wizard > .content img {
  max-height: 850px;
  width: 80%;
}
@media all and (max-width: 767px) {
  .wizard > .content img {
    max-height: 120px;
    margin-bottom: 0.92857143em;
  }
}
/*! -- Stack Footers -- */
footer .type--fine-print:not(p) {
  opacity: .5;
}
footer a {
  font-weight: normal;
}
footer:not(.bg--primary):not(.bg--dark) a {
  color: #666666;
}
footer a.type--fine-print:not(:first-child) {
  margin-left: 1.85714286em;
}
footer .logo {
  max-height: 1.67142857em;
}
footer ul:not(.list-inline):not(.slides) > li {
  line-height: 2.32142857em !important;
}
footer.footer-2 .row:last-child {
  margin-top: 3.71428571em;
}
footer.footer-5 .row [class*='col-'] > div:nth-last-child(2) {
  margin: 0.92857143em;
}
footer.footer-5 .row [class*='col-'] > div:nth-last-child(2) .type--fine-print {
  opacity: 1;
}
footer.footer-6 .footer__lower {
  background: #fafafa;
  margin-top: 1.85714286em;
  padding: 1.85714286em 0;
}
footer.footer-6.bg--dark .footer__lower {
  background: #181818;
}
footer.footer-6.bg--secondary .footer__lower {
  background: #f5f5f5;
}
footer.footer-6.bg--primary .footer__lower {
  background: #3483de;
}
footer.footer-7 {
  padding: 1.85714286em 0;
}
@media all and (min-width: 768px) {
  footer .list-inline + .btn {
    position: relative;
    bottom: 4px;
  }
  footer .logo {
    margin: 0;
  }
  footer .logo:not(:last-child) {
    margin-right: 1.85714286em;
  }
  footer.footer-3 .logo + ul {
    position: relative;
    top: 5px;
  }
  footer.footer-3 .logo + ul a {
    color: #252525;
  }
  footer.footer-3 .social-list {
    position: relative;
    top: 9px;
  }
  footer.footer-3 .row:last-child {
    margin-top: 1.85714286em;
  }
  footer.footer-4 .logo + span {
    position: relative;
  }
  footer.footer-4 form {
    position: relative;
    bottom: 4px;
  }
  footer.footer-4 .row:last-child {
    margin-top: 1.85714286em;
  }
  footer.imagebg:not(.image--light) span,
  footer.imagebg:not(.image--light) p,
  footer.imagebg:not(.image--light) a {
    color: #fff;
  }
  .footer-1 .type--fine-print {
    position: relative;
    top: 2px;
  }
}
@media all and (max-width: 767px) {
  footer .social-list {
    margin: 1.85714286em 0;
  }
  .footer-4 form {
    margin: 1.85714286em 0;
  }
  .footer-4 .logo {
    margin: 0;
  }
  .footer-6 .footer__lower .social-list {
    margin-top: 1.85714286em;
  }
  .footer-7 .social-list {
    margin-top: 1.85714286em;
  }
  .footer-1 .social-list {
    margin-top: 1.85714286em;
  }
  .footer-1 .logo {
    display: block;
    margin-top: 1.85714286em;
    margin-bottom: 0.92857143em;
    margin-left: auto;
    margin-right: auto;
  }
}
.quote {
  color: #666 !important;
  text-align: right;
  font-size: 1.4em;
}
.quote cite {
  color: #000 !important;
  display: block;
  text-align: left;
  font-weight: bold;
}

.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}
label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}
button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}
form {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 3px solid #e6ebf1;
}
button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}
input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}
input::-webkit-input-placeholder {
  color: #aab7c4;
}
input::-ms-input-placeholder {
  color: #aab7c4;
}
input::placeholder {
  color: #aab7c4;
}
input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}
.StripeElement iframe {
  min-height: auto !important;
}

.terms p, .terms li {
  text-align: left;
}

body{
  font-family: 'Lato','Verdana','Helvetica', sans-serif;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6{
  font-family: 'Rubik','Helvetica', sans-serif;
}

h5,h6,.h5,.h6{
	font-weight: 500;
}

.menu > li{
	font-family: 'Lato','Verdana','Helvetica', sans-serif;
}

.btn,.btn__text,button{
	font-family: 'Lato','Verdana','Helvetica', sans-serif;
}


.loginButton, .signupButton {
  width: auto !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

#mc_embed_signup {
  background: none; clear:left; font:14px Helvetica,Arial,sans-serif;
}

